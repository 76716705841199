// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Components
import { ModuleHeader } from '../../../../layout/elements/ModuleHeader';

// View
import {
  FeederInterlockContainer,
} from './FeederInterlockModule.style';
import { FeederInterlockModuleProps } from './FeederInterlockModule.types';

function FeederInterlockModule({
  className,
  onOpenDrawer,
  style,
  testId,
}: FeederInterlockModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  return (
    <FeederInterlockContainer
      data-testid={testId}
      className={className}
      style={style}
    >
      <ModuleHeader
        onClick={onOpenDrawer}
        title={t('view.crusher.feederInterlock')}
      />
    </FeederInterlockContainer>
  );
}

export { FeederInterlockModule };
