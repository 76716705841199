// Core
import styled from 'styled-components';

export const OverallStatusContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-top: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
`;

export const TankTempStatusContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-top: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
`;

export const ClampPressureContainer = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const TrampPressureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const HydraulicContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const SpeedometersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
