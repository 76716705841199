// Core
import React, {
  useEffect,
  useMemo,
  useRef,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import _ from 'lodash';

// Plugins
import {
  currentValuePercentage,
  formatNumber,
} from '../../../plugins/general';

// Components
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../Paragraph';

// Component
import {
  Container,
  Element,
  ProgressIndicatorsContainer,
  ProgressThermometer,
  StepsThermometer,
  TextContainer,
  ThermometerAntD,
} from './Thermometer.style';
import { ThermometerProps, ThresholdsType } from './Thermometer.types';

// Types
import { Theme } from '../../../types/theme';

function Thermometer({
  className,
  decimalPlaces = 0,
  minValue,
  maxValue,
  running = true,
  testId,
  thresholds,
  title,
  unit,
  value,
}: ThermometerProps): JSX.Element | null {
  // Dependencies
  const theme: Theme = useTheme();
  const { i18n } = useTranslation();
  const thermometerRef = useRef<HTMLDivElement>(null);

  // UseEffect to re-render on language change
  useEffect((): void => {
    // Force update component when language changes
  }, [i18n.language]);

  // Magic value threshold
  const DEFAULT_THRESHOLD_VALUE: number = 43;

  /* ***********************************************************************************************
  ***************************************** METHODS ************************************************
  *********************************************************************************************** */

  // Triggers errors at unaccepted thresholds
  if (thresholds) {
    if (
      !_.isNil(thresholds.lowAlarm)
      && !_.isNil(thresholds.lowWarning)
      && thresholds.lowAlarm >= thresholds.lowWarning
    ) {
      throw new Error('[lowAlarm] must be less than [lowWarning]');
    }
    if (
      !_.isNil(thresholds.lowAlarm)
      && !_.isNil(thresholds.highWarning)
      && thresholds.lowAlarm >= thresholds.highWarning
    ) {
      throw new Error('[lowAlarm] must be less than [highWarning]');
    }
    if (
      !_.isNil(thresholds.lowAlarm)
      && !_.isNil(thresholds.highAlarm)
      && thresholds.lowAlarm >= thresholds.highAlarm
    ) {
      throw new Error('[lowAlarm] must be less than [highAlarm]');
    }
    if (
      !_.isNil(thresholds.lowWarning)
      && !_.isNil(thresholds.highWarning)
      && thresholds.lowWarning >= thresholds.highWarning
    ) {
      throw new Error('[lowWarning] must be less than [highWarning]');
    }
    if (
      !_.isNil(thresholds.lowWarning)
      && !_.isNil(thresholds.highAlarm)
      && thresholds.lowWarning >= thresholds.highAlarm
    ) {
      throw new Error('[lowWarning] must be less than [highAlarm]');
    }
    if (
      !_.isNil(thresholds.highWarning)
      && !_.isNil(thresholds.highAlarm)
      && thresholds.highWarning >= thresholds.highAlarm
    ) {
      throw new Error('[highWarning] must be less than [highAlarm]');
    }
  }

  const highAlarm: number | undefined = thresholds?.highAlarm;
  const highWarning: number | undefined = thresholds?.highWarning;
  const lowAlarm: number | undefined = thresholds?.lowAlarm;
  const lowWarning: number | undefined = thresholds?.lowWarning;

  const percentage: number = (value === null || typeof value === 'undefined')
    ? 0
    : currentValuePercentage({
      value,
      minValue,
      maxValue,
    });

  // Calculates a threshold value's position relative to a defined range
  const calculateThreshold = (
    thresholdValue: number | undefined,
    minimumValue: number,
    maximumValue: number,
  ): number => {
    let thresholdPosition: number = 0;

    if (thresholdValue !== undefined) {
      const clampedValue: number = Math.max(Math.min(thresholdValue, maximumValue), minimumValue);
      const relativeValue: number = clampedValue - minimumValue;
      const percent: number = relativeValue / (maximumValue - minimumValue);
      thresholdPosition = Math.round(percent * DEFAULT_THRESHOLD_VALUE);
    }

    return thresholdPosition;
  };

  const thresholdsTick: ThresholdsType = useMemo(() => ({
    highWarning: calculateThreshold(thresholds?.highWarning, minValue, maxValue),
    highAlarm: calculateThreshold(thresholds?.highAlarm, minValue, maxValue),
    lowWarning: calculateThreshold(thresholds?.lowWarning, minValue, maxValue),
    lowAlarm: calculateThreshold(thresholds?.lowAlarm, minValue, maxValue),
  }), [thresholds, minValue, maxValue]);

  // Determines the current thermometer color based on calculated percentages
  let currentColor: string;

  if (!running) {
    currentColor = theme.color.speedometer.offProgressBackgroundColor;
  } else if (
    !_.isNil(value)
    && (
      (!_.isNil(highAlarm) && value >= highAlarm)
      || (!_.isNil(lowAlarm) && value <= lowAlarm)
    )
  ) {
    currentColor = theme.color.speedometer.alarmBackgroundColor;
  } else if (
    !_.isNil(value)
    && (
      (!_.isNil(highWarning) && value >= highWarning)
      || (!_.isNil(lowWarning) && value <= lowWarning)
    )
  ) {
    currentColor = theme.color.speedometer.warningBackgroundColor;
  } else {
    currentColor = theme.color.speedometer.normalBackgroundColor;
  }

  /* ***********************************************************************************************
  **************************************** COMPONENT HANDLING **************************************
  *********************************************************************************************** */

  // Create values containers for minValue, maxValue, and value with their corresponding classes.
  useEffect((): void => {
    const thermometerElement: HTMLElement | null = thermometerRef.current;
    if (thermometerElement) {
      const allStepsItems: NodeListOf<Element> = thermometerElement.querySelectorAll(
        '.ant-progress-steps-item',
      );

      // Finding the element for the current value in the middle of the steps
      const middleStepIndex: number = Math.floor(allStepsItems.length / 2);
      const middleStep: Element = allStepsItems[middleStepIndex];
      const valueDiv: Element | null = middleStep.querySelector('.value-container');

      // Create or update the value in the middle of the thermometer
      if (!valueDiv) {
        const newValueDiv: HTMLElement = document.createElement('div');
        newValueDiv.classList.add('value-container');
        // Checks if the value is null or undefined, and formats as necessary
        const displayValue: string = `${formatNumber(value, decimalPlaces)}${unit}`;
        const valueTextNode: Text = document.createTextNode(displayValue as string);
        newValueDiv.appendChild(valueTextNode);
        middleStep.appendChild(newValueDiv);
      } else {
        // Update the text if the value already exists
        valueDiv.textContent = `${formatNumber(value, decimalPlaces)}${unit}`;
      }
    }
  }, [
    value,
    decimalPlaces,
    unit,
    i18n.language,
  ]);

  useEffect((): void => {
    const thermometerElement: HTMLElement | null = thermometerRef.current;
    if (thermometerElement) {
      const allStepsItems: NodeListOf<Element> = thermometerElement.querySelectorAll(
        '.ant-progress-steps-item',
      );

      // Last step
      const getLastStep: Element = allStepsItems[allStepsItems.length - 2];
      const maxValueDiv: HTMLElement | null = getLastStep.querySelector('.max-value-container');
      if (!maxValueDiv) {
        const newMaxValueDiv: HTMLElement = document.createElement('div');
        newMaxValueDiv.classList.add('max-value-container');
        const maxValueText: Text = document.createTextNode(
          formatNumber(maxValue, decimalPlaces),
        );
        newMaxValueDiv.appendChild(maxValueText);
        getLastStep.appendChild(newMaxValueDiv);
      } else {
        maxValueDiv.textContent = formatNumber(maxValue, decimalPlaces);
      }

      // First step
      const getFirstStep: Element = allStepsItems[1];
      const minValueDiv: HTMLElement | null = getFirstStep.querySelector('.min-value-container');
      if (!minValueDiv) {
        const newMinValueDiv: HTMLElement = document.createElement('div');
        newMinValueDiv.classList.add('min-value-container');
        const minValueText: Text = document.createTextNode(
          formatNumber(minValue, decimalPlaces),
        );
        newMinValueDiv.appendChild(minValueText);
        getFirstStep.appendChild(newMinValueDiv);
      } else {
        minValueDiv.textContent = formatNumber(minValue, decimalPlaces);
      }
    }
  }, [
    decimalPlaces,
    minValue,
    maxValue,
    i18n.language,
  ]);

  useEffect((): void => {
    const thermometerElement: HTMLElement | null = thermometerRef.current;
    if (thermometerElement) {
      const highAlarmIndicator: HTMLElement | null = thermometerElement.querySelector(
        `.ant-progress-steps-item:nth-child(${thresholdsTick.highAlarm})`,
      );
      highAlarmIndicator?.classList.add('high-alarm');

      // High Warning
      const highWarningIndicator: HTMLElement | null = thermometerElement.querySelector(
        `.ant-progress-steps-item:nth-child(${thresholdsTick.highWarning})`,
      );
      highWarningIndicator?.classList.add('high-warning');

      // Low Alarm
      const lowAlarmIndicator: HTMLElement | null = thermometerElement.querySelector(
        `.ant-progress-steps-item:nth-child(${thresholdsTick.lowAlarm})`,
      );
      lowAlarmIndicator?.classList.add('low-alarm');

      // Low Warning
      const lowWarningIndicator: HTMLElement | null = thermometerElement.querySelector(
        `.ant-progress-steps-item:nth-child(${thresholdsTick.lowWarning})`,
      );
      lowWarningIndicator?.classList.add('low-warning');
    }
  }, [thresholdsTick]);

  return (
    <div className={className} ref={thermometerRef}>
      <Element
        data-testid={testId}
        className={className}
        value={value}
        thresholds={thresholds}
        running={running}
      >
        <Container>
          <ProgressIndicatorsContainer>
            <ProgressThermometer>
              <ThermometerAntD
                strokeLinecap="square"
                percent={percentage}
                showInfo={false}
                strokeColor={currentColor}
              />
            </ProgressThermometer>
            <StepsThermometer threshold={thresholdsTick} running={running}>
              <ThermometerAntD
                steps={43}
                showInfo={false}
                size="small"
              />
            </StepsThermometer>
          </ProgressIndicatorsContainer>
          <TextContainer>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.bold}
              color={theme.color.thermometer.titleTextColor}
            >
              {title}
            </Paragraph>
          </TextContainer>
        </Container>
      </Element>
    </div>
  );
}

export { Thermometer };
