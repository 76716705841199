import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Helvetica Neue',
    sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: normal !important;
    margin: 0;
    padding: 0;
  }
  
  * {
      box-sizing: border-box;
  }
`;

export default GlobalStyle;
