// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M5.83333 2.66536H14.5V3.9987H5.83333V2.66536ZM2.5 2.33203H4.5V4.33203H2.5V2.33203ZM2.5 6.9987H4.5V8.9987H2.5V6.9987ZM2.5 11.6654H4.5V13.6654H2.5V11.6654ZM5.83333 7.33203H14.5V8.66536H5.83333V7.33203ZM5.83333 11.9987H14.5V13.332H5.83333V11.9987Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
