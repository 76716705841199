// Core
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 0;
  width: 100%;
  height: calc(100vh - 64px);
`;

export const SuperiorNumberRowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InstalledAtContainer = styled.div`
  min-width: 100px;
`;

export const LoadingContainer = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
