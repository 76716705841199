// Core
import styled from 'styled-components';

export const ThermometerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  display: flex;
`;

export const CavityLevelModuleContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: max-content;
`;
