// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.167 6h11.667m0 0-5 5m5-5-5-5"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
