// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

// Layout
import { AlertLine } from '../../../../../layout/icons';
import { AlertType } from '../../../../../layout/elements/Alert';
import { Button, ButtonSize, ButtonType } from '../../../../../layout/elements/Button';
import { Col } from '../../../../../layout/elements/Col';
import { Drawer, DrawerSize } from '../../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';
import { Speedometer } from '../../../../../layout/elements/Speedometer';
import {
  StatusIndicator,
  StatusIndicatorSize,
} from '../../../../../layout/elements/StatusIndicator';
import { Tag } from '../../../../../layout/elements/Tag';
import { Thermometer } from '../../../../../layout/elements/Thermometer';

// Plugins
import {
  overallStatusToTagType,
  overallStatusToText,
  permissiveStatusToIndicatorStatus,
  runningStatusToIndicatorStatus,
  weatherModeStatusToTagType,
  weatherModeStatusToText,
} from '../../../../../plugins/crusher';

// Types
import { EdgeDeviceOperationalStatus } from '../../../../../types/global';
import { Theme } from '../../../../../types/theme';

// Component
import { LubricationUnitProps } from './LubricationUnit.types';
import {
  BorderBottomContainer,
  HeaterPermissiveListContainer,
  LubePressureContainer,
  LubricationContainer,
  OverallStatusContainer,
  PermissiveItem,
  PermissiveListContainer,
  PermissiveTitleContainer,
  RunningStatusContainer,
  RunningStatusItem,
  RunningStatusList,
  ThermometerContainer,
  WinterModeContainer,
  WinterModeStatus,
} from './LubricationUnit.style';

function LubricationUnit({
  className,
  isDrawerOpen,
  onCloseDrawer,
  operationalStatus,
  testId,
  unitData,
}: LubricationUnitProps): JSX.Element {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const { lubricationUnitDrawer } = theme.color.crusherDashboard;

  const renderLubePermissive = (
    permissiveName: string,
    permissive: boolean | null | undefined,
  ): JSX.Element => (
    <PermissiveItem
      status={permissiveStatusToIndicatorStatus(permissive, operationalStatus)}
    >
      <StatusIndicator
        text={t(`view.crusher.lubrication.drawer.${permissiveName}`)}
        status={permissiveStatusToIndicatorStatus(permissive, operationalStatus)}
      />
    </PermissiveItem>
  );

  const renderHeaterPermissive = (
    permissiveName: string,
    permissive: boolean | null | undefined,
  ): JSX.Element => (
    <PermissiveItem
      status={permissiveStatusToIndicatorStatus(permissive, operationalStatus)}
    >
      <StatusIndicator
        text={t(`view.crusher.lubrication.drawer.${permissiveName}`)}
        status={permissiveStatusToIndicatorStatus(permissive, operationalStatus)}
      />
    </PermissiveItem>
  );

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t('view.crusher.lubrication.drawer.lubricationUnit')}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      footer={false}
      size={DrawerSize.lg}
      alert={operationalStatus !== EdgeDeviceOperationalStatus.on && {
        type: AlertType.warning,
        icon: <AlertLine />,
        description: `${t('layout.elements.drawer.alertText')}`,
      }}
    >
      <LubricationContainer>
        <BorderBottomContainer>
          <Row>
            <Col>
              <OverallStatusContainer>
                <Paragraph
                  color={lubricationUnitDrawer.overallStatusDescriptionTextColor}
                  weight={ParagraphWeight.bold}
                >
                  {t('view.crusher.overallStatus')}
                </Paragraph>
                <Tag
                  text={overallStatusToText(unitData?.lubricationOverallStatus)}
                  type={
                    overallStatusToTagType(
                      unitData?.lubricationOverallStatus,
                      operationalStatus,
                    )
                  }
                />
              </OverallStatusContainer>
            </Col>
          </Row>
        </BorderBottomContainer>
        <BorderBottomContainer>
          <Row>
            <Col cols={12}>
              <RunningStatusContainer>
                <Paragraph weight={ParagraphWeight.bold}>
                  {t('view.crusher.lubrication.drawer.runningStatus')}
                </Paragraph>
                <RunningStatusList>
                  <RunningStatusItem>
                    <StatusIndicator
                      text={t('view.crusher.lubrication.drawer.lubePump')}
                      status={
                        runningStatusToIndicatorStatus(
                          unitData?.lubePumpRunningStatus,
                          operationalStatus,
                        )
                      }
                    />
                  </RunningStatusItem>
                  <RunningStatusItem>
                    <StatusIndicator
                      text={t('view.crusher.lubrication.drawer.lubeCooler')}
                      status={
                        runningStatusToIndicatorStatus(
                          unitData?.coolerFanStatus,
                          operationalStatus,
                        )
                      }
                    />
                  </RunningStatusItem>
                  <RunningStatusItem>
                    <StatusIndicator
                      text={t('view.crusher.lubrication.drawer.tankHeater')}
                      status={
                        runningStatusToIndicatorStatus(
                          unitData?.tankLubeHeaterStatus,
                          operationalStatus,
                        )
                      }
                    />
                  </RunningStatusItem>
                </RunningStatusList>
              </RunningStatusContainer>
            </Col>
            <Col cols={12}>
              <LubePressureContainer>
                <Speedometer
                  title={`${t('view.crusher.lubrication.drawer.lubePressure')}`}
                  unit="psi"
                  running={operationalStatus === EdgeDeviceOperationalStatus.on}
                  minValue={0}
                  maxValue={145}
                  value={unitData?.lubricationOilPressure}
                  thresholds={{ lowAlarm: 13, lowWarning: 17 }}
                />
              </LubePressureContainer>
            </Col>
          </Row>
        </BorderBottomContainer>
        <BorderBottomContainer>
          <Row>
            <Col>
              <WinterModeContainer>
                <WinterModeStatus>
                  <Paragraph
                    size={ParagraphSize.sm}
                    weight={ParagraphWeight.bold}
                    color={lubricationUnitDrawer.winterModeTextColor}
                  >
                    {t('view.crusher.lubrication.drawer.winterMode')}
                  </Paragraph>
                  <Tag
                    text={weatherModeStatusToText(unitData?.weatherModeStatus)}
                    type={
                      weatherModeStatusToTagType(
                        unitData?.weatherModeStatus,
                        operationalStatus,
                      )
                    }
                  />
                </WinterModeStatus>
                <Button
                  disabled
                  type={ButtonType.secondary}
                  size={ButtonSize.xs}
                  block={false}
                  onClick={() => alert('Winter mode button was clicked!')}
                >
                  {t('view.crusher.lubrication.drawer.accessWinterMode')}
                </Button>
              </WinterModeContainer>
            </Col>
          </Row>
        </BorderBottomContainer>
        <BorderBottomContainer>
          <Row>
            <ThermometerContainer>
              <Thermometer
                running={operationalStatus === EdgeDeviceOperationalStatus.on}
                thresholds={{
                  lowAlarm: 18,
                  lowWarning: 20,
                  highWarning: 52,
                  highAlarm: 60,
                }}
                maxValue={150}
                minValue={-20}
                value={unitData?.lubricationReturnTemperature}
                unit="°C"
                title={`${t('view.crusher.lubrication.drawer.returnTemp')}`}
              />
            </ThermometerContainer>
            <ThermometerContainer>
              <Thermometer
                running={operationalStatus === EdgeDeviceOperationalStatus.on}
                thresholds={{
                  lowAlarm: 17,
                  lowWarning: 21,
                  highWarning: 54,
                  highAlarm: 60,
                }}
                maxValue={150}
                minValue={-20}
                value={unitData?.lubricationTankTemperature}
                unit="°C"
                title={`${t('view.crusher.lubrication.drawer.tankTemp')}`}
              />
            </ThermometerContainer>
            <ThermometerContainer>
              <Thermometer
                running={operationalStatus === EdgeDeviceOperationalStatus.on}
                thresholds={{ lowWarning: 20 }}
                maxValue={150}
                minValue={-20}
                value={unitData?.lubricationSupplyTemperature}
                unit="°C"
                title={`${t('view.crusher.lubrication.drawer.supplyTemp')}`}
              />
            </ThermometerContainer>
            <ThermometerContainer>
              <Thermometer
                running={operationalStatus === EdgeDeviceOperationalStatus.on}
                maxValue={300}
                minValue={-40}
                value={unitData?.lubeSupplyDifferential}
                unit="°C"
                title={`${t('view.crusher.lubrication.drawer.lubeSupplyDifferential')}`}
              />
            </ThermometerContainer>
          </Row>
        </BorderBottomContainer>
        <Row>
          <Col cols={12}>
            <PermissiveListContainer>
              <PermissiveTitleContainer>
                <StatusIndicator
                  text={t('view.crusher.lubrication.drawer.lubePermissive')}
                  status={
                    permissiveStatusToIndicatorStatus(
                      unitData?.lubePermissive,
                      operationalStatus,
                    )
                  }
                  size={StatusIndicatorSize.lg}
                />
              </PermissiveTitleContainer>
              {renderLubePermissive('lubePump1StarterFault', unitData?.lubePump1StarterFault)}
              {renderLubePermissive('lubeOilPressure', unitData?.lubeOilPressure)}
              {renderLubePermissive('heaterEnabled', unitData?.lubeTankHeaterEnable)}
              {renderLubePermissive('lubeTankOilLevel', unitData?.lubeTankOilLevel)}
              {renderLubePermissive('tankTempTooLowToRun', unitData?.tankTempTooLowToRun)}
              {renderLubePermissive('tankTempTooLowToStart', unitData?.tankTempTooLowToStart)}
            </PermissiveListContainer>
          </Col>
          <Col cols={12}>
            <HeaterPermissiveListContainer>
              <PermissiveTitleContainer>
                <StatusIndicator
                  text={t('view.crusher.lubrication.drawer.lubeTankHeater')}
                  status={
                    permissiveStatusToIndicatorStatus(
                      unitData?.lubeHeaterPermissive,
                      operationalStatus,
                    )
                  }
                  size={StatusIndicatorSize.lg}
                />
              </PermissiveTitleContainer>
              {renderHeaterPermissive('lubeHeaterStarterFault', unitData?.lubeHeaterStarterFault)}
              {renderHeaterPermissive('lubeTankLevel', unitData?.lubeTankLevel)}
              {renderHeaterPermissive(
                'lubeTankTempTransducerFault',
                unitData?.lubeTankTempTransducerFault,
              )}
            </HeaterPermissiveListContainer>
          </Col>
        </Row>
      </LubricationContainer>
    </Drawer>
  );
}

export { LubricationUnit };
