/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Libraries
import { Checkbox as CheckboxAntD } from 'antd';

// Component
import { CheckboxProps, CheckboxSize } from './Checkbox.types';

// Types
import { Theme } from '../../../types/theme';

const mapSize: Record<CheckboxSize, string> = {
  sm: '16px',
  md: '20px',
};

// Function to get border color
const getBorderColor = (
  checked: boolean | undefined,
  disabled: boolean | undefined,
  theme: Theme,
): string => {
  let borderColor: string = 'none';

  if (!disabled) {
    borderColor = !checked ? `${theme.color.checkbox.borderColorHover}` : 'none';
  }

  return borderColor;
};

export const Element = styled(CheckboxAntD)<Pick<CheckboxProps, 'size'>>`
  
  ${({ size }) => `
    .ant-checkbox-input, .ant-checkbox-inner {
      height: ${mapSize[size as CheckboxSize]};
      width: ${mapSize[size as CheckboxSize]};
      border-radius: ${size === CheckboxSize.md ? '6px' : '4px'};
    };
  `};

  user-select: none;
  font-family: ${({ theme }) => theme.font.regular};
  letter-spacing: -0.32px;

  .ant-checkbox-wrapper {
    display: flex;
  };

  .ant-checkbox {
    margin-top: ${({ size }) => (size === CheckboxSize.md ? '3px' : '5px')};
    align-self: flex-start;
  };

  .ant-checkbox-checked:after {
    border-radius: ${({ size }) => (size === CheckboxSize.md ? '6px' : '4px')};
  };

  /* *************** ICON **************** */
  .ant-checkbox-inner::after {
    border-bottom: ${({ disabled, theme }) => (
      disabled
        ? `2px solid ${theme.color.checkbox.disabledBorderColor}`
        : `2px solid ${theme.color.checkbox.checkedIconColor}`
    )};
    border-right: ${({ disabled, theme }) => (
      disabled
        ? `2px solid ${theme.color.checkbox.disabledBorderColor}`
        : `2px solid ${theme.color.checkbox.checkedIconColor}`
    )};
    width: ${({ size }) => (size === CheckboxSize.md ? '6px' : '4px')};
    height: ${({ size }) => (size === CheckboxSize.md ? '9px' : '7px')};
    margin-left: ${({ size }) => (size === CheckboxSize.md ? '1.4px' : '1px')};
  };

  /* *************** INDETERMINATE CONTAINER **************** */
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${({ disabled, theme }) => (
      disabled
        ? `${theme.color.checkbox.disabledBackgroundColor}`
        : `${theme.color.checkbox.checkedBackgroundColor}`
    )};
    border: ${({ disabled, theme }) => (
      disabled
        ? `1px solid ${theme.color.checkbox.disabledCheckedIconColor}`
        : 'inherit'
    )} !important;
  };

  /* *************** INDETERMINATE ICON **************** */
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    height: ${({ size }) => (size === CheckboxSize.md ? '2px' : '1.5px')};
    width: ${({ size }) => (size === CheckboxSize.md ? '9px' : '8px')};
    margin: 0;
    border: none;
    border-radius: 2px;
    background-color: ${({ disabled, theme }) => (
      disabled
        ? `${theme.color.checkbox.disabledCheckedIconColor}`
        : `${theme.color.checkbox.checkedIconColor}`
    )};
  };

  &:hover {
    /* *************** ICON **************** */
    .ant-checkbox-inner {
      border-color: ${({
        checked,
        disabled,
        theme,
      }) => getBorderColor(
        checked,
        disabled,
        theme,
      )} !important;
      border-radius: ${({ size }) => (size === CheckboxSize.md ? '6px' : '4px')} !important;
    };

    /* *************** INDETERMINATE ICON **************** */
    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: ${({ disabled, theme }) => (
        disabled
          ? `${theme.color.checkbox.disabledBackgroundColor}`
          : `${theme.color.checkbox.hoverCheckedBackgroundColor}`
      )} !important;
    };
  };
`;
