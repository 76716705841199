// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      data-testid="alert-warning-fill-icon"
    >
      <path
        d="M9.99984 18.3334C5.39734 18.3334 1.6665 14.6026 1.6665 10.0001C1.6665 5.39758 5.39734 1.66675 9.99984 1.66675C14.6023 1.66675 18.3332 5.39758 18.3332 10.0001C18.3332 14.6026 14.6023 18.3334 9.99984 18.3334ZM9.1665 12.5001V14.1667H10.8332V12.5001H9.1665ZM9.1665 5.83342V10.8334H10.8332V5.83342H9.1665Z"
        fill="#FCC206"
      />
    </svg>
  );
}

export default Icon;
