// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import {
  runningStatusToCountershaftIndicator,
  runningStatusToIndicatorStatus,
} from '../../../../plugins/crusher';

// Components
import { Paragraph, ParagraphWeight } from '../../../../layout/elements/Paragraph';
import { StatusIndicator } from '../../../../layout/elements/StatusIndicator';

// View
import {
  IndicatorsContainer,
  StatusContainer,
  TitleContainer,
} from './IndicatorsModule.style';
import { CrusherIndicatorsModuleProps } from './IndicatorsModule.types';

function IndicatorsModule({
  className,
  operationalStatus,
  style,
  testId,
  unitData,
}: CrusherIndicatorsModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  return (
    <IndicatorsContainer
      data-testid={testId}
      style={style}
      className={className}
    >
      <TitleContainer>
        <Paragraph weight={ParagraphWeight.bold}>
          {t('view.crusher.runningStatus.title')}
        </Paragraph>
      </TitleContainer>
      <StatusContainer>
        <StatusIndicator
          text={t('view.crusher.runningStatus.hydUnit')}
          status={runningStatusToIndicatorStatus(
            unitData?.hydraulicPumpRunningStatus,
            operationalStatus,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.motor')}
          status={runningStatusToIndicatorStatus(
            unitData?.crusherMotor,
            operationalStatus,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.lubeUnit')}
          status={runningStatusToIndicatorStatus(
            unitData?.lubePumpRunningStatus,
            operationalStatus,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.discharge')}
          status={runningStatusToIndicatorStatus(
            unitData?.dischargeRunningStatus,
            operationalStatus,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.feeder')}
          status={runningStatusToIndicatorStatus(
            unitData?.feederRunningStatus,
            operationalStatus,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.blower')}
          status={runningStatusToCountershaftIndicator(
            unitData?.countershaftBlowerRunningStatus,
            operationalStatus,
          )}
          className="status"
        />
      </StatusContainer>
    </IndicatorsContainer>
  );
}

export { IndicatorsModule };
