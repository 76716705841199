// Core
import React from 'react';

// Libraries
import { TableProps as TablePropsAntD } from 'antd';

// Component
import { Element } from './Table.style';
import { TableProps } from './Table.types';

// Type
type AntDTableProps = TablePropsAntD<any>;

function Table({
  className,
  columns,
  fixedHeader = false,
  onClickRow,
  pagination = false,
  rows,
  testId,
}: TableProps) {
  // Sorts rows based on the value of a specified data index
  const sortRows = (a: any, b: any, dataIndex: string): number => {
    let sortResult: number = 0;
    const valueA: string | number = a[dataIndex];
    const valueB: string | number = b[dataIndex];

    // Sort numbers
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      sortResult = valueA - valueB;
    }

    // Sort strings
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      sortResult = valueA.localeCompare(valueB);
    }

    // Fallback if types are different or not handled
    return sortResult;
  };

  // Convert columns to AntD columns
  const columnsToAntDColumns: AntDTableProps['columns'] = columns.map((column) => {
    if (column.sorter) {
      return {
        ...column,
        sorter: (a: any, b: any) => sortRows(a, b, column.dataIndex),
      };
    }
    return column;
  });

  return (
    <Element
      className={className}
      columns={columnsToAntDColumns}
      data-testid={testId}
      dataSource={rows}
      onRow={onClickRow}
      pagination={pagination}
      scroll={{ x: 'max-content' }}
      showSorterTooltip={false}
      sticky={fixedHeader}
    />
  );
}

export { Table };
