// Core
import React, {
  RefObject,
  useRef,
  useState,
} from 'react';

// Libraries
import ReactHlsPlayer from 'react-hls-player';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

// Layout
import { Map } from '../../layout/elements/Map';
import { ModalPanel } from '../../layout/elements/ModalPanel';
import {
  CloudOffLine,
  Expand,
  // Live, @TODO: uncomment this and apply the camera logic after first release
  MapOutline,
} from '../../layout/icons';
import {
  Paragraph,
  ParagraphWeight,
  ParagraphAlign,
  ParagraphSize,
} from '../../layout/elements/Paragraph';

// Component
import {
  ActionsContainer,
  // CamButton, // @TODO: uncomment this and apply the camera logic after first release
  CameraContainer,
  CameraFeedContainer,
  CameraOffOverlay,
  CameraOnOverlay,
  CloudOff,
  CrusherSimulationImage,
  FullScreenButton,
  LiveContainer,
  LiveIndicator,
  MapButton,
  MapViewContainer,
  OfflineMessageContainer,
  SwitchContainer,
  ContainerTitle,
} from './CameraFeed.style';
import { CameraFeedProps, LiveStatus, ViewType } from './CameraFeed.types';

// Types
import { Theme } from '../../types/theme';

// Media
import crusherSimulation from '../../media/crusherSimulation.gif';

function CameraFeed({
  centerCoordinates,
  className,
  live = LiveStatus.off,
  pins,
  polygons,
  src,
  testId,
  title,
}: CameraFeedProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  /* **********************************************************************************************
  ***************************************** LOCAL STATES ******************************************
  ********************************************************************************************** */

  /* Initial state */
  const initialState: {
    modalOpen: boolean,
    currentView: ViewType,
  } = {
    modalOpen: false,
    currentView: ViewType.map, // @TODO: switch to camera after first release
  };

  const [state, setState] = useState(initialState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  const playerRef: RefObject<HTMLVideoElement> = useRef(null);
  const containerRef: RefObject<HTMLDivElement> = useRef(null);

  // Function that activates autoplay when the status is on
  const autoPlay = (liveStatus: CameraFeedProps['live']): boolean => liveStatus === LiveStatus.on;

  // Error message for unauthorized [src]
  if (src && !src.endsWith('.m3u8')) {
    throw new Error('Error: [src] invalid url, expected .m3u8');
  }

  // Function that opens the full screen
  const handleOpenFullScreen = (): void => {
    setState((prevState) => ({ ...prevState, modalOpen: true }));
  };

  // Function that closes the full screen
  const handleCloseModal = (): void => {
    setState((prevState) => ({ ...prevState, modalOpen: false }));
  };

  // @TODO: uncomment this and apply the camera logic after first release
  // Function that toggles between camera and map view
  // const handleToggleView = (): void => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     currentView: prevState.currentView === ViewType.camera ? ViewType.map : ViewType.camera,
  //   }));
  // };

  const actionButtons = ({ isModalOpen }: { isModalOpen: boolean }): JSX.Element => (
    <ActionsContainer>
      <SwitchContainer>
        {/* @TODO: uncomment this and apply the camera logic after first release */}
        {/* <CamButton currentView={state.currentView} onClick={handleToggleView}> */}
        {/*  <Live /> */}
        {/* </CamButton> */}
        <MapButton currentView={state.currentView} onClick={() => {}}>
          <MapOutline />
        </MapButton>
      </SwitchContainer>
      {!isModalOpen && (
        <FullScreenButton onClick={handleOpenFullScreen}>
          <Expand />
        </FullScreenButton>
      )}
    </ActionsContainer>
  );

  const cameraOn = (): JSX.Element => (
    <>
      <CameraOnOverlay />
      <LiveContainer>
        <LiveIndicator />
        <Paragraph
          weight={ParagraphWeight.medium}
          testId="live-text"
          color={theme.color.cameraFeed.textColor}
        >
          {t('common.live')}
        </Paragraph>
      </LiveContainer>
      {src && src.endsWith('.m3u8')
        ? (
          <ReactHlsPlayer
            id="react-hls-player"
            src={`${src}`}
            playerRef={playerRef}
            autoPlay={autoPlay(live)}
            muted
          />
        ) : (
          <CrusherSimulationImage src={crusherSimulation} alt="Crusher Simulation" />
        )}
    </>
  );

  const cameraOff = (): JSX.Element => (
    <>
      <CameraOffOverlay />
      <OfflineMessageContainer>
        <CloudOff>
          <CloudOffLine />
        </CloudOff>
        <Paragraph
          weight={ParagraphWeight.medium}
          size={ParagraphSize.xl}
          color={theme.color.cameraFeed.textColor}
        >
          {t('layout.elements.cameraFeed.cameraOffline')}
        </Paragraph>
        <Paragraph
          align={ParagraphAlign.center}
          color={theme.color.cameraFeed.textColor}
        >
          {t('layout.elements.cameraFeed.cameraOffDescription')}
        </Paragraph>
      </OfflineMessageContainer>
    </>
  );

  return (
    <CameraFeedContainer
      data-testid={testId}
      className={className}
      ref={containerRef}
    >
      <ContainerTitle>
        <Paragraph
          weight={ParagraphWeight.medium}
          size={ParagraphSize.sm}
          color={theme.color.cameraFeed.textColor}
        >
          {title}
        </Paragraph>
      </ContainerTitle>
      {actionButtons({ isModalOpen: false })}
      {state.currentView === ViewType.map
        ? (
          <MapViewContainer>
            <Map
              pins={pins}
              polygons={polygons}
              centerCoordinates={centerCoordinates}
            />
          </MapViewContainer>
        ) : (
          <CameraContainer>
            {
              state.currentView === ViewType.camera
              && live === LiveStatus.on ? cameraOn() : cameraOff()
            }
          </CameraContainer>
        )}
      <ModalPanel
        onClose={handleCloseModal}
        showModal={state.modalOpen}
        title={title}
      >
        {state.currentView === ViewType.map
          ? (
            <MapViewContainer>
              {actionButtons({ isModalOpen: true })}
              <Map
                pins={pins}
                polygons={polygons}
                centerCoordinates={centerCoordinates}
              />
            </MapViewContainer>
          ) : (
            <CameraContainer>
              {actionButtons({ isModalOpen: true })}
              {
                state.currentView === ViewType.camera
                && live === LiveStatus.on ? cameraOn() : cameraOff()
              }
            </CameraContainer>
          )}
      </ModalPanel>
    </CameraFeedContainer>
  );
}

export { CameraFeed };
