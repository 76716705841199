// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 46 45"
      fill="none"
      data-testid="spin-icon"
    >
      <path
        d="M34.9325 10.5676L32.2813 13.2188C30.1342 11.0715 27.3091 9.73517 24.2872 9.43737C21.2653 9.13958 18.2336 9.89879 15.7088 11.5857C13.1839 13.2725 11.322 15.7827 10.4404 18.6884C9.55876 21.5941 9.71192 24.7156 10.8738 27.5211C12.0356 30.3265 14.1343 32.6423 16.8122 34.0739C19.4901 35.5055 22.5815 35.9643 25.5597 35.3721C28.5379 34.7799 31.2187 33.1734 33.1452 30.8262C35.0717 28.4791 36.1248 25.5366 36.125 22.5001H39.875C39.875 26.4042 38.5212 30.1875 36.0445 33.2054C33.5677 36.2233 30.1211 38.2891 26.292 39.0507C22.4629 39.8123 18.4882 39.2227 15.0451 37.3823C11.602 35.5418 8.9035 32.5645 7.40949 28.9576C5.91547 25.3506 5.71834 21.3372 6.85167 17.6012C7.985 13.8652 10.3787 10.6378 13.6249 8.46879C16.871 6.2998 20.7688 5.32348 24.6542 5.70617C28.5395 6.08887 32.1719 7.80691 34.9325 10.5676Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
