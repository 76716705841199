// Core
import styled from 'styled-components';

export const OverallStatusContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-top: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-right: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
`;

export const WinterModeStatusContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-top: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};  
  border-right: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
`;

export const CoolerFanStatusContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-top: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
`;

export const LubricationContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  height: 100%;
  flex-direction: column;
  border-radius: 4px;
  display: flex;
`;

export const LubricationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const LubePressureStatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ThermometersContainer = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
