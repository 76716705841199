// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  overallStatusToTagType,
  overallStatusToText,
} from '../../../../plugins/crusher';

// Types
import { EdgeDeviceOperationalStatus } from '../../../../types/global';
import { Theme } from '../../../../types/theme';

// Components
import { Col } from '../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../layout/elements/ModuleHeader';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../layout/elements/Paragraph';
import { Row } from '../../../../layout/elements/Row';
import { Tag } from '../../../../layout/elements/Tag';

// View
import { CrusherHydraulicModuleProps } from './HydraulicModule.types';
import {
  ClampPressureContainer,
  HydraulicContainer,
  OverallStatusContainer,
  SpeedometersContainer,
  TankTempStatusContainer,
  TrampPressureContainer,
} from './HydraulicModule.style';
import { Speedometer } from '../../../../layout/elements/Speedometer';

function HydraulicModule({
  className,
  onOpenDrawer,
  operationalStatus,
  style,
  testId,
  unitData,
}: CrusherHydraulicModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  return (
    <HydraulicContainer
      data-testid={testId}
      className={className}
      style={style}
    >
      <Row>
        <Col>
          <ModuleHeader
            onClick={onOpenDrawer}
            title={t('view.crusher.hydraulic.title')}
          />
        </Col>
      </Row>
      <Row>
        <Col cols={12}>
          <OverallStatusContainer>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              color={theme.color.crusherDashboard.statusTitleColor}
            >
              {t('view.crusher.overallStatus')}
            </Paragraph>
            <Tag
              text={overallStatusToText(unitData?.hydraulicOverallStatus)}
              type={overallStatusToTagType(
                unitData?.hydraulicOverallStatus,
                operationalStatus,
              )}
            />
          </OverallStatusContainer>
        </Col>
        <Col cols={12}>
          <TankTempStatusContainer>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              color={theme.color.crusherDashboard.statusTitleColor}
            >
              {t('view.crusher.hydraulic.tankTemp')}
            </Paragraph>
            <Tag
              text={overallStatusToText(unitData?.hydraulicTankTempStatus)}
              type={overallStatusToTagType(
                unitData?.hydraulicTankTempStatus,
                operationalStatus,
              )}
            />
          </TankTempStatusContainer>
        </Col>
      </Row>
      <Row style={{ flex: 1 }}>
        <SpeedometersContainer>
          <ClampPressureContainer>
            <Col cols={12}>
              <Speedometer
                minValue={0}
                maxValue={3625}
                value={unitData?.hydraulicClampPressure}
                unit="psi"
                thresholds={{ lowAlarm: 2400, highAlarm: 3100 }}
                title={`${t('view.crusher.hydraulic.clampPressure')}`}
                running={operationalStatus === EdgeDeviceOperationalStatus.on}
              />
            </Col>
          </ClampPressureContainer>

          <TrampPressureContainer>
            <Col cols={12}>
              <Speedometer
                minValue={0}
                maxValue={3625}
                value={unitData?.hydraulicTrampPressure}
                unit="psi"
                thresholds={{ lowAlarm: 1900 }}
                title={`${t('view.crusher.hydraulic.trampPressure')}`}
                running={operationalStatus === EdgeDeviceOperationalStatus.on}
              />
            </Col>
          </TrampPressureContainer>
        </SpeedometersContainer>
      </Row>
    </HydraulicContainer>
  );
}

export { HydraulicModule };
