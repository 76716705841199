// Core
import React, {
  useEffect,
  useState,
} from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

// Libraries
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Store
import { getUser, User } from '../../../store/session.store';

// Types
import { UserType } from '../../../types/global';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../plugins/request';

// Components
import { AdminPanel } from '../../../layout/AdminPanel';
import { Button, ButtonSize } from '../../../layout/elements/Button';
import { Input, InputType } from '../../../layout/elements/Input';
import { PageHeader } from '../../../layout/elements/PageHeader';
import { showToast, ToastType } from '../../../layout/elements/Toast';

// Component
import {
  Container,
  Content,
  InputContainer,
} from './AdminActions.style';

function AdminActions(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  interface State {
    email: string | null;
    password: string | null;
    buttonLoading: number | null;
    isSuccess: boolean;
    errorMsg: string | null;
  }

  const initialState: State = {
    email: null,
    password: null,
    buttonLoading: null,
    isSuccess: false,
    errorMsg: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  // Get user
  const user: User = getUser() as User;

  // Redirects if the user is not an admin
  if (user.type !== UserType.admin) {
    navigate('/plant-management');
  }

  const updateField = (field: string, value?: string | null | number): void => {
    setState((prevState: State) => ({
      ...prevState,
      [field]: value as string | null,
      error: null,
    }));
  };

  const handleReset = async (): Promise<void> => {
    setState((prevState: State) => ({
      ...prevState,
      buttonLoading: 0,
    }));

    try {
      await requestVantageApi.users({
        method: RequestMethod.PUT,
        path: '/auth/password-reset',
        body: {
          email: state.email,
          password: state.password,
        },
      });

      // Show success toast
      showToast({
        text: `${t('view.adminActions.passwordReset.toast.success')}`,
        type: ToastType.success,
      });

      setState((prevState: State) => ({
        ...prevState,
        buttonLoading: 100,
        isSuccess: true,
      }));
    } catch (error: unknown) {
      setState({ ...state, buttonLoading: null });
    }
  };

  const isDisabledToSubmitForm: boolean = (
    _.isNil(state.email) || _.isNil(state.password)
  );

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  // Effect to reset fields after success
  useEffect((): void => {
    setState((prevState: State) => ({
      ...prevState,
      email: null,
      password: null,
      buttonLoading: null,
      isSuccess: false,
    }));
  }, [state.isSuccess]);

  return (
    <AdminPanel>
      <Container>
        <Content>
          <PageHeader
            title={`${t('view.adminActions.passwordReset.title')}`}
          />
          <InputContainer>
            <Input
              error={state.errorMsg !== null}
              label={`${t('common.email')}`}
              testId="input-email"
              onChange={(value?: string | number | null) => updateField('email', value)}
              value={state.email}
              onPressEnter={() => {
                const inputs = Array.from(document.querySelectorAll('input'))[1];
                if (inputs) inputs.focus();
              }}
            />
            <Input
              error={state.errorMsg !== null}
              label={`${t('common.password')}`}
              onChange={(value?: string | number | null) => updateField('password', value)}
              testId="input-password"
              type={InputType.password}
              value={state.password}
              onPressEnter={handleReset}
            />
          </InputContainer>

          <Button
            size={ButtonSize.lg}
            loading={state.buttonLoading}
            disabled={isDisabledToSubmitForm}
            loadingText={`${t('view.adminActions.passwordReset.loadingText')}`}
            onClick={handleReset}
            testId="reset-password-button"
          >
            {t('view.adminActions.passwordReset.button')}
          </Button>
        </Content>
      </Container>
    </AdminPanel>
  );
}

export { AdminActions };
