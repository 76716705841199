// Core
import React from 'react';
import styled from 'styled-components';

// Types
import { Theme } from '../../../types/theme';

// Component
import {
  TitleColor,
  TitleProps,
  TitleSize,
  TitleWeight,
} from './Title.types';

const mapFontBySize: Record<TitleSize, string> = {
  [TitleSize.xxs]: '24px',
  [TitleSize.xs]: '32px',
  [TitleSize.sm]: '36px',
  [TitleSize.md]: '40px',
  [TitleSize.lg]: '46px',
  [TitleSize.xl]: '56px',
  [TitleSize.xxl]: '64px',
};

const mapTagBySize: Record<TitleSize, React.ElementType> = {
  [TitleSize.xxs]: 'h6',
  [TitleSize.xs]: 'h6',
  [TitleSize.sm]: 'h5',
  [TitleSize.md]: 'h4',
  [TitleSize.lg]: 'h3',
  [TitleSize.xl]: 'h2',
  [TitleSize.xxl]: 'h1',
};

const mapLineHeightBySize: Record<TitleSize, string> = {
  [TitleSize.xxs]: '34px',
  [TitleSize.xs]: '42px',
  [TitleSize.sm]: '46px',
  [TitleSize.md]: '54px',
  [TitleSize.lg]: '56px',
  [TitleSize.xl]: '66px',
  [TitleSize.xxl]: '74px',
};

/*
  Verifies the title [weight] and returns the [font-weight]
  @param theme should receive the theme object
*/
const mapFontWeight = (theme: Theme): Record<TitleWeight, string> => ({
  [TitleWeight.regular]: theme.font.regular,
  [TitleWeight.medium]: theme.font.medium,
  [TitleWeight.bold]: theme.font.bold,
});

export const Element = styled(
  ({ size, ...rest }) => React.createElement(mapTagBySize[size as TitleSize], rest),
)<Pick<TitleProps, 'size' | 'weight' | 'color'>>`
  /* ******************* FONT SIZE ******************* */
  font-size: ${({ size }) => mapFontBySize[size as TitleSize]};

  /* ******************* FONT FAMILY ******************* */
  font-family: ${({ weight, theme }) => mapFontWeight(theme)[weight as TitleWeight]};

  /* ******************* LINE HEIGHT ******************* */
  line-height: ${({ size }) => mapLineHeightBySize[size as TitleSize]};

  /* ******************* ALIGN ******************* */
  text-align: ${({ align }) => align};
  
  /* ******************* COLOR ******************* */
  color: ${({ color, theme }) => (
    color === TitleColor.default
      ? theme.color.title.default
      : color
  )};
  
  letter-spacing: -0.02em;
`;
