// Core
import styled from 'styled-components';

// Media
import loginBackgroundSuperior from '../../media/images/loginBackgroundSuperior.png';

export const LeftPanel = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: 1;
  position: relative;
`;

export const ContentContainer = styled.div`
  flex: 1;
`;

export const ImageContainer = styled.div`
  margin-left: -90px;
  padding: 8px;
  display: flex;
  height: 100vh;
  align-items: stretch;
`;

export const Image = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  background-repeat: no-repeat;
  background-image: url(${loginBackgroundSuperior});
  background-size: cover;
  background-position: bottom left;
`;

export const NeedHelpContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  z-index: 1;
  position: relative;
`;
