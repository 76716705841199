// Core
import styled from 'styled-components';

// Plugins
import { hexToRgba } from '../../plugins/general';

export const OptionsDropdownContainer = styled.div`
  visibility: hidden;
  position: absolute;
  padding-left: 256px;
  padding-top: 16px;
`;

export const CardCustomerComponent = styled.div`
  width: 306px;
  height: 226px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.5px solid ${({ theme }) => theme.color.cardCustomer.borderColor};
  background: ${({ theme }) => theme.color.cardCustomer.backgroundColor};
  cursor: pointer;
  transition: all 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  > svg {
    width: 14px;
    height: 14px;
  };

  &:hover {
    ${OptionsDropdownContainer} {
      visibility: visible;
    };
  };
  
  /* ************* HOVER ************* */
  &:hover {
    box-shadow: 2px 2px 6px ${({ theme }) => hexToRgba(theme.color.cardCustomer.hoverShadowColor, 0.5)};
    border: 1px solid ${({ theme }) => theme.color.cardCustomer.hoverBorderColor};
  };
`;

export const CustomerPreviewContainer = styled.div`
  width: 100%;
  min-height: 120px;
  border-bottom: 1px solid ${({ theme }) => theme.color.cardCustomer.borderColor};
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomerPreview = styled.img`
  width: 50%;
  object-fit: contain;
`;

export const CustomerNameContainer = styled.div`
  width: 260px;
  margin-left: 20px;
  height: 28px;
`;

export const ContentContainer = styled.div`
  width: max-content;
  max-width: 100%;
`;

export const MetricsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 16px;
`;

export const ActiveUsersContainer = styled.div`
  padding-left: 4px;
  width: 96px;
  height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  box-sizing: border-box;
  padding-right: 16px;
  color: ${({ theme }) => theme.color.cardCustomer.metricsColor};
  > svg {
    min-width: 14px;
    min-height: 14px;
  };
`;

export const EquipmentUnitsContainer = styled.div`
  padding-left: 12px;
  width: 96px;
  height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.cardCustomer.metricsColor};
  > svg {
    min-width: 14px;
    min-height: 14px;
  };
`;

export const SitesContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.cardCustomer.metricsBorderColor};
  border-right: 1px solid ${({ theme }) => theme.color.cardCustomer.metricsBorderColor};
  width: 96px;
  height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.cardCustomer.metricsColor};
  gap: 6px;
  white-space: nowrap;
  > svg {
    min-width: 14px;
    min-height: 14px;
  };
`;
