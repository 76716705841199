// Core
import React from 'react';

// Layout
import { Close, Arrow } from '../../icons';

// Component
import {
  ActionContainer,
  Element,
  PrimaryActionButton,
  SecondaryActionButton,
} from './Alert.style';
import {
  AlertAction,
  AlertType,
  AlertProps,
} from './Alert.types';

function Alert({
  className,
  closable = true,
  description,
  icon,
  primaryAction,
  secondaryAction,
  testId,
  title,
  type = AlertType.success,
}: AlertProps): JSX.Element {
  // Render primary action
  const renderPrimaryAction = ({ text, onClick }: AlertAction): JSX.Element => (
    <PrimaryActionButton
      type={type}
      onClick={onClick}
      role="button"
    >
      {text}
      <Arrow />
    </PrimaryActionButton>
  );

  // Render secondary action
  const renderSecondaryAction = ({ text, onClick }: AlertAction): JSX.Element => (
    <SecondaryActionButton
      type={type}
      role="button"
      onClick={onClick}
    >
      {text}
    </SecondaryActionButton>
  );

  return (
    <Element
      data-testid={testId}
      className={className}
      type={type}
      message={title as string} // @TODO - Fix this after first release
      description={description}
      closable={closable}
      icon={icon}
      action={(
        <ActionContainer>
          { secondaryAction && renderSecondaryAction(secondaryAction) }
          { primaryAction && renderPrimaryAction(primaryAction) }
        </ActionContainer>
      )}
      closeIcon={<Close />}
      showIcon
    />
  );
}

export { Alert };
