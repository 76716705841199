// Core
import styled from 'styled-components';

// Libraries
import { Table as TableAntD } from 'antd';

export const Element = styled(TableAntD)`
  padding: 0 44px 40px 44px;
  gap: 6px;
  letter-spacing: -0.5px;

  /* ********** PAGINATION ********** */
  .ant-pagination {
    display: none;
  };

  /* ********** ROWS ********** */
  .ant-table-tbody > tr > td:not(:first-child):not(:last-child) {
    max-width: 350px;
    min-width: 140px;
  };

  .ant-table-tbody > tr > td:first-child, 
  .ant-table-tbody > tr > td:last-child {
    height: 80px;
    max-width: 350px;
    min-width: max-content;
  };

  /* ********** ROW HOVER ********** */
  .ant-table-row:hover > td {
    background: none !important;
  };
    
  .ant-table-tbody > tr:hover {
    background-color: ${({ theme }) => theme.color.table.hoverRowBackgroundColor};
  };

  /* ********** FONT FAMILY ********** */
  .ant-table-cell > tr {
    font-family: ${({ theme }) => theme.font.regular};
  };

  /* ********** IMG ********** */
  .ant-table-cell img {
    width: 64px;
    height: 64px;
    padding: 6px 12px 6px 0;
    object-fit: contain;
    display: block;
  };

  /* ********** COLUMN TITLE ********** */
  .ant-table-thead > tr > th:not(:first-child):not(:last-child) {
    padding-left: 12px;
    max-width: 350px;
    min-width: 140px;
    user-select: none;
  };
  
  .ant-table-thead > tr > th:first-child {
    max-width: 350px;
    min-width: 140px;
    user-select: none;
  };
    
  &.ant-table-wrapper .ant-table-column-title {
    flex: none;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    user-select: none;
    font-family: ${({ theme }) => theme.font.regular};
  };

  /* ********** TITLE WHEN SORTER ********** */
  &.ant-table-wrapper .ant-table-column-sorters {
    display: flex;
    align-items: center;
    justify-content: unset;
    min-width: 140px;
  };

  /* ********** BORDER BOTTOM ********** */
  &.ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${({ theme }) => theme.color.table.borderColor};
  };
`;
