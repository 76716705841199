import React, { ReactNode } from 'react';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  link = 'link',
}

export enum ButtonColor {
  primary = 'primary',
  neutral = 'neutral',
  danger = 'danger',
}

export enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export interface ButtonProps {
  block?: boolean;
  children: ReactNode | ReactNode[];
  className?: string;
  color?: ButtonColor;
  disabled?: boolean;
  loading?: number | null | true; // true means undetermined processing (animation)
  loadingText?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  size?: ButtonSize;
  square?: boolean;
  testId?: string;
  type?: ButtonType;
}
