// Core
import React, { useCallback, useEffect, useRef } from 'react';

// Libraries
import { useTheme } from 'styled-components';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';

// Types
import { Theme } from '../../../types/theme';

// Component
import { CoordinatesType, MapProps } from './Map.types';
import { Container } from './Map.style';

const API_KEY: string | undefined = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const containerStyle: Record<string, string> = {
  height: '100%',
  width: '100%',
};

function Map({
  centerCoordinates,
  className,
  polygons,
  pins,
  testId,
}: MapProps): JSX.Element {
  const theme: Theme = useTheme();
  const mapRef = useRef<google.maps.Map | null>(null);
  const polygonRef = useRef<google.maps.Polygon | null>(null);

  if (!API_KEY) {
    throw new Error('Google Maps API key is missing');
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    id: 'google-map-script',
  });

  const handleMapLoad = useCallback((map: google.maps.Map): void => {
    mapRef.current = map;
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
    }
    if (polygons) {
      const contouredArea: google.maps.Polygon = polygons && new window.google.maps.Polygon({
        fillColor: theme.color.mapView.fillColor,
        fillOpacity: 0.1,
        paths: polygons,
        strokeColor: theme.color.mapView.strokeColor,
        strokeWeight: 2,
      });

      contouredArea.setMap(map);
      polygonRef.current = contouredArea;
    }
  }, [polygons, theme.color.mapView.fillColor, theme.color.mapView.strokeColor]);

  const handleMapUnmount = useCallback((): void => {
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      polygonRef.current = null;
    }
    mapRef.current = null;
  }, []);

  useEffect((): void => {
    if (mapRef.current) {
      handleMapLoad(mapRef.current);
    }
  }, [handleMapLoad, polygons]);

  return (
    <Container
      className={className}
      data-testid={testId}
    >
      {isLoaded && (
        <GoogleMap
          onLoad={handleMapLoad}
          onUnmount={handleMapUnmount}
          center={centerCoordinates}
          mapContainerStyle={containerStyle}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: 'satellite',
            streetViewControl: false,
            zoomControl: false,
          }}
          zoom={18}
        >
          {pins && pins.map((coordinate: CoordinatesType) => (
            <Marker key={`${coordinate.lat}-${coordinate.lng}`} position={coordinate} />
          ))}
        </GoogleMap>
      )}
    </Container>
  );
}

export { Map };
