// Libraries
import create from 'zustand';
import { persist } from 'zustand/middleware';

export interface AppState {
  language: string | null;
  rememberMe: boolean;
}

export const useAppStore = create(
  persist<AppState>(
    () => ({
      language: null,
      rememberMe: false,
    }),
    {
      name: 'app-store',
      getStorage: () => localStorage,
    },
  ),
);

// Getters
export const getLanguage = (): string | null => useAppStore.getState().language;
export const getRememberMe = (): boolean => useAppStore.getState().rememberMe;

// Setters
export const setLanguage = (language: string): void => useAppStore.setState({ language });
export const setRememberMe = (rememberMe: boolean): void => useAppStore.setState({ rememberMe });
