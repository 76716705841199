// Core
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Libraries
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  requestVantageApi,
  RequestMethod,
  isHTTPResponseError,
} from '../../plugins/request';

// Store
import {
  setAuthToken,
  setUser,
  User,
} from '../../store/session.store';

// Types
import { UserType } from '../../types/global';

// Layout
import { Alert, AlertType } from '../../layout/elements/Alert';
import { Button, ButtonSize } from '../../layout/elements/Button';
import { Checkbox, CheckboxSize } from '../../layout/elements/Checkbox';
import { Input, InputType } from '../../layout/elements/Input';
import { LeftPanelImageRight } from '../../layout/LeftPanelImageRight';
import {
  LinkTo,
  LinkToColor,
  LinkToType,
  LinkToWeight,
} from '../../layout/elements/LinkTo';
import { Paragraph, ParagraphAlign } from '../../layout/elements/Paragraph';
import {
  Title,
  TitleAlign,
  TitleSize,
  TitleWeight,
} from '../../layout/elements/Title';
import { AlertLine } from '../../layout/icons';

// Style
import {
  AlertContainer,
  Description,
  FormContentContainer,
  InputEmail,
  LoginContentContainer,
  OptionsLoginContainer,
  RegisteredTrademark,
  TitleContainer,
} from './Login.style';

function Login(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  interface LoginFormState {
    email: string | null,
    error: string | null,
    buttonLoading: number | null | true,
    password: string | null,
    rememberMe: boolean,
  }

  const initialLoginFormState: LoginFormState = {
    email: null,
    error: null,
    buttonLoading: null,
    password: null,
    rememberMe: false,
  };

  const [loginFormState, setLoginFormState] = useState<LoginFormState>(initialLoginFormState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  // Function to handle errors
  const handleLoginFormErrors = (error: unknown): void => {
    let errorMessage = t('common.defaultError');

    // Check if the error is an HTTP response error
    if (isHTTPResponseError(error)) {
      if (error.message.includes('Network Error')) {
        errorMessage = t('common.networkError');
      } else if (error.response.status === 401) {
        errorMessage = t('view.login.invalidUserOrPassword');
      }
    }

    setLoginFormState({ ...loginFormState, error: errorMessage });
  };

  /**
  * Submits the login form data to the authentication endpoint.
  *
  * @returns {Promise<void>} A promise that resolves when the login process is complete.
  */
  const submitLoginForm = async (): Promise<void> => {
  // Set the button loading state to true
    setLoginFormState({ ...loginFormState, buttonLoading: true });

    try {
      // Send the login request to the authentication endpoint
      const authResponse: string = await requestVantageApi.users({
        method: RequestMethod.POST,
        path: '/auth/authenticate',
        body: {
          email: loginFormState.email,
          password: loginFormState.password,
        },
        handleErrors: false,
      });

      // Set the authentication token
      setAuthToken(authResponse);
      const decodedToken: { user: string } = jwtDecode(authResponse);
      const parsedUser: User = JSON.parse(decodedToken.user);
      setUser(parsedUser);

      // Update the button loading state to 100%
      setLoginFormState({ ...loginFormState, buttonLoading: 100 });

      // Navigate to the /customers when the user is an admin
      if (parsedUser.type === UserType.admin) {
        navigate('/');
      } else {
        navigate('/plant-management');
      }
    } catch (error) {
    // Update the button loading state to 100% in case of an error
      setLoginFormState({ ...loginFormState, buttonLoading: null });
      // Handle login form errors
      handleLoginFormErrors(error);
    }
  };

  /**
  * Toggles the "Remember Me" checkbox state.
  *
  * This function updates the `rememberMe` property in the `loginFormState` object
  * by setting it to the opposite of its current value.
  */
  const toggleRememberMe = (): void => {
    setLoginFormState((prevState) => ({
      ...prevState,
      rememberMe: !prevState.rememberMe,
    }));
  };

  /**
  * Updates a specific field in the login form state.
  *
  * @param {string} field - The name of the field to update.
  * @param {string | null | number} [value] - The new value for the field. Defaults to undefined.
  */
  const updateField = (field: string, value?: string | null | number): void => {
    setLoginFormState((prevState) => ({
      ...prevState,
      [field]: value as string | null,
      error: null,
    }));
  };

  /**
  * Determines if the login form submit button should be disabled.
  * The button is disabled if both the email and password fields are null or undefined.
  * @type {boolean}
  */
  const isDisabledToSubmitLoginForm: boolean = (
    _.isNil(loginFormState.email) || _.isNil(loginFormState.password)
  );

  return (
    <LeftPanelImageRight>
      <LoginContentContainer>
        <FormContentContainer>

          <TitleContainer>
            <Title
              align={TitleAlign.center}
              size={TitleSize.xs}
              weight={TitleWeight.bold}
            >
              {t('view.login.title').split('{trademark}')[0]}
              <RegisteredTrademark>®</RegisteredTrademark>
              {t('view.login.title').split('{trademark}')[1]}
            </Title>
          </TitleContainer>

          <Description>
            <Paragraph align={ParagraphAlign.center}>
              {t('view.login.instructions')}
            </Paragraph>
          </Description>

          {loginFormState.error && (
            <AlertContainer>
              <Alert
                closable={false}
                description={loginFormState.error}
                icon={<AlertLine />}
                testId="errorAlert"
                type={AlertType.error}
              />
            </AlertContainer>
          )}

          <InputEmail>
            <Input
              error={loginFormState.error !== null}
              label={`${t('common.email')}`}
              onChange={(value?: string | number | null) => updateField('email', value)}
              testId="input-email"
              value={loginFormState.email}
              onPressEnter={() => {
                const inputs = Array.from(document.querySelectorAll('input'))[1];
                if (inputs) inputs.focus();
              }}
            />
          </InputEmail>

          <Input
            error={loginFormState.error !== null}
            label={t('common.password') || ''}
            onChange={(value?: string | number | null) => updateField('password', value)}
            testId="input-password"
            type={InputType.password}
            value={loginFormState.password}
            onPressEnter={!isDisabledToSubmitLoginForm ? submitLoginForm : undefined}
          />

          <OptionsLoginContainer>
            <Checkbox
              checked={loginFormState.rememberMe}
              onChange={toggleRememberMe}
              size={CheckboxSize.sm}
              text={`${t('view.login.rememberMe')}`}
            />
            <LinkTo // @TODO - Add the correct link
              color={LinkToColor.primary}
              target="/"
              type={LinkToType.link}
              weight={LinkToWeight.medium}
            >
              {t('view.login.forgotPassword')}
            </LinkTo>
          </OptionsLoginContainer>

          <Button
            size={ButtonSize.lg}
            loading={loginFormState.buttonLoading}
            disabled={isDisabledToSubmitLoginForm}
            loadingText={`${t('view.login.signingIn')}`}
            onClick={submitLoginForm}
            testId="signing-in-button"
          >
            {t('view.login.signIn')}
          </Button>

        </FormContentContainer>
      </LoginContentContainer>
    </LeftPanelImageRight>
  );
}

export { Login };
