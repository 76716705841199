// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Layout
import { Wifi, WifiOffline } from '../../layout/icons';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../layout/elements/Paragraph';

// Types
import { Theme } from '../../types/theme';

// Components
import { ConnectionStatusProps, ConnectionStatusStatus } from './ConnectionStatus.types';
import { ConnectionStatusComponent } from './ConnectionStatus.style';

function ConnectionStatus({
  className,
  status = ConnectionStatusStatus.online,
  testId,
}: ConnectionStatusProps) {
  const theme: Theme = useTheme();

  return (
    <ConnectionStatusComponent
      data-testid={testId}
      className={className}
      status={status}
    >
      {status === ConnectionStatusStatus.online ? <Wifi /> : <WifiOffline />}
      <Paragraph
        size={ParagraphSize.sm}
        weight={ParagraphWeight.bold}
        color={theme.color.connectionStatus.statusTextColor}
      >
        {status === ConnectionStatusStatus.online ? 'Online' : 'Offline'}
      </Paragraph>
    </ConnectionStatusComponent>
  );
}

export { ConnectionStatus };
