export enum CheckboxSize {
  sm = 'sm',
  md = 'md',
}

export interface CheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: () => void;
  size?: CheckboxSize;
  supportingText?: string;
  testId?: string;
  text?: string;
}
