// Core
import styled from 'styled-components';

// Component
import { LoaderProps, LoaderSize } from './Loader.types';

export const mapLoaderDimensionsBySize: Record<LoaderSize, string> = {
  [LoaderSize.xs]: '15px',
  [LoaderSize.sm]: '25px',
  [LoaderSize.md]: '45px',
  [LoaderSize.lg]: '65px',
  [LoaderSize.xl]: '90px',
};

export const Container = styled.div<Pick<LoaderProps, 'size'>>`
  color: ${({ theme }) => theme.color.loader.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > svg {
    width: ${({ size }) => mapLoaderDimensionsBySize[size as LoaderSize]};
    height: ${({ size }) => mapLoaderDimensionsBySize[size as LoaderSize]};
    animation: rotation 2s infinite linear;
      
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    };
  };
`;

export const Text = styled.div`
  margin-top: 8px;
`;
