// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Component
import { Element } from './Tag.style';
import { TagProps, TagType } from './Tag.types';

// Layout
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../Paragraph';

// Types
import { Theme } from '../../../types/theme';

function Tag({
  block = false,
  className,
  testId,
  text,
  type = TagType.neutral,
}: TagProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  // Defining the color mapping for the tag types
  const colorMap: Record<string, string> = {
    critical: theme.color.tag.criticalTypeTextColor,
    info: theme.color.tag.infoTypeTextColor,
    neutral: theme.color.tag.neutralTypeTextColor,
    success: theme.color.tag.successTypeTextColor,
    warning: theme.color.tag.warningTypeTextColor,
  };

  return (
    <Element
      block={block}
      className={className}
      data-testid={testId}
      type={type}
    >
      <Paragraph
        color={colorMap[type]}
        size={ParagraphSize.xs}
        weight={ParagraphWeight.bold}
      >
        {text}
      </Paragraph>
    </Element>
  );
}

export { Tag };
