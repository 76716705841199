/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/
// Core
import styled from 'styled-components';

// Types
import { StatusIndicatorProps } from '../../../../../layout/elements/StatusIndicator';

const speedometerContainerStyle: string = `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 29px 0 44px;
`;

export const OverallStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
  padding: 24px 19px;
`;

export const OverallContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
  border-radius: 4px;
`;

export const RPMMotorSpeedometer = styled.div`
  ${speedometerContainerStyle};
`;

export const MotorCurrentSpeedometer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
  ${speedometerContainerStyle};
`;

export const SpeedometersContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
`;

export const CounterShaftBushingStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 19px;
`;

export const CounterShaftVibrationTitle = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
`;

export const ThermometersAndSensorsContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
`;

export const ThermometersContainer = styled.div`
  padding: 24px 0 16px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const RingVibrationContainer = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
  padding: 24px 19px;
`;

export const VibrationSensorItem = styled.div`
  padding: 20px 25px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};
  border-left: 1px solid ${({ theme }) => theme.color.crusherDashboard.motorStatus.borderColor};

  &:last-child {
    border-bottom: none;
  }
`;

export const OverallPermissiveContainer = styled.div`
  padding: 22px 20px 10px 20px;
`;

export const CrusherStartListDescription = styled.div<Pick<StatusIndicatorProps, 'status'>>`
  padding: 12px 20px;
  gap: 2px;
  width: 100%;
  background-color: ${({ status, theme }) => (
    status === 'alarm'
      ? theme.color.crusherDashboard.motorStatus.descriptionListErrorBackgroundColor
      : 'transparent'
  )};
  border-radius: ${({ status }) => (
    status === 'alarm'
      ? '4px'
      : 'none'
  )};
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  margin-top: 20px;
  border-radius: 4px;
  background-color: ${({
    theme,
  }) => theme.color.crusherDashboard.motorStatus.footerBackgroundColor};
  width: 100%;
`;
