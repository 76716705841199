// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 14.6666C4.31798 14.6666 1.33331 11.682 1.33331 7.99998C1.33331 4.31798 4.31798 1.33331 7.99998 1.33331C11.682 1.33331 14.6666 4.31798 14.6666 7.99998C14.6666 11.682 11.682 14.6666 7.99998 14.6666ZM7.99998 13.3333C9.41447 13.3333 10.771 12.7714 11.7712 11.7712C12.7714 10.771 13.3333 9.41447 13.3333 7.99998C13.3333 6.58549 12.7714 5.22894 11.7712 4.22874C10.771 3.22855 9.41447 2.66665 7.99998 2.66665C6.58549 2.66665 5.22894 3.22855 4.22874 4.22874C3.22855 5.22894 2.66665 6.58549 2.66665 7.99998C2.66665 9.41447 3.22855 10.771 4.22874 11.7712C5.22894 12.7714 6.58549 13.3333 7.99998 13.3333ZM7.33331 4.66665H8.66665V5.99998H7.33331V4.66665ZM7.33331 7.33331H8.66665V11.3333H7.33331V7.33331Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
