// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Component
import {
  Container,
  Indicator,
  StatusDescription,
} from './StatusIndicator.style';
import {
  StatusIndicatorProps,
  StatusIndicatorSize,
  StatusIndicatorStatus,
} from './StatusIndicator.types';

// Layout
import { Paragraph, ParagraphSize, ParagraphWeight } from '../Paragraph';

// Types
import { Theme } from '../../../types/theme';

function StatusIndicator({
  className,
  size = StatusIndicatorSize.sm,
  status = StatusIndicatorStatus.good,
  testId,
  text,
}: StatusIndicatorProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  // Map object to convert StatusIndicatorSize to ParagraphSize
  const sizeMap: Record<StatusIndicatorSize, ParagraphSize> = {
    [StatusIndicatorSize.sm]: ParagraphSize.sm,
    [StatusIndicatorSize.lg]: ParagraphSize.lg,
  };

  return (
    <Container
      data-testid={testId}
      className={className}
    >
      <Indicator
        data-testid="status-indicator"
        status={status}
      />
      <StatusDescription>
        <Paragraph
          testId="status-description"
          size={sizeMap[size]}
          color={
            status === StatusIndicatorStatus.neutral
              ? theme.color.statusIndicator.neutralTextColor
              : theme.color.statusIndicator.defaultTextColor
          }
          weight={
            size === StatusIndicatorSize.lg
              ? ParagraphWeight.bold
              : ParagraphWeight.regular
          }
        >
          {text}
        </Paragraph>
      </StatusDescription>
    </Container>
  );
}

export { StatusIndicator };
