// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99988 3.8L6.19988 2L9.79988 3.8L13.5817 2.1794C13.6273 2.15983 13.6771 2.1519 13.7266 2.15633C13.7761 2.16075 13.8237 2.17739 13.8651 2.20475C13.9066 2.23211 13.9406 2.26933 13.9641 2.31308C13.9876 2.35683 13.9999 2.40573 13.9999 2.4554V12.2L9.79988 14L6.19988 12.2L2.41808 13.8206C2.37243 13.8402 2.32263 13.8481 2.27316 13.8437C2.22369 13.8393 2.17609 13.8226 2.13463 13.7953C2.09317 13.7679 2.05916 13.7307 2.03564 13.6869C2.01212 13.6432 1.99983 13.5943 1.99988 13.5446V3.8ZM10.3999 12.437L12.7999 11.4086V3.8198L10.3999 4.8482V12.437ZM9.19988 12.3584V4.8416L6.79988 3.6416V11.1584L9.19988 12.3584ZM5.59988 11.1518V3.563L3.19988 4.5914V12.1802L5.59988 11.1518Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
