// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Layout
import { ModuleHeader } from '../../../../layout/elements/ModuleHeader';
import { Col } from '../../../../layout/elements/Col';

// View
import {
  MotorControlContainer,
} from './MotorControlModule.style';
import { CrusherMotorControlModuleProps } from './MotorControlModule.types';

function MotorControlModule({
  className,
  onOpenDrawer,
  style,
  testId,
}: CrusherMotorControlModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  return (
    <MotorControlContainer
      data-testid={testId}
      style={style}
      className={className}
    >
      <Col>
        <ModuleHeader
          onClick={onOpenDrawer}
          title={t('view.crusher.motorControl.title')}
        />
      </Col>
    </MotorControlContainer>
  );
}

export { MotorControlModule };
