// Core
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

// Libraries
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Plugins
import { RequestMethod, requestVantageApi } from '../../plugins/request';
import { timestampToDateStrText } from '../../plugins/general';

// Store
import {
  Customer,
  getCustomer,
  getUser,
  User,
} from '../../store/session.store';

// Types
import { EquipmentUnitCrusher } from '../../types/crusher';
import { EquipmentModel, UserType } from '../../types/global';

// Media
import CrusherImage from '../../media/images/equipment/crusher.png';

// Components
import { CustomerPanel } from '../../layout/CustomerPanel';
import { Loader, LoaderSize } from '../../layout/elements/Loader';
import { Table, TableColumnProps } from '../../layout/elements/Table';

// Component
import {
  Container,
  InstalledAtContainer,
  LoadingContainer,
  SuperiorNumberRowContainer,
} from './PlantNavigation.style';

function PlantNavigation(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  type EquipmentData = EquipmentUnitCrusher & Partial<EquipmentModel>;

  interface PlantNavigationState {
    equipmentUnits: EquipmentUnitCrusher[],
    equipmentModels: EquipmentModel[],
    isLoading: boolean,
  }

  const initialState: PlantNavigationState = {
    equipmentUnits: [],
    equipmentModels: [],
    isLoading: false,
  };

  const [state, setState] = useState(initialState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  // Get customer
  const customer: Customer | null = getCustomer();

  // Get user
  const user: User | null = getUser();

  // Fetch equipment data
  const fetchEquipmentData: () => Promise<void> = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const equipmentUnitsResponse: EquipmentUnitCrusher[] = await requestVantageApi.equipmentUnit({
        method: RequestMethod.GET,
        path: '/crusher',
        params: user?.type === UserType.admin ? { idCustomer: customer?.id } : {},
      });

      const equipmentModelsResponse: EquipmentModel[] = await requestVantageApi.equipmentModel({
        method: RequestMethod.GET,
        path: '/crusher',
        params: user?.type === UserType.admin ? { idCustomer: customer?.id } : {},
      });

      setState((prevState) => ({
        ...prevState,
        equipmentUnits: equipmentUnitsResponse,
        equipmentModels: equipmentModelsResponse,
        isLoading: false,
      }));
    } catch (error: unknown) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }, [customer, user?.type]);

  // Columns
  const columns: TableColumnProps[] = [
    {
      key: 'serialNumber',
      dataIndex: 'serialNumber',
      title: `${t('common.serialNumber')}`,
      sorter: true,
      render: (text) => (
        <SuperiorNumberRowContainer>
          <img src={CrusherImage} alt="Crusher" />
          {text}
        </SuperiorNumberRowContainer>
      ),
    },
    {
      key: 'superiorPartNumber',
      dataIndex: 'superiorPartNumber',
      title: `${t('common.superiorPartNo')}`,
      sorter: true,
    },
    {
      key: 'commercialName',
      dataIndex: 'commercialName',
      title: `${t('common.commercialName')}`,
      sorter: true,
    },
    {
      key: 'customerPartNumber',
      dataIndex: 'customerPartNumber',
      title: `${t('common.customerPartNumber')}`,
      sorter: true,
    },
    {
      key: 'status',
      dataIndex: 'installStatus',
      title: `${t('common.status')}`,
      sorter: true,
      render: (text: string) => {
        switch (text) {
          case 'pre':
            return `${t('equipment.unit.installStatus.preRegistered')}`;
          case 'installed':
            return `${t('equipment.unit.installStatus.installed')}`;
          case 'removed':
            return `${t('equipment.unit.installStatus.removed')}`;
          default:
            return text;
        }
      },
    },
    {
      key: 'installedAt',
      dataIndex: 'installedAt',
      title: `${t('common.installedAt')}`,
      sorter: true,
      render: (_text: string, record): JSX.Element => {
        const { installedAt } = record;
        return (
          <InstalledAtContainer>
            {installedAt ? timestampToDateStrText(installedAt) : ''}
          </InstalledAtContainer>
        );
      },
    },
  ];

  // Generate equipment data rows
  const equipmentDataRows: EquipmentData[] = state.equipmentUnits.map((unit) => {
    const models: EquipmentModel | undefined = state.equipmentModels.find((
      model,
    ) => model.id === unit.idModel);
    return {
      ...unit,
      commercialName: models?.commercialName,
      superiorPartNumber: models?.superiorPartNumber,
      key: unit.id,
    };
  });

  /* ***********************************************************************************************
  ************************************ COMPONENT HANDLING ******************************************
  *********************************************************************************************** */

  useEffect(() => {
    fetchEquipmentData().catch(() => {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    });
  }, [fetchEquipmentData]);

  return (
    <CustomerPanel>
      <Container>
        {state.isLoading ? (
          <LoadingContainer>
            <Loader size={LoaderSize.xl} text={`${t('view.customers.loading')}`} />
          </LoadingContainer>
        ) : (
          <Table
            rows={equipmentDataRows}
            columns={columns}
            fixedHeader
            onClickRow={(record) => ({
              style: { cursor: 'pointer' },
              onClick: () => {
                navigate(`/crusher/${record.id}`);
              },
            })}
          />
        )}
      </Container>
    </CustomerPanel>
  );
}

export { PlantNavigation };
