export enum LogoSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export interface LogoProps {
  className?: string;
  size?: LogoSize;
  testId?: string;
}
