// Core
import styled from 'styled-components';

export const CSSettingsValueContainer = styled.div`
  line-height: 26px;
  margin-right: 4px;
`;

export const CSSettingsUnitContainer = styled.div`
  line-height: normal;
  margin-bottom: 1px;
  width: max-content;
`;

export const CSSettingsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  display: flex;
`;

export const CSSettingsContent = styled.div`
  display: flex;
  align-items: flex-end;
`;
