// Core
import styled from 'styled-components';

// Component
import { LogoProps, LogoSize } from './Logo.types';

export const mapLogoSizeBySize: Record<LogoSize, string> = {
  [LogoSize.xs]: '100px',
  [LogoSize.sm]: '175px',
  [LogoSize.md]: '300px',
  [LogoSize.lg]: '425px',
  [LogoSize.xl]: '600px',
};

export const Image = styled.img<Pick<LogoProps, 'size'>>`
  width: ${({ size }) => mapLogoSizeBySize[size as LogoSize]};
`;
