// Core
import { ReactNode } from 'react';

export enum ParagraphSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum ParagraphWeight {
  regular = 'regular',
  medium = 'medium',
  bold = 'bold',
}

export enum ParagraphAlign {
  left = 'left',
  right = 'right',
  center = 'center',
  justify = 'justify',
}

export enum ParagraphColor {
  default = 'default',
}

export interface ParagraphProps {
  align?: ParagraphAlign;
  children: ReactNode | ReactNode[];
  className?: string;
  color?: ParagraphColor | string
  preventOverflow?: boolean;
  size?: ParagraphSize;
  testId?: string;
  weight?: ParagraphWeight;
}
