// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M4.05327 2.17868C4.49796 2.02001 4.98033 1.99997 5.43665 2.12122C5.89297 2.24247 6.30176 2.4993 6.60904 2.85779C6.91631 3.21627 7.1076 3.65954 7.15763 4.12904C7.20767 4.59853 7.11409 5.07216 6.88927 5.48735L14.0286 12.6274L13.0859 13.57L5.94594 6.43002C5.53065 6.65395 5.05727 6.74681 4.58816 6.69635C4.11905 6.64589 3.67624 6.45449 3.31808 6.14736C2.95992 5.84023 2.70321 5.43179 2.5818 4.97587C2.46039 4.51994 2.47996 4.03793 2.63794 3.59335L4.12927 5.08468C4.22152 5.18019 4.33186 5.25638 4.45387 5.30879C4.57587 5.36119 4.70709 5.38878 4.83987 5.38993C4.97265 5.39109 5.10433 5.36579 5.22722 5.31551C5.35012 5.26523 5.46177 5.19097 5.55567 5.09708C5.64956 5.00319 5.72381 4.89153 5.77409 4.76864C5.82437 4.64574 5.84967 4.51406 5.84852 4.38128C5.84737 4.2485 5.81978 4.11728 5.76737 3.99528C5.71496 3.87328 5.63878 3.76293 5.54327 3.67068L4.0526 2.17802L4.05327 2.17868ZM10.9646 3.43468L13.0859 2.25602L14.0286 3.19868L12.8499 5.32002L11.6713 5.55602L10.2579 6.97002L9.3146 6.02735L10.7286 4.61335L10.9646 3.43468ZM6.48594 8.85602L7.4286 9.79868L3.89327 13.334C3.77307 13.4546 3.61131 13.5246 3.44116 13.5296C3.271 13.5347 3.10535 13.4746 2.97815 13.3614C2.85095 13.2483 2.77185 13.0908 2.75706 12.9212C2.74227 12.7516 2.79291 12.5828 2.8986 12.4494L2.9506 12.3914L6.48594 8.85602Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
