/* eslint-disable @typescript-eslint/indent */

// Core
import styled, { css } from 'styled-components';

// Types
import { StatusIndicatorProps } from '../../../../../layout/elements/StatusIndicator';
import { Theme } from '../../../../../types/theme';

// Reusable styles
const borderColor = (
  { theme }: { theme: Theme },
) => theme.color.crusherDashboard.lubricationUnitDrawer.borderColor;

const flexCenterBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OverallStatusContainer = styled.div`
  ${flexCenterBetween};
  padding: 19px 24px;
`;

export const CoolerFanStatusContainer = styled.div`
  ${flexCenterBetween};
  padding: 19px 24px;
`;

export const RunningStatusItem = styled.li`
  padding: 12px;
`;

export const WinterModeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

export const PermissiveTitleContainer = styled.li`
  padding: 18px 20px 10px 20px;
`;

export const PermissiveItem = styled.li<Pick<StatusIndicatorProps, 'status'>>`
  padding: 12px;
  margin: 0 20px 0 34px;
  background-color: ${({ status, theme }) => (status !== 'alarm'
    ? 'transparent'
    : theme.color.crusherDashboard.lubricationUnitDrawer.descriptionListErrorBackgroundColor
  )};
  border-radius: 4px;
`;

export const LubricationContainer = styled.div`
  border: 1px solid ${borderColor};
  border-radius: 4px;
`;

export const BorderBottomContainer = styled.div`
  border-bottom: 1px solid ${borderColor};
`;

export const RunningStatusContainer = styled.div`
  padding: 18px 20px;
  border-right: 1px solid ${borderColor};
`;

export const RunningStatusList = styled.ul`
  padding-left: 2px;
  list-style: none;
`;

export const LubePressureContainer = styled.div`
  ${flexCenterBetween};
  height: 100%;
  padding: 19px 24px;
  justify-content: center;
`;

export const WinterModeStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ThermometerContainer = styled.div`
  padding: 16px 0;
  border-right: 1px solid ${borderColor};
  
  :last-child {
    border-right: none;
  }
`;

export const PermissiveListContainer = styled.ul`
  border-right: 1px solid ${borderColor};
  list-style: none;
  padding: 0 0 24px 0;
  margin: 0;
  height: 100%;
`;

export const HeaterPermissiveListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 24px 0;
`;
