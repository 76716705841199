// Core
import { ReactNode } from 'react';

export enum TitleSize {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export enum TitleWeight {
  regular = 'regular',
  medium = 'medium',
  bold = 'bold',
}

export enum TitleAlign {
  left = 'left',
  right = 'right',
  center = 'center',
  justify = 'justify',
}

export enum TitleColor {
  default = 'default',
}

export interface TitleProps {
  align?: TitleAlign;
  children: ReactNode | ReactNode[];
  className?: string;
  color?: TitleColor | string;
  size?: TitleSize;
  testId?: string;
  weight?: TitleWeight;
}
