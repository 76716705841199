/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Libraries
import { ToastContainer } from 'react-toastify';
import { Trans } from 'react-i18next';

// Plugins
import { hexToRgba } from '../../../plugins/general';

export const ToastElement = styled(ToastContainer)`
  width: max-content;
  margin: 0;
  padding: 0;
  
  &.Toastify__toast-container {
    gap: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    > .Toastify__toast {
      height: 2.375em;
      min-height: unset;
      width: max-content;
      margin: 0;
      padding: 0;
      font-family: ${({ theme }) => theme.font.regular};
      border-radius: 0.375em;
      display: flex;
      align-items: center;
      box-shadow: 0 0.125em 0.5em -0.125em ${({
        theme,
      }) => hexToRgba(theme.color.toast.shadowColor, 0.5)},
      0 0.5em 1.75em -0.25em ${({ theme }) => hexToRgba(theme.color.toast.shadowColor, 0.1)};
    };
  };

  .Toastify__toast-body {
    padding: 0.5em 0.75em;
    gap: 0.5em;
    line-height: 1.375em;
    font-size: 0.875em;
    letter-spacing: -0.018em;
    color: ${({ theme }) => theme.color.toast.textColor};
  };

  .Toastify__toast-icon {
    margin-inline-end: unset;
  };
  
  .Toastify__toast--loading > .Toastify__toast-body > .Toastify__toast-icon > svg {
    animation: rotation 2s infinite linear;
    color: ${({ theme }) => theme.color.toast.loadingIconColor};

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    };
  };
  
  .Toastify__toast--success > .Toastify__toast-body > .Toastify__toast-icon > svg,
  .Toastify__toast--error > .Toastify__toast-body > .Toastify__toast-icon > svg {
    animation: none;
  };
`;

export const TextMessage = styled(Trans)`
  font-family: ${({ theme }) => theme.font.regular};
  > strong {
    font-family: ${({ theme }) => theme.font.bold};
  };
`;
