// Core
import React from 'react';

// Component
import { Element } from './Title.style';
import {
  TitleAlign,
  TitleColor,
  TitleProps,
  TitleSize,
  TitleWeight,
} from './Title.types';

function Title({
  testId,
  className,
  children,
  size = TitleSize.md,
  weight = TitleWeight.regular,
  color = TitleColor.default,
  align = TitleAlign.left,
}: TitleProps): JSX.Element {
  return (
    <Element
      data-testid={testId}
      className={className}
      color={color}
      size={size}
      weight={weight}
      align={align}
    >
      {children}
    </Element>
  );
}

export { Title };
