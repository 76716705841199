// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';

// Layout
import {
  LineChart,
  LineChartType,
  Series,
  SeriesColorEnum,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsPIDProps } from './TrendingsPID.types';

type FilteredData = {
  currentCavityLevel: number | null;
  currentCrusherAmps: number | null;
  readAt: number | null;
  // currentCrusherSP: number; @TODO: apply after first release
  // cavityLevelSP: number | null; // @TODO: apply after first release
  // feedSpeed: number | null; // @TODO: Need to apply after first release
};

function TrendingsPID({
  testId,
  className,
  operationalStatus,
  unitData,
}: TrendingsPIDProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const series: Series[] = [
    {
      name: t('common.cloudConnection'),
      field: 'edgeStatus',
      type: LineChartType.fill,
      date: 'readAt',
      opposite: true,
    },
    {
      name: t('view.crusher.trendings.pid.legend.currentCrusherAmps'),
      field: 'currentCrusherAmps',
      type: LineChartType.line,
      date: 'readAt',
      unit: 'A',
      decimals: 2,
      color: SeriesColorEnum.green,
      bullet: true,
    },
    {
      name: t('view.crusher.trendings.pid.legend.currenCavityLevel'),
      field: 'currentCavityLevel',
      type: LineChartType.line,
      date: 'readAt',
      unit: '%',
      opposite: true,
      decimals: 2,
      color: SeriesColorEnum.blue,
      bullet: true,
    },
    // { // @TODO: apply the dynamic value after first release
    //   name: t('view.crusher.trendings.pid.legend.currentCrusherSP'),
    //   field: 'currentCrusherSP',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   unit: 'A',
    //   color: 'yellow',
    //   bullet: true,
    // },
    // { // @TODO: apply the dynamic after first release
    //   name: t('view.crusher.trendings.pid.legend.cavityLevelSP'),
    //   field: 'cavityLevelSP',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   unit: '%',
    //   opposite: true,
    //   color: 'orange',
    //   bullet: true,
    // },
    // {
    //   name: t('view.crusher.trendings.pid.legend.feedSpeed'),
    //   field: 'feedSpeed',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   unit: '%',
    //   opposite: true,
    //   color: 'green',
    //   bullet: true,
    // },
  ];

  const filteredData: FilteredData[] = unitData.map((unitDataItem: EquipmentUnitDataCrusher) => {
    const filteredOperationalStatus: EdgeDeviceOperationalStatusStatus[] = operationalStatus.filter(
      (statusItem: { readAt: number; edgeStatus: EdgeDeviceOperationalStatus }) => (
        unitDataItem.readAt !== null
        && Math.abs(statusItem.readAt - unitDataItem.readAt) <= 10000
      ),
    );

    const latestOperationalStatus:
    EdgeDeviceOperationalStatusStatus | undefined = filteredOperationalStatus[
      filteredOperationalStatus.length - 1
    ];

    let edgeStatus: number = 0;
    if (latestOperationalStatus) {
      if (latestOperationalStatus.edgeStatus === EdgeDeviceOperationalStatus.on) {
        edgeStatus = 1;
      } else if (latestOperationalStatus.edgeStatus === EdgeDeviceOperationalStatus.off) {
        edgeStatus = 0;
      }
    }

    return {
      currentCrusherAmps: roundNumber(unitDataItem.crusherMotorAmps, 2),
      currentCavityLevel: roundNumber(unitDataItem.cavityLevel, 2),
      readAt: unitDataItem.readAt,
      // currentCrusherSP: 300, // @TODO: apply the dynamic value after first release
      // cavityLevelSP: roundNumber(data.cavityLevel, 2), // @TODO: apply after first release
      // feedSpeed: roundNumber(data.feedSpeed, 2),
      edgeStatus,
    };
  });

  return (
    <LineChart
      testId={testId}
      className={className}
      id="PID-trending"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsPID };
