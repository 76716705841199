// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      data-testid="checkbox-circle-fill-icon"
    >
      <path
        d="M9.99984 18.3334C5.39734 18.3334 1.6665 14.6026 1.6665 10.0001C1.6665 5.39758 5.39734 1.66675 9.99984 1.66675C14.6023 1.66675 18.3332 5.39758 18.3332 10.0001C18.3332 14.6026 14.6023 18.3334 9.99984 18.3334ZM9.169 13.3334L15.0607 7.44092L13.8823 6.26258L9.169 10.9767L6.8115 8.61925L5.63317 9.79758L9.169 13.3334Z"
        fill="#1CAA56"
      />
    </svg>
  );
}

export default Icon;
