// Core
import styled from 'styled-components';

// Component
import { StatusIndicatorProps, StatusIndicatorStatus } from './StatusIndicator.types';

// Types
import { Theme, ThemedProps } from '../../../types/theme';

/*
  Maps the background color based on the status
  @param theme should receive the theme object
*/
const mapBackgroundByStatus = (theme: Theme) => ({
  [StatusIndicatorStatus.alarm]: theme.color.statusIndicator.alarmBackgroundColor,
  [StatusIndicatorStatus.warning]: theme.color.statusIndicator.warningBackgroundColor,
  [StatusIndicatorStatus.good]: theme.color.statusIndicator.goodBackgroundColor,
  [StatusIndicatorStatus.neutral]: theme.color.statusIndicator.neutralBackgroundColor,
});

/*
  Maps the box shadow color based on the status [alarm | warning | good] neutral has no shadow
  @param theme should receive the theme object
*/
const mapBoxShadowColorByStatus = (theme: Theme) => ({
  [StatusIndicatorStatus.alarm]: theme.color.statusIndicator.alarmShadowColor,
  [StatusIndicatorStatus.warning]: theme.color.statusIndicator.warningShadowColor,
  [StatusIndicatorStatus.good]: theme.color.statusIndicator.goodShadowColor,
  [StatusIndicatorStatus.neutral]: 'none',
});

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Indicator = styled.div<Pick<StatusIndicatorProps, 'status'> & ThemedProps>`
  width: 9px;
  height: 9px;
  display: flex;
  align-items: center;

  /* ******************* BACKGROUND ******************* */
  background: ${({ status, theme }) => (status ? mapBackgroundByStatus(theme)[status] : 'inherit')};

  /* ******************* BOX SHADOW ******************* */
  box-shadow: 0 0 6px 1px ${({
    status,
    theme,
  }) => (status ? mapBoxShadowColorByStatus(theme)[status] : 'inherit')};

  border-radius: 2px;
`;

export const StatusDescription = styled.div<Pick <StatusIndicatorProps, 'status' | 'size'> & ThemedProps>`
  width: max-content;
  display: flex;
  text-align: center;
  justify-content: space-between;

  /* ******************* COLOR ******************* */
  color: ${({
    status,
    theme,
  }) => (
    status === StatusIndicatorStatus.neutral
      ? theme.color.statusIndicator.neutralTextColor
      : theme.color.statusIndicator.defaultTextColor
  )};
`;
