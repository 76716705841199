// Core
import styled from 'styled-components';

export const TrendingsDetailsContainer = styled.div`
  width: 100%;
  align-self: flex-end;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
`;

export const TrendingsLoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MotorCurrentTextContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  width: max-content;
`;

export const ChartTrendingsTabContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TabsTrendingsContainer = styled.div`
  padding-bottom: 20px;
`;

export const LastUpdateTime = styled.span`
  font-family: ${({ theme }) => theme.font.bold};
  color: ${({
    theme,
  }) => theme.color.crusherDashboard.trendings.lastUpdateStrongTextColor};
`;

export const LineChartContainer = styled.div`
  height: 53vh;
  display: flex;
  width: 100%;
`;
