// Core
import React from 'react';

// Libraries
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Types
import { Theme } from '../../types/theme';

// Layout
import { Col } from '../elements/Col';
import {
  LinkTo,
  LinkToColor,
  LinkToSize,
  LinkToType,
  LinkToWeight,
} from '../elements/LinkTo';
import { Logo, LogoSize } from '../elements/Logo';
import {
  Paragraph,
  ParagraphAlign,
  ParagraphSize,
} from '../elements/Paragraph';
import { Row } from '../elements/Row';

// Component
import { LeftPanelImageRightProps } from './LeftPanelImageRight.types';
import {
  ContentContainer,
  Image,
  ImageContainer,
  NeedHelpContainer,
  LeftPanel,
} from './LeftPanelImageRight.style';

function LeftPanelImageRight({ children }: LeftPanelImageRightProps) {
  // Dependencies
  const theme: Theme = useTheme();
  const { t } = useTranslation();

  // Current year
  const currentYear: number = dayjs().year();

  // Help email
  const vantageHelpEmail: string = 'vantagehelp@superior-ind.com';

  return (
    <Row>
      <Col cols={9}>
        <LeftPanel>
          <Row>
            <Col cols={8}>
              <Logo size={LogoSize.xs} />
            </Col>

            <Col cols={16}>
              <NeedHelpContainer>
                <Paragraph align={ParagraphAlign.right}>
                  <LinkTo
                    color={LinkToColor.neutral}
                    type={LinkToType.link}
                    target={`mailto:${vantageHelpEmail}`}
                    size={LinkToSize.xs}
                    weight={LinkToWeight.regular}
                    newTab
                  >
                    {t('layout.leftPanelImageRight.needHelp')}
                  </LinkTo>
                </Paragraph>
              </NeedHelpContainer>
            </Col>
          </Row>

          <ContentContainer>
            {children}
          </ContentContainer>

          <Paragraph
            size={ParagraphSize.xs}
            align={ParagraphAlign.center}
            color={theme.color.leftPanelImageRight.textCopyrightColor}
          >
            {t('common.copyright', { year: currentYear })}
          </Paragraph>
        </LeftPanel>
      </Col>

      <Col cols={15}>
        <ImageContainer>
          <Image />
        </ImageContainer>
      </Col>

    </Row>
  );
}

export { LeftPanelImageRight };
