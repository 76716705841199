// Core
import { HTMLAttributes } from 'react';

export enum TableSortOrder {
  ascend = 'ascend',
  descend = 'descend',
}

export interface TableColumnProps {
  dataIndex: string;
  defaultSortOrder?: TableSortOrder;
  key: string;
  render?: (text: string, record: any, index: number) => string | JSX.Element;
  sorter?: true;
  title?: string | JSX.Element;
}

export interface TablePaginationProps {
  current: number;
  onPageChange: (page: number, newPageSize: number) => void;
  pageSize: number;
  totalItems: number;
}

export interface TableProps {
  className?: string;
  columns: TableColumnProps[];
  fixedHeader?: boolean;
  onClickRow?: (record: any) => HTMLAttributes<any>;
  pagination?: TablePaginationProps | false;
  rows: Record<string, any>[];
  testId?: string;
}
