// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="expand-icon"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.97485 3.80156V2.60156H2.59985V6.20156H3.79985V3.80156H5.97485ZM3.79985 9.80156H2.59985V13.4016H5.97485V12.2016H3.79985V9.80156ZM12.1999 9.80156H13.3999V13.4016H10.0249V12.2016H12.1999V9.80156ZM13.3999 6.20156V2.60156H10.0249V3.80156H12.1999V6.20156H13.3999Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
