// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  overallStatusToTagType,
  overallStatusToText,
} from '../../../../plugins/crusher';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../layout/elements/Paragraph';

// Types
import { EdgeDeviceOperationalStatus } from '../../../../types/global';
import { Theme } from '../../../../types/theme';

// Components
import { Col } from '../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../layout/elements/ModuleHeader';
import { Row } from '../../../../layout/elements/Row';
import { Speedometer } from '../../../../layout/elements/Speedometer';
import { Tag } from '../../../../layout/elements/Tag';

// View
import {
  MotorAmpsContainer,
  MotorRpmContainer,
  MotorStatusContainer,
  OverallStatusContainer,
  SpeedometersContainer,
} from './MotorStatusModule.style';
import { CrusherMotorStatusModuleProps } from './MotorStatusModule.types';

function MotorStatusModule({
  className,
  onOpenDrawer,
  operationalStatus,
  style,
  testId,
  unitData,
}: CrusherMotorStatusModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  return (
    <MotorStatusContainer
      data-testid={testId}
      className={className}
      style={style}
    >
      <Row>
        <Col>
          <ModuleHeader
            onClick={onOpenDrawer}
            title={t('view.crusher.crusherMotor.title')}
          />
        </Col>
      </Row>
      <Row>
        <Col cols={24}>
          <OverallStatusContainer>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              color={theme.color.crusherDashboard.statusTitleColor}
            >
              {t('view.crusher.overallStatus')}
            </Paragraph>
            <Tag
              text={overallStatusToText(unitData?.crusherMotorOverallStatus)}
              type={overallStatusToTagType(
                unitData?.crusherMotorOverallStatus,
                operationalStatus,
              )}
            />
          </OverallStatusContainer>
        </Col>
      </Row>
      <SpeedometersContainer>
        <MotorRpmContainer>
          <Speedometer
            className="speedometer"
            minValue={0}
            maxValue={3000}
            value={unitData?.crusherMotorRpm}
            unit="rpm"
            thresholds={{}}
            title={`${t('view.crusher.crusherMotor.motorRmp')}`}
            running={operationalStatus === EdgeDeviceOperationalStatus.on}
          />
        </MotorRpmContainer>

        <MotorAmpsContainer>
          <Speedometer
            className="speedometer"
            minValue={0}
            maxValue={750}
            value={unitData?.crusherMotorAmps}
            unit="A"
            thresholds={{ highWarning: 510 }}
            title={`${t('view.crusher.crusherMotor.motorAmps')}`}
            running={operationalStatus === EdgeDeviceOperationalStatus.on}
          />
        </MotorAmpsContainer>
      </SpeedometersContainer>
    </MotorStatusContainer>
  );
}

export { MotorStatusModule };
