// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// Types
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsHydraulicUnitProps } from './TrendingsHydraulicUnit.types';

type FilteredData = {
  trampPressure: number | null;
  clampPressure: number | null;
  readAt: number | null;
};

function TrendingsHydraulicUnit({
  className,
  testId,
  operationalStatus,
  unitData,
}: TrendingsHydraulicUnitProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const series: Series[] = [
    {
      name: t('common.cloudConnection'),
      field: 'edgeStatus',
      type: LineChartType.fill,
      date: 'readAt',
      opposite: true,
    },
    {
      name: t('view.crusher.hydraulic.trampPressure'),
      field: 'trampPressure',
      type: LineChartType.line,
      date: 'readAt',
      color: 'green',
      unit: 'psi',
      bullet: true,
    },
    {
      name: t('view.crusher.hydraulic.clampPressure'),
      field: 'clampPressure',
      type: LineChartType.line,
      date: 'readAt',
      color: 'blue',
      unit: 'psi',
      bullet: true,
    },
  ];

  const filteredData: FilteredData[] = unitData.map((unitDataItem: EquipmentUnitDataCrusher) => {
    const filteredOperationalStatus: EdgeDeviceOperationalStatusStatus[] = operationalStatus.filter(
      (statusItem: { readAt: number; edgeStatus: EdgeDeviceOperationalStatus }) => (
        unitDataItem.readAt !== null
        && Math.abs(statusItem.readAt - unitDataItem.readAt) <= 10000
      ),
    );

    const latestOperationalStatus:
    EdgeDeviceOperationalStatusStatus | undefined = filteredOperationalStatus[
      filteredOperationalStatus.length - 1
    ];

    let edgeStatus: number = 0;
    if (latestOperationalStatus) {
      if (latestOperationalStatus.edgeStatus === EdgeDeviceOperationalStatus.on) {
        edgeStatus = 1;
      } else if (latestOperationalStatus.edgeStatus === EdgeDeviceOperationalStatus.off) {
        edgeStatus = 0;
      }
    }

    return {
      trampPressure: roundNumber(unitDataItem.hydraulicTrampPressure, 2),
      clampPressure: roundNumber(unitDataItem.hydraulicClampPressure, 2),
      readAt: unitDataItem.readAt,
      edgeStatus,
    };
  });

  return (
    <LineChart
      testId={testId}
      className={className}
      id="hydraulic-unit"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsHydraulicUnit };
