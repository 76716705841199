// Core
import React from 'react';

// Component
import { Element } from './Row.style';
import { RowProps } from './Row.types';

function Row({
  testId,
  className,
  style,
  children,
  gutter = 0,
}: RowProps) {
  return (
    <Element
      data-testid={testId}
      className={className}
      style={style}
      gutter={gutter}
    >
      {children}
    </Element>
  );
}

export { Row };
