// Core
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

// Libraries
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Bullet } from '@amcharts/amcharts5';
import {
  AxisRenderer,
  ILineSeriesSettings,
  LineSeries,
} from '@amcharts/amcharts5/xy';

// Types
import { Theme } from '../../../types/theme';

// Plugins
import { formatNumber } from '../../../plugins/general';

// Component
import { Element } from './LineChart.style';
import {
  LineChartProps,
  Series,
  SeriesColor,
} from './LineChart.types';

function LineChart({
  activeBullet,
  className,
  data,
  id,
  grid = false,
  removeNullDates = true,
  series,
  testId,
  timeUnit,
}: LineChartProps): JSX.Element {
  // Dependencies
  const { i18n } = useTranslation();
  const theme: Theme = useTheme();

  /* ************************** THEME ************************** */
  const chartConfig = useMemo((): {
    fonts: { regular: string };
    colors: {
      fillColor: string;
      statusColor: string;
      yAxisColor: string;
      lineSeriesColor: Record<string | SeriesColor, string>
    }
  } => ({
    colors: {
      fillColor: theme.color.lineChart.colorFill,
      statusColor: theme.color.lineChart.colorStatus,
      yAxisColor: theme.color.lineChart.yAxisColor,
      lineSeriesColor: {
        purple: theme.color.lineChart.seriesColorPurple,
        yellow: theme.color.lineChart.seriesColorYellow,
        lightOrange: theme.color.lineChart.seriesColorLightOrange,
        green: theme.color.lineChart.seriesColorGreen,
        orange: theme.color.lineChart.seriesColorOrange,
        blue: theme.color.lineChart.seriesColorBlue,
        pink: theme.color.lineChart.seriesColorPink,
        lightGreen: theme.color.lineChart.seriesColorLightGreen,
        lightPink: theme.color.lineChart.seriesColorLightPink,
        lightBlue: theme.color.lineChart.seriesColorLightBlue,
      },
    },
    fonts: {
      regular: theme.font.regular,
    },
  }), [theme]);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  // Maximum number of data points to display in the chart
  const MAX_DATA_POINTS: number = 300;

  // Reference chart
  const chartRef: React.MutableRefObject<am5xy.XYChart | null> = useRef<am5xy.XYChart | null>(null);

  // Reference root
  const rootRef: React.MutableRefObject<am5.Root | undefined> = useRef(undefined);

  // Default series colors
  const defaultSeriesColor: string[] = Object.values(chartConfig.colors.lineSeriesColor).map(
    (color: string) => color,
  );

  // Creates a mapping of status values to their corresponding indices
  const mapStatusArrayToIndex = (statusArray: string[]): { [key: string]: number } => {
    const mapping: { [key: string]: number } = {};
    statusArray.forEach((status, index) => {
      mapping[status] = index;
    });
    return mapping;
  };

  const renderChart = useCallback((): void => {
    // Function to set the colors of a series in a chart
    const setSeriesColors = (
      seriesChart: LineSeries,
      color: string | undefined,
      defaultColor: string,
    ): void => {
      if (color) {
        // If specific color is provided for the series, set both stroke and fill with that color
        seriesChart.set('stroke', am5.color(chartConfig.colors.lineSeriesColor[color] || color));
        seriesChart.set('fill', am5.color(chartConfig.colors.lineSeriesColor[color] || color));
      } else {
        // If no specific color is provided, use the default color
        seriesChart.set('stroke', am5.color(defaultColor));
        seriesChart.set('fill', am5.color(defaultColor));
      }
    };

    if (rootRef.current) {
      // Configure the root to use the Animated theme
      const myTheme: am5.Theme = am5.Theme.new(rootRef.current);
      rootRef.current.setThemes([
        myTheme,
        am5themes_Animated.new(rootRef.current),
      ]);

      // Apply a theme rule to set the stroke dash array for the x-axis grid
      myTheme.rule('Grid', ['x']).setAll({
        strokeDasharray: 8,
        strokeOpacity: 0.2,
        visible: grid,
      });

      // ************************** CHART ************************

      // Create chart
      if (!chartRef.current) {
        const chart: am5xy.XYChart = rootRef.current.container.children.push(
          am5xy.XYChart.new(rootRef.current, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            pinchZoomX: false,
            layout: rootRef.current.verticalLayout,
            paddingTop: 44,
          }),
        );

        // Remove zoom button
        chart.zoomOutButton.set('forceHidden', true);

        // ************************** X AXIS ************************

        // Create x axis
        const xAxis: am5xy.DateAxis<am5xy.AxisRendererX> = chart.xAxes.push(
          am5xy.DateAxis.new(rootRef.current, {
            extraMax: 0.01,
            extraMin: 0,
            baseInterval: { timeUnit, count: 1 },
            y: 8,
            renderer: am5xy.AxisRendererX.new(rootRef.current, {
              minGridDistance: 120,
            }),
          }),
        );

        // Set the fill color of x-axis labels
        xAxis.get('renderer').labels.template.set('fill', am5.color(chartConfig.colors.statusColor));

        // Set the location of the x-axis
        xAxis.get('renderer').labels.template.setAll({
          location: 1,
        });

        // Map the time unit to the format string
        const formatMap: Record<string, string> = {
          millisecond: 'mm:ss:SSS',
          second: 'LTS',
          minute: 'LT',
          hour: 'LT',
          day: 'L',
          week: 'L',
          month: 'MMMM/YYYY',
          year: 'YYYY',
          default: 'll',
        };

        // Set the format of the x-axis labels
        xAxis.get('renderer').labels.template.adapters.add('text', (text, target) => {
          let formattedText: string | undefined = text;
          const { dataItem } = target;

          if (dataItem) {
            const timestamp: number = dataItem.get('value' as any);

            if (timestamp) {
              const dayObj: dayjs.Dayjs = dayjs(timestamp);
              const formatString: string = formatMap[timeUnit] || formatMap.default;
              formattedText = dayObj.format(formatString);
            }
          }

          return formattedText;
        });

        // ************************** Y AXIS ************************

        // Create y axis
        const yAxis: am5xy.ValueAxis<AxisRenderer>[] = [];

        // Filter the series by unit
        const filterSeriesByUnit: Series[] = series.filter((
          currentSeries: Series,
          index: number,
          self: Series[],
        ): boolean => (
          index === self.findIndex((seriesUnit: Series): boolean => (
            seriesUnit.unit === currentSeries.unit
          ))
        ));

        // Create a Y-axis for each unit in the series
        filterSeriesByUnit.forEach((seriesChart: Series): void => {
          if (rootRef.current) {
            // Create a new Y-axis for the current series
            const yAxisInstance: am5xy.ValueAxis<AxisRenderer> = chart.yAxes.push(
              am5xy.ValueAxis.new(rootRef.current, {
                extraMax: 0.1,
                extraMin: 0.1,
                paddingRight: 18,
                paddingLeft: 8,
                syncWithAxis: seriesChart.type === 'fill' || seriesChart.type === 'status'
                  ? undefined
                  : yAxis[0],
                renderer: am5xy.AxisRendererY.new(rootRef.current, {
                  opposite: seriesChart.opposite,
                  minGridDistance: 50,
                }),
              }),
            );

            // Configuration for Y axes specific to 'fill' and 'status' series types
            if (seriesChart.type === 'fill' || seriesChart.type === 'status') {
              yAxisInstance.set('strictMinMax', true);
              yAxisInstance.set('min', 0);
              yAxisInstance.set('max', 1);

              // Hides the labels and grid of the Y axis for 'fill' series types
              if (seriesChart.type === 'fill') {
                yAxisInstance.get('renderer').labels.template.set('visible', false);
                yAxisInstance.get('renderer').grid.template.set('visible', false);
              }
            }

            const decimals: number = seriesChart.decimals || 0;

            // Configure the text label for the Y-axis
            yAxisInstance.get('renderer').labels.template.set('text', '{value}');
            yAxisInstance.get('renderer').labels.template.adapters.add('text', (text, target) => {
              const value: number = target.dataItem?.get('value' as any);
              return value !== undefined ? formatNumber(value, decimals) : text;
            });

            yAxisInstance.get('renderer').labels.template.setAll({
              width: 10,
            });

            yAxis.push(yAxisInstance);

            // Create a unit label for the series
            const unitLabel: am5.Label = am5.Label.new(rootRef.current, {
              rotation: 0,
              text: seriesChart.unit,
              x: 50,
              centerX: 15,
              centerY: 44,
              fill: am5.color(theme.color.lineChart.yAxisColor),
              fontFamily: theme.font.medium,
              fontSize: 12,
            });

            // Add the unit label to the beginning of the Y-axis children
            yAxisInstance.children.unshift(
              unitLabel,
            );
          }
        });

        // ************************** SERIES ************************
        let seriesChart: am5xy.LineSeries;

        // Inside your series.forEach loop
        series.forEach((currentSeries: Series, index: number): void => {
          if (rootRef.current) {
            // Get the y-axis with the same unit as the current series
            const yAxisWithSameUnit: am5xy.ValueAxis<am5xy.AxisRenderer> = yAxis[
              filterSeriesByUnit.findIndex((
                seriesUnit: Series,
              ): boolean => seriesUnit.unit === currentSeries.unit)
            ];

            // Common configuration for all series types
            const commonConfig: ILineSeriesSettings = {
              name: `${currentSeries.name}${currentSeries.unit ? ` (${currentSeries.unit})` : ''}`,
              xAxis,
              yAxis: yAxisWithSameUnit,
              valueYField: currentSeries.field,
              valueXField: currentSeries.date,
              id: currentSeries.field,
              tooltip: currentSeries.type === 'fill'
                ? undefined
                : am5.Tooltip.new(rootRef.current, {
                  pointerOrientation: 'horizontal',
                  labelText: `[bold]{name} (${currentSeries.unit})[/]: {valueY}`,
                }),
            };

            // Check if the series type is a line and create line series
            if (currentSeries.type === 'line') {
              seriesChart = am5xy.LineSeries.new(rootRef.current, {
                ...commonConfig,
                connect: false,
                autoGapCount: MAX_DATA_POINTS * 0.07,
              });
              setSeriesColors(
                seriesChart,
                currentSeries.color,
                defaultSeriesColor[index],
              );
            } else if (currentSeries.type === 'status' || currentSeries.type === 'fill') {
              seriesChart = am5xy.StepLineSeries.new(rootRef.current, {
                ...commonConfig,
                connect: true,
              });

              // Configure the fills template for the fill chart (applies to 'status' and 'fill')
              if (currentSeries.type === 'fill') {
                seriesChart.fills.template.setAll({
                  fillOpacity: 0.1,
                  visible: true,
                  strokeOpacity: 0,
                });
                // Disabled the stroke width for 'fill'
                seriesChart.strokes.template.setAll({
                  strokeOpacity: 0,
                });

                setSeriesColors(seriesChart, currentSeries.color, chartConfig.colors.fillColor);
              }

              if (currentSeries.type === 'status') {
                setSeriesColors(seriesChart, currentSeries.color, chartConfig.colors.statusColor);

                // Set the minimum grid distance for the y-axis status renderer
                yAxisWithSameUnit.get('renderer').set('minGridDistance', 1000);

                const statusMapping: { [key: string]: number } = mapStatusArrayToIndex(
                  currentSeries.status as Array<string>,
                );

                // Update the data with the status value
                const updatedData: { statusValue: number }[] = data.map((item) => ({
                  ...item,
                  statusValue: statusMapping[item[currentSeries.field]],
                }));

                seriesChart.data.setAll(updatedData);

                // Get the renderer for the y-axis status labels
                yAxisWithSameUnit.get('renderer').labels.template.adapters.add(
                  'text',
                  (text, target) => {
                    const value = target.dataItem?.get('value' as any);
                    let result: string | undefined = text;

                    if (currentSeries.status && value !== undefined) {
                      result = currentSeries.status[value];
                    }

                    return result;
                  },
                );
              }
            }

            if (seriesChart) {
              // Sets the stroke width for both line and step line series
              seriesChart.strokes.template.setAll({
                strokeWidth: 2,
              });

              // Set color and font properties for y-axis labels
              yAxisWithSameUnit.get('renderer').labels.template.setAll({
                fill: am5.color(theme.color.lineChart.yAxisColor),
                fontFamily: theme.font.regular,
                fontSize: 12,
              });

              // Add the series chart to the chart's series array
              chart.series.push(seriesChart);

              // ************************** TOOLTIP ************************

              // Create tooltip
              const tooltip: am5.Tooltip | undefined = seriesChart.get('tooltip');

              // Checks if series status and tooltip exist
              if (currentSeries.status && tooltip) {
                /* Adjust tooltip to show status text instead of value */
                tooltip.label.adapters.add('text', (text, target) => {
                  const value = target.dataItem?.get('valueY' as any);
                  let statusText = '';
                  if (value !== undefined && currentSeries.status) {
                    statusText = value === 0 ? currentSeries.status[0] : currentSeries.status[1];
                  }
                  return `[bold fontSize:14px]{name}[/][fontSize:14px]: ${statusText}[/]`;
                });
              } else if (tooltip) {
                // If the series status does not exist, it will render the default tooltip
                tooltip.set('labelText', '[bold fontSize:14px]{name}[/][fontSize:14px]: {valueY}[/]');
                tooltip.label.adapters.add('text', (text, target) => {
                  const value = target.dataItem?.get('valueY' as any);
                  return value !== undefined
                    ? text?.replace('{valueY}', formatNumber(value, currentSeries.decimals || 0))
                    : text;
                });
              }
            }
          }
        });

        // ************************** LEGEND ************************

        // Add legend
        const legend: am5.Legend = chart.children.push(
          am5.Legend.new(rootRef.current, {
            useDefaultMarker: true,
            x: am5.percent(50),
            y: am5.percent(98),
            centerX: am5.percent(50),
          }),
        );

        // Customize the markers template for the legend with specific dimensions
        legend.markers.template.setAll({
          width: 10,
          height: 2,
        });

        // Set the legend data to include all series in the chart
        legend.data.setAll([...chart.series.values]);

        // Sets the template for all legend labels in a chart
        legend.labels.template.setAll({
          fontFamily: theme.font.regular,
          fontSize: 14,
          fontWeight: 'bold',
        });

        // Create an XY cursor for the chart
        const cursor: am5xy.XYCursor = chart.set('cursor', am5xy.XYCursor.new(rootRef.current, {}));

        // Hide the Y-axis cursor line
        cursor.lineY.set('visible', false);

        // Assign the current chart instance to the chartRef variable
        chartRef.current = chart;
      }
    }

    // ************************** BULLET ************************

    if (chartRef.current) {
      // Iterate through each series in the chart
      chartRef.current.series.each((seriesChart: am5xy.XYSeries, index: number): void => {
        // Get the current series configuration
        const currentSeries: Series = series[index];

        // Check if the current series is of type 'line'
        if (currentSeries.type === 'line') {
          // Add a custom bullet to the series
          seriesChart.bullets.push((
            customRoot: am5.Root,
            customSeries: am5.Series,
            dataItem: Record<string, any>,
          ): Bullet | undefined => {
            // Variable to store the bullet element
            let bulletElement: Bullet | undefined;

            // Check if the bullet property is true
            if (currentSeries.bullet) {
              // Extract the 'bullet' property from the dataItem's dataContext
              const { bullet } = dataItem.dataContext;

              // Get the color for the current series
              const seriesColor: am5.Color = !isNil(currentSeries.color)
                ? am5.color(
                  chartConfig.colors.lineSeriesColor[currentSeries.color] || currentSeries.color,
                ) : am5.color(defaultSeriesColor[index]);

              if (bullet) {
                // Create a container for the bullet with two circles
                const container: am5.Container = am5.Container.new(customRoot, {});

                // Create bullet circle
                const bulletCircle: am5.Circle = container.children.push(am5.Circle.new(
                  customRoot,
                  { radius: 5, fill: seriesColor },
                ));

                // Animate the first circle's radius continuously
                bulletCircle.animate({
                  key: 'radius',
                  to: 5,
                  duration: 1000,
                  easing: am5.ease.out(am5.ease.cubic),
                  loops: Infinity,
                });

                // Create effect of bullet circle
                const circleEffect: am5.Circle = container.children.push(am5.Circle.new(
                  customRoot,
                  { radius: 5, fill: seriesColor },
                ));

                // Animate the second circle's radius
                circleEffect.animate({
                  key: 'radius',
                  to: activeBullet ? 15 : 0,
                  duration: 1000,
                  easing: am5.ease.out(am5.ease.cubic),
                  loops: Infinity,
                });

                // Animate the second circle's opacity
                circleEffect.animate({
                  key: 'opacity',
                  to: 0,
                  from: 1,
                  duration: 1000,
                  easing: am5.ease.out(am5.ease.cubic),
                  loops: Infinity,
                });

                // Set up event handling to position the container at the last data point
                customRoot.events.on('framestarted', (): void => {
                  const lastDataItem: am5.DataItem<am5xy.IXYSeriesDataItem> = seriesChart.dataItems[
                    seriesChart.dataItems.length - 1
                  ];
                  lastDataItem.bullets = [];
                  const point: am5.IPoint | undefined = lastDataItem.get('point');

                  if (point) {
                    container.setAll({
                      x: point.x,
                      y: point.y,
                    });
                  }
                });

                // Set the result to the new Bullet with the container as a sprite
                bulletElement = am5.Bullet.new(customRoot, {
                  sprite: container,
                });
              }
            }

            // Return the result
            return bulletElement;
          });
        }
      });
    }
  }, [
    chartConfig,
    defaultSeriesColor,
    grid,
    series,
    activeBullet,
    theme,
    timeUnit,
    data,
  ]);

  /* ***********************************************************************************************
  ***************************************** COMPONENT HANDLING *************************************
  *********************************************************************************************** */
  // useEffect hook to create the root when the component mounts
  useEffect(() => {
    if (!rootRef.current) {
      rootRef.current = am5.Root.new(id);
    }

    return (): void => {
      if (rootRef.current) {
        rootRef.current.dispose();
        rootRef.current = undefined;
        chartRef.current = null;
      }
    };
  }, [id, i18n.language]);

  // useEffect hook to render the chart when the root is created
  useEffect((): void => {
    if (rootRef.current) {
      renderChart();
    }
  }, [renderChart]);

  // useEffect hook to update the data for the chart series when the data change
  useEffect((): void => {
    if (chartRef.current) {
      let filteredData: Record<string, any> = data;

      // Remove null dates from the data
      if (removeNullDates) {
        series.forEach((currentSeries: Series): void => {
          const { field } = currentSeries;
          filteredData = filteredData.filter((
            item: { [x: string]: null },
          ) => item[field] !== null && item[currentSeries.date] !== null);
        });
      }

      // Reduce the data to the 300 points
      const reducedData: LineChartProps['data'] = filteredData.reduce((
        acc: { bullet: boolean }[],
        item: { bullet: boolean },
        index: number,
        array: [],
      ) => {
        if (array.length - index <= MAX_DATA_POINTS) {
          acc.push({
            ...item,
            bullet: array.length - index === 1,
          });
        }
        return acc;
      }, []);

      if (chartRef.current) {
        chartRef.current.series.each((seriesChart: am5xy.XYSeries): void => {
          seriesChart.data.setAll(reducedData);
        });
      }
    }
  }, [
    data,
    removeNullDates,
    series,
  ]);

  return (
    <Element
      id={id}
      data-testid={testId}
      className={className}
    />
  );
}

export { LineChart };
