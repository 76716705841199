// Libraries
import {
  io,
  ManagerOptions,
  Socket,
  SocketOptions,
} from 'socket.io-client';

export interface SocketHookPayload {
  url: string;
  token?: string;
}

export enum SocketDefaultListenEvents {
  connect = 'connect',
  disconnect = 'disconnect',
}

export const getSocket = (payload: SocketHookPayload): Socket => {
  const connectionOptions: Partial<ManagerOptions & SocketOptions> = !payload.token ? {} : {
    auth: {
      authToken: payload.token,
    },
  };
  return io(payload.url, {
    ...connectionOptions,
    autoConnect: false,
  });
};

export { Socket };
