/* eslint-disable @typescript-eslint/indent */
// Core
import styled from 'styled-components';

// Libraries
import { Breadcrumb as BreadcrumbAntD } from 'antd';

// Component
import { BreadcrumbType } from './Breadcrumb.types';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
  align-items: center;
  
  .ant-breadcrumb > ol {
    align-items: center;
  };
`;

export const Element = styled(BreadcrumbAntD)<{ type: BreadcrumbType }>`
  align-items: center;
  
  li:last-child {
    a {
      color: ${({ theme }) => theme.color.breadcrumb.lastTargetPageTextColor};
      background-color: inherit;
      
      :hover {
        cursor: default;
      };
    };
  };
  
  /* ****************** SEPARATOR ****************** */
  .ant-breadcrumb-separator {
    font-size: ${({ type }) => (type === BreadcrumbType.plant ? '18px' : '20px')};
    margin: ${({ type }) => (type === BreadcrumbType.plant ? '0 8px' : '0 16px')};
  };
  
  li:nth-child(2) {
    display: ${({ type }) => (type === BreadcrumbType.page ? 'none' : 'block')};
  };
  
  .ant-breadcrumb-link {
    color: ${({ theme }) => theme.color.breadcrumb.targetTextColor};
    height: 100%;
    font-family: ${({ theme, type }) => (
      type === BreadcrumbType.plant
        ? theme.font.medium
        : theme.font.bold
    )};
    display: flex;
    align-items: center;
    font-size: ${({ type }) => (type === BreadcrumbType.plant ? '12px' : '14px')};
    
    /* ****************** HOVER ****************** */
    :hover {
      background-color: inherit;
    };
    
    /* ****************** ICON ****************** */
    > svg {
      height: 100%;
      width: ${({ type }) => (type === BreadcrumbType.plant ? '20px' : '24px')};
      color: ${({ theme }) => theme.color.breadcrumb.targetTextHoverColor};
    };
  };

  .ant-breadcrumb-link:has(svg) {
    padding: 0;
    margin-right: 16px;
  };
`;
