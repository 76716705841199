// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Application Modules
import { AppErrorProps } from './AppError.types';
import {
  Container,
  ContentContainer,
  Image,
  ImageContainer,
  TitleContainer,
} from './AppError.style';

// Layout
import { Alert, AlertType } from '../../layout/elements/Alert';
import { Title, TitleSize, TitleWeight } from '../../layout/elements/Title';
import { AlertLine } from '../../layout/icons';

// Media
import Conveyor from '../../media/images/equipment/conveyor2.png';

function AppError({
  testId,
  className,
  errorMessage,
}: AppErrorProps) {
  // Dependencies
  const { t } = useTranslation();

  return (
    <Container
      data-testid={testId}
      className={className}
    >
      <ContentContainer>
        <TitleContainer>
          <Title
            weight={TitleWeight.bold}
            size={TitleSize.xs}
          >
            {t('view.appError.title')}
          </Title>
        </TitleContainer>
        <Alert
          icon={<AlertLine />}
          type={AlertType.error}
          description={errorMessage}
          closable={false}
        />
        <ImageContainer>
          <Image
            alt="Conveyor Superior"
            src={Conveyor}
          />
        </ImageContainer>
      </ContentContainer>
    </Container>
  );
}

export { AppError };
