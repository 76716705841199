// Core
import React, { FC } from 'react';

// Libraries
import {
  Location,
  Navigate,
  useLocation,
} from 'react-router-dom';

// Store
import { getAuthToken } from '../store/session.store';

export interface AuthProps {
  Component: FC;
  Fallback?: FC;
}

function Auth({ Component, Fallback }: AuthProps): JSX.Element {
  const authToken: string | null = getAuthToken();
  const location: Location = useLocation();

  let toRender: JSX.Element = <Component />;
  if (!authToken) {
    if (Fallback) {
      toRender = <Fallback />;
    } else {
      toRender = <Navigate to="/" state={{ from: location }} replace />;
    }
  }

  return toRender;
}

export default Auth;
