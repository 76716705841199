// Core
import React from 'react';

// Component
import { Container, Text } from './Loader.style';
import { LoaderProps, LoaderSize } from './Loader.types';

// Layout
import { Spin } from '../../icons';
import { Paragraph, ParagraphSize } from '../Paragraph';

function Loader({
  className,
  size = LoaderSize.md,
  testId,
  text,
}: LoaderProps): JSX.Element {
  const mapParagraphSizeByLoaderSize: Record<LoaderSize, ParagraphSize> = {
    [LoaderSize.xs]: ParagraphSize.xs,
    [LoaderSize.sm]: ParagraphSize.sm,
    [LoaderSize.md]: ParagraphSize.md,
    [LoaderSize.lg]: ParagraphSize.lg,
    [LoaderSize.xl]: ParagraphSize.xl,
  };

  const textSize: ParagraphSize = mapParagraphSizeByLoaderSize[size];

  return (
    <Container
      className={className}
      data-testid={testId}
      size={size}
    >
      <Spin />
      {text && (
        <Text>
          <Paragraph size={textSize} testId="loader-text-id">
            {text}
          </Paragraph>
        </Text>
      )}
    </Container>
  );
}

export { Loader };
