export enum NavigationContextType {
  admin = 'admin',
  customer = 'customer',
}

export interface MainNavigationProps {
  className?: string;
  context?: NavigationContextType;
  searchComponentEnabled?: boolean;
  testId?: string;
}
