export enum TagStatus {
  running = 'running',
  stopped = 'stopped',
}

export interface EquipmentStatusTagProps {
  testId?: string;
  className?: string;
  status: TagStatus;
}
