// Core
import { useEffect, useState } from 'react';

// Plugins
import { getWindowDimensions } from '../plugins/general';

export const useWindowDimensions = (): { width: number, height: number } => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
