// Core
import styled from 'styled-components';

export const Element = styled.div`
  /* ******************* BACKGROUND COLOR ******************* */
  background-color: ${({ theme }) => theme.color.emptyBox.backgroundColor};
  
  /* ******************* BORDER ******************* */
  border: 2px solid ${({ theme }) => theme.color.emptyBox.borderColor};
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
  gap: 32px;
  display: flex;
`;

export const Image = styled.img`
  width: 284px;
  height: 111px;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  flex-direction: column;
  gap: 8px;
  align-items: center;
  display: flex; 
`;
