// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="icon-caret-left"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.828 12L15.778 16.95L14.364 18.364L8 12L14.364 5.63599L15.778 7.04999L10.828 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
