// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="caret-right-icon"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1727 12.0002L8.22266 7.05023L9.63666 5.63623L16.0007 12.0002L9.63666 18.3642L8.22266 16.9502L13.1727 12.0002Z"
        stroke="currentColor"
        strokeWidth={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
