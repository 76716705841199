// Libraries
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
import enUS from './en_US.json';
import ptBR from './pt_BR.json';

export enum Language { // @ TODO: Adjust and remove this lint disable
  // eslint-disable-next-line @typescript-eslint/no-shadow
  en_US = 'en_US',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  pt_BR = 'pt_BR',
}

export interface LangMap {
  en_us: 'en_US';
  en: 'en_US';
  pt_br: 'pt_BR';
  pt: 'pt_BR';
}

export const langMap: LangMap = {
  en_us: 'en_US',
  en: 'en_US',
  pt_br: 'pt_BR',
  pt: 'pt_BR',
};

export const fallbackLanguage = 'en_US';

export function initI18n() {
  // Language resources
  const resources = {
    en_US: { translation: enUS },
    pt_BR: { translation: ptBR },
  };

  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: fallbackLanguage,
      fallbackLng: fallbackLanguage,
      interpolation: {
        escapeValue: false,
      },
    });
}

export default i18n;
