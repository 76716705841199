// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// Types
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import {
  LineChart,
  LineChartType,
  Series,
  SeriesColorEnum,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsRingBounceProps } from './TrendingsRingBounce.types';

type FilteredData = {
  vibrationSensor1: number | null;
  vibrationSensor2: number | null;
  vibrationSensor3: number | null;
  readAt: number | null;
};

function TrendingsRingBounce({
  className,
  operationalStatus,
  testId,
  unitData,
}: TrendingsRingBounceProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const DEFAULT_TRANSLATE_LEGEND: string = 'view.crusher.trendings.ringBounceSensor';

  const series: Series[] = [
    {
      name: t('common.cloudConnection'),
      field: 'edgeStatus',
      type: LineChartType.fill,
      date: 'readAt',
      opposite: true,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor1`),
      field: 'vibrationSensor1',
      type: LineChartType.line,
      date: 'readAt',
      color: SeriesColorEnum.lightPink,
      unit: '%',
      bullet: true,
      decimals: 2,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor2`),
      field: 'vibrationSensor2',
      type: LineChartType.line,
      date: 'readAt',
      color: SeriesColorEnum.lightGreen,
      unit: '%',
      bullet: true,
      decimals: 2,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor3`),
      field: 'vibrationSensor3',
      type: LineChartType.line,
      date: 'readAt',
      color: SeriesColorEnum.blue,
      unit: '%',
      bullet: true,
      decimals: 2,
    },
    // {
    //   name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.maxVibration`),
    //   field: 'maxVibration',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   color: 'orange',
    //   unit: '%',
    // },
  ];

  const filteredData: FilteredData[] = unitData.map((unitDataItem: EquipmentUnitDataCrusher) => {
    const filteredOperationalStatus: EdgeDeviceOperationalStatusStatus[] = operationalStatus.filter(
      (statusItem: { readAt: number; edgeStatus: EdgeDeviceOperationalStatus }) => (
        unitDataItem.readAt !== null
        && Math.abs(statusItem.readAt - unitDataItem.readAt) <= 10000
      ),
    );

    const latestOperationalStatus:
    EdgeDeviceOperationalStatusStatus | undefined = filteredOperationalStatus[
      filteredOperationalStatus.length - 1
    ];

    let edgeStatus: number = 0;
    if (latestOperationalStatus) {
      if (latestOperationalStatus.edgeStatus === EdgeDeviceOperationalStatus.on) {
        edgeStatus = 1;
      } else if (latestOperationalStatus.edgeStatus === EdgeDeviceOperationalStatus.off) {
        edgeStatus = 0;
      }
    }

    return {
      vibrationSensor1: roundNumber(unitDataItem.vibrationSensor1, 2),
      vibrationSensor2: roundNumber(unitDataItem.vibrationSensor2, 2),
      vibrationSensor3: roundNumber(unitDataItem.vibrationSensor3, 2),
      // maxVibration: roundNumber(data.maxVibration, 2),
      edgeStatus,
      readAt: unitDataItem.readAt,
    };
  });

  return (
    <LineChart
      testId={testId}
      className={className}
      id="ring-bounce"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsRingBounce };
