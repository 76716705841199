// Core
import React, { useState } from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { Trans } from 'react-i18next';

// Layout
import {
  Paragraph,
  ParagraphAlign,
  ParagraphSize,
} from '../Paragraph';

// Component
import {
  TooltipProps,
  TooltipPlacement,
  TooltipMode,
} from './Tooltip.types';
import {
  Bold,
  Container,
  Element,
} from './Tooltip.style';

// Types
import { Theme } from '../../../types/theme';

function Tooltip({
  children,
  className,
  mode = TooltipMode.default,
  placement = TooltipPlacement.top,
  testId,
  text,
}: TooltipProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  /* ***********************************************************************************************
  ************************************ INITIAL STATES **********************************************
  *********************************************************************************************** */

  const [visible, setVisible] = useState<boolean>(false);

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  return (
    <Container
      className={className}
      data-testid={testId}
      onMouseEnter={(): void => setVisible(true)}
      onMouseLeave={(): void => setVisible(false)}
    >
      <Element
        placement={placement}
        mode={mode}
        visible={visible}
      >
        <Paragraph
          align={ParagraphAlign.center}
          color={
            mode === TooltipMode.default
              ? theme.color.tooltip.textColor
              : theme.color.tooltip.lightTextColor
          }
          size={ParagraphSize.sm}
        >
          <Trans i18nKey={text} components={{ underlined: <ins />, bold: <Bold /> }} />
        </Paragraph>
      </Element>
      <div>
        {children}
      </div>
    </Container>
  );
}

export { Tooltip };
