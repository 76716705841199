// Core
import React from 'react';

// Libraries
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Component
import { TextMessage, ToastElement } from './Toast.style';
import { ShowToastProps } from './Toast.types';

// Layout
import {
  AlertWarningFill,
  CheckboxCircleFill,
  ErrorWarningFill,
} from '../../icons';

export const showToast = ({
  className,
  text,
  type,
  autoClose = 4,
}: ShowToastProps) => {
  // Map icons for each type
  const mapIcons = {
    success: <CheckboxCircleFill />,
    error: <ErrorWarningFill />,
    warning: <AlertWarningFill />,
  };

  // Map options for each type
  const mapToastOptions = () => ({
    className,
    icon: mapIcons[type],
    autoClose: autoClose === false ? autoClose : autoClose * 1000,
  });

  const mapToasts = {
    success: () => toast.success(<TextMessage>{text}</TextMessage>, mapToastOptions()),
    warning: () => toast.warning(<TextMessage>{text}</TextMessage>, mapToastOptions()),
    error: () => toast.error(<TextMessage>{text}</TextMessage>, mapToastOptions()),
  };

  const toastFunction = mapToasts[type];
  if (toastFunction) toastFunction();
};

function Toast() {
  return (
    <ToastElement
      position="top-center"
      hideProgressBar
      closeButton={false}
    />
  );
}

export { Toast };
