// Core
import React, {
  ReactNode,
  useEffect,
} from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Libraries
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

// Application Modules
import i18n, {
  fallbackLanguage,
  initI18n,
  LangMap,
  langMap,
} from './locale/i18n';
import { getLanguage, setLanguage } from './store/app.store';
import routes from './Routes';
import { WindowDimensionsViewer } from './style/App.style';
import { useWindowDimensions } from './hooks/useWindowDimensions';

// Plugins
import { appLocaleToDayjs, validateEnvironmentVariables } from './plugins/general';

// Theme
import theme, { antDTheme } from './config/theme';

// View
import { AppError } from './views/AppError';

// Layout
import { Toast } from './layout/elements/Toast';

initI18n();

function App() {
  // Dependencies
  const router = createBrowserRouter(routes);

  const [envVarsErrors, setEnvVarsErrors] = React.useState<string>();

  const { height, width } = useWindowDimensions();

  /* **********************************************************************************************
  ************************************* COMPONENT HANDLING ****************************************
  ********************************************************************************************** */

  useEffect((): void => { // Set the language
    const language: string | null = getLanguage();

    if (language) {
      i18n.changeLanguage(language);
      dayjs.locale(appLocaleToDayjs(language));
    } else { // If no language is set, we will try to get the browser language
      const browserLanguage: string = navigator.language.toLowerCase().replace('-', '_');

      const isValidLanguage = (key: string, obj: LangMap): key is keyof typeof obj => key in obj;

      const initialLanguage: string = isValidLanguage(browserLanguage, langMap)
        ? langMap[browserLanguage]
        : fallbackLanguage;

      i18n.changeLanguage(initialLanguage);
      dayjs.locale(appLocaleToDayjs(initialLanguage));
      setLanguage(initialLanguage);
    }
  }, []);

  useEffect((): void => {
    try {
      validateEnvironmentVariables();
    } catch (error: unknown) {
      if (error instanceof Error) {
        setEnvVarsErrors(error.message);
      }
    }
  }, []);

  let appContent: ReactNode;

  if (envVarsErrors) {
    appContent = <AppError errorMessage={envVarsErrors} />;
  } else {
    appContent = (
      <>
        <RouterProvider router={router} />
        <Toast />
        {process.env.NODE_ENV === 'development' && (
          <WindowDimensionsViewer>
            { `${height} x ${width}` }
          </WindowDimensionsViewer>
        )}
      </>
    );
  }

  return (
    <ConfigProvider theme={antDTheme.default}>
      <ThemeProvider theme={theme.default}>
        <div className="App">
          {appContent}
        </div>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default App;
