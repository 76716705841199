// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M3.16674 13.332V9.33203C3.16674 7.91754 3.72864 6.56099 4.72883 5.56079C5.72903 4.5606 7.08558 3.9987 8.50007 3.9987C9.91456 3.9987 11.2711 4.5606 12.2713 5.56079C13.2715 6.56099 13.8334 7.91754 13.8334 9.33203V13.332H14.5001V14.6654H2.50007V13.332H3.16674ZM4.50007 13.332H12.5001V9.33203C12.5001 8.27117 12.0786 7.25375 11.3285 6.5036C10.5784 5.75346 9.56094 5.33203 8.50007 5.33203C7.4392 5.33203 6.42179 5.75346 5.67164 6.5036C4.9215 7.25375 4.50007 8.27117 4.50007 9.33203V13.332ZM7.8334 1.33203H9.16674V3.33203H7.8334V1.33203ZM13.6854 3.20403L14.6281 4.1467L13.2147 5.5607L12.2714 4.61803L13.6854 3.20403ZM2.37207 4.1467L3.31474 3.20403L4.72874 4.61736L3.78674 5.56136L2.37207 4.1467ZM5.16674 9.33203C5.16674 8.44798 5.51793 7.60013 6.14305 6.97501C6.76817 6.34989 7.61601 5.9987 8.50007 5.9987V7.33203C7.96964 7.33203 7.46093 7.54274 7.08586 7.91782C6.71078 8.29289 6.50007 8.8016 6.50007 9.33203H5.16674Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
