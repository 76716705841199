// Core
import React from 'react';

export enum OptionsDropdownColor {
  normal = 'normal',
  danger = 'danger',
}

export enum OptionsDropdownPlacement {
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  bottom = 'bottom',
  topLeft = 'topLeft',
  topRight = 'topRight',
  top = 'top',

}

export interface Options {
  color: OptionsDropdownColor;
  disabled: boolean;
  icon?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLElement>;
  text: string;
}

export interface OptionsDropdownProps {
  children?: React.ReactNode;
  className?: string;
  options: Options[];
  placement?: OptionsDropdownPlacement;
  secondaryOptions?: Options[];
  testId?: string;
}
