// Core
import styled from 'styled-components';

export const VibrationItemsContainer = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const VibrationItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
  width: 100%;
  border-top: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.vibrationModule.itemsBorderColor};
  list-style: none;
  flex: 1;
`;

export const VibrationContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  height: 25%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;
