// Core
import styled from 'styled-components';

// Component
import { TagProps } from './Tag.types';

// Types
import { Theme, ThemedProps } from '../../../types/theme';

const mapBackgroundColorByType = (theme: Theme) => ({
  critical: theme.color.tag.criticalTypeBackgroundColor,
  success: theme.color.tag.successTypeBackgroundColor,
  neutral: theme.color.tag.neutralTypeBackgroundColor,
  info: theme.color.tag.infoTypeBackgroundColor,
  warning: theme.color.tag.warningTypeBackgroundColor,
});

export const Element = styled.div<Pick<TagProps, 'type' | 'block'> & ThemedProps>`
  background: ${({ type, theme }) => type && mapBackgroundColorByType(theme)[type]};
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 2px;
  height: 21px;
  ${({ block }) => block && `
    display: block;
    width: 100%;
  `}
`;
