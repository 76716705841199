// Core
import React from 'react';

// Component
import { Image } from './Logo.style';
import { LogoProps, LogoSize } from './Logo.types';

// Media
import logoSuperior from '../../../media/images/logoSuperior.png';

function Logo({
  className,
  size = LogoSize.sm,
  testId,
}: LogoProps): JSX.Element {
  return (
    <Image
      alt="Superior Industries Logo"
      className={className}
      data-testid={testId}
      size={size}
      src={logoSuperior}
    />
  );
}

export { Logo };
