/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Theme
import { Theme } from '../../../types/theme';

// Component
import {
  Options,
  OptionsDropdownColor,
  OptionsDropdownPlacement,
  OptionsDropdownProps,
} from './OptionsDropdown.types';

export const mapPlacement: Record<OptionsDropdownPlacement, string> = {
  [OptionsDropdownPlacement.bottom]: `
    top: 100%;
    right: 50%;
    transform: translateX(50%);
  `,
  [OptionsDropdownPlacement.bottomRight]: `
    top: 100%;
    left: 0;
    transform: translateX(0);
  `,
  [OptionsDropdownPlacement.bottomLeft]: `
    top: 100%;
    right: 0;
    transform: translateX(0);
  `,
  [OptionsDropdownPlacement.top]: `
    margin: 5px;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
  `,
  [OptionsDropdownPlacement.topRight]: `
    margin: 5px;
    bottom: 100%;
    left: 0;
    transform: translateX(0);
  `,
  [OptionsDropdownPlacement.topLeft]: `
    margin: 5px;
    bottom: 100%;
    right: 0;
    transform: translateX(0);
  `,
};

export const mapBackgroundByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.dangerBackgroundHoverColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.optionItemBgHoverColor,
});

export const mapBackgroundDisabledByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.disabledBackgroundColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.disabledBackgroundColor,
});

export const mapTextByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.dangerTextColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.optionItemTextColor,
});

export const mapTextDisabledByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.disabledDangerTextColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.disabledOptionTextColor,
});

export const mapTextDisabledHoverByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.disabledDangerTextHoverColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.disabledOptionTextHoverColor,
});

export const mapTextHoverByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.dangerTextHoverColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.optionItemTextHoverColor,
});

export const Container = styled.div`
  display: inline;
  cursor: pointer;
  color: ${({ theme }) => theme.color.optionsDropdown.iconColor};
  position: relative;
    
  > svg {
    width: 20px;
    height: 20px;
  };
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 16px;
    height: 16px;
  };
`;

export const Content = styled.ul<Pick<OptionsDropdownProps, 'placement'>>`
  padding: 0;
  border-radius: 10px;
  height: auto;
  margin: auto;
  display: inline-block;
  z-index: 999;
  font-size: 14px;
  background-color: ${({ theme }) => theme.color.optionsDropdown.optionsContainerBgColor};
  border: 1px solid ${({ theme }) => theme.color.optionsDropdown.optionsContainerBorder};
  box-shadow: 0 7px 14px 0 ${({ theme }) => theme.color.optionsDropdown.optionsContainerBoxShadow};
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  max-height: 0;
  ${({ placement }) => mapPlacement[placement as OptionsDropdownPlacement]};
  ${Container}:hover & {
    max-height: 400px;
    visibility: visible;
  };
`;

export const Item = styled.li<Pick<Options, 'color' | 'disabled'>>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  display: flex;
  padding: 10px 16px;
  margin: 4px 4px;
  border-radius: 8px;
  width: 210px;
  gap: 16px;
  transition: all 0.3s;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({
    theme,
    color,
    disabled,
  }) => (disabled ? mapTextDisabledByColor(theme)[color] : mapTextByColor(theme)[color])};

  :hover {
    background-color: ${({
      theme,
      color,
      disabled,
    }) => (disabled ? mapBackgroundDisabledByColor(theme) : mapBackgroundByColor(theme)[color])};
    
    color: ${({
      theme,
      color,
      disabled,
    }) => (
      disabled
        ? mapTextDisabledHoverByColor(theme)[color]
        : mapTextHoverByColor(theme)[color]
    )};
  };
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.optionsDropdown.dividerColor};
`;
