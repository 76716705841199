// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 12.1917L10.6375 9.3042C11.2085 8.73312 11.5974 8.00554 11.7549 7.21346C11.9124 6.42138 11.8315 5.60038 11.5225 4.85428C11.2134 4.10817 10.69 3.47046 10.0185 3.0218C9.34704 2.57314 8.55759 2.33366 7.75 2.33366C6.94241 2.33366 6.15296 2.57314 5.48147 3.0218C4.80998 3.47046 4.2866 4.10817 3.97753 4.85428C3.66846 5.60038 3.58758 6.42138 3.7451 7.21346C3.90262 8.00554 4.29148 8.73312 4.8625 9.3042L7.75 12.1917ZM7.75 13.8414L4.03767 10.129C3.30345 9.39481 2.80344 8.45935 2.60087 7.44095C2.39831 6.42255 2.50228 5.36695 2.89964 4.40764C3.297 3.44834 3.96991 2.6284 4.83327 2.05153C5.69662 1.47465 6.71165 1.16675 7.75 1.16675C8.78835 1.16675 9.80338 1.47465 10.6667 2.05153C11.5301 2.6284 12.203 3.44834 12.6004 4.40764C12.9977 5.36695 13.1017 6.42255 12.8991 7.44095C12.6966 8.45935 12.1966 9.39481 11.4623 10.129L7.75 13.8414ZM7.75 7.58337C8.05942 7.58337 8.35617 7.46045 8.57496 7.24166C8.79375 7.02287 8.91667 6.72612 8.91667 6.4167C8.91667 6.10728 8.79375 5.81054 8.57496 5.59174C8.35617 5.37295 8.05942 5.25004 7.75 5.25004C7.44058 5.25004 7.14384 5.37295 6.92504 5.59174C6.70625 5.81054 6.58334 6.10728 6.58334 6.4167C6.58334 6.72612 6.70625 7.02287 6.92504 7.24166C7.14384 7.46045 7.44058 7.58337 7.75 7.58337ZM7.75 8.75004C7.13116 8.75004 6.53767 8.5042 6.10009 8.06662C5.6625 7.62903 5.41667 7.03554 5.41667 6.4167C5.41667 5.79786 5.6625 5.20437 6.10009 4.76679C6.53767 4.3292 7.13116 4.08337 7.75 4.08337C8.36884 4.08337 8.96233 4.3292 9.39992 4.76679C9.8375 5.20437 10.0833 5.79786 10.0833 6.4167C10.0833 7.03554 9.8375 7.62903 9.39992 8.06662C8.96233 8.5042 8.36884 8.75004 7.75 8.75004Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
