export enum CoordinatesEnum {
  Lat = 'lat',
  Lng = 'lng',
}

export type CoordinatesType = {
  [CoordinatesEnum.Lat]: number,
  [CoordinatesEnum.Lng]: number,
};

export interface MapProps {
  centerCoordinates?: CoordinatesType;
  className?: string;
  pins?: CoordinatesType[];
  polygons?: CoordinatesType[] | CoordinatesType[][];
  testId?: string;
}
