/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Component
import {
  TooltipProps,
  TooltipPlacement,
  TooltipMode,
} from './Tooltip.types';

// Types
import { Theme } from '../../../types/theme';

// Maps tooltip placements and apply mode styles
const getPlacementStyles = (
  theme: Theme,
  mode: TooltipMode,
  placement: TooltipPlacement | undefined,
) => {
  const placements = {
    top: `
      bottom: 110%;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 8px;
      &:after {
        border-color: ${
          mode === TooltipMode.default
            ? `${theme.color.tooltip.arrowBackgroundColor} transparent transparent transparent`
            : `${theme.color.tooltip.lightArrowBackgroundColor} transparent transparent transparent`
        };
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
      };
    `,
    bottom: `
      top: 110%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 8px;
      &:after {
        border-color: ${
          mode === TooltipMode.default
            ? `transparent transparent ${theme.color.tooltip.arrowBackgroundColor} transparent`
            : `transparent transparent ${theme.color.tooltip.lightArrowBackgroundColor} transparent`
        };
        left: 50%;
        transform: translateX(-50%);
        bottom: 100%;
      };
    `,
    right: `
      top: 50%;
      left: 100%;
      margin-left: 10px;
      transform: translateY(-50%);
      &:after {
        border-color: ${
          mode === TooltipMode.default
            ? `transparent ${theme.color.tooltip.arrowBackgroundColor} transparent transparent`
            : `transparent ${theme.color.tooltip.lightBackgroundColor} transparent transparent`
        };
        right: 100%;
        top: 50%;
        margin-top: -8px;
      };
    `,
    left: `
      top: 50%;
      right: 100%;
      margin-right: 10px;
      transform: translateY(-50%);
      &:after {
        border-color: ${
          mode === TooltipMode.default
            ? `transparent transparent transparent ${theme.color.tooltip.arrowBackgroundColor}`
            : `transparent transparent transparent ${theme.color.tooltip.lightBackgroundColor}`
        };
        left: 100%;
        top: 50%;
        margin-top: -8px;
      };
    `,
  };

  return placements[placement || TooltipPlacement.top];
};

export const Bold = styled.strong`
  font-family: ${({ theme }) => theme.font.bold};
`;

export const Container = styled.div`
  z-index: 1;
  cursor: pointer;
  position: relative;
  width: max-content;
  max-width: 100%;
  display: inline-block;
`;

export const Element = styled.div<{ visible: boolean } & Pick<TooltipProps, 'placement' | 'mode'>>`
  max-width: 300px;
  word-wrap: break-word;
  background-color: ${({ theme, mode }) => (
    mode === TooltipMode.default
      ? theme.color.tooltip.backgroundColor
      : theme.color.tooltip.lightBackgroundColor)
  };
  border-radius: 8px;
  box-shadow: 0 12px 16px -4px ${({ theme }) => theme.color.tooltip.shadowColor},
              0px 4px 6px -2px ${({ theme }) => theme.color.tooltip.shadowColor},
              12px 0 16px -4px ${({ theme }) => theme.color.tooltip.shadowColor},
              -12px 0 16px -4px ${({ theme }) => theme.color.tooltip.shadowColor};
  box-sizing: border-box;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  transition: all 250ms;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: max-content;
  min-height: max-content;
  ${({
    theme,
    mode = TooltipMode.default,
    placement,
  }) => getPlacementStyles(
    theme,
    mode,
    placement,
  )};

  /* ******************* ARROW ******************* */
  &:after {
    border-style: solid;
    border-width: 8px;
    content: '';
    position: absolute;
  };
`;
