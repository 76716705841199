export enum LoaderSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export interface LoaderProps {
  className?: string;
  size?: LoaderSize;
  testId?: string;
  text?: string;
}
