// Core
import styled from 'styled-components';

export const CameraContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;
