// Core
import React from 'react';

export enum InputType {
  text = 'text',
  password = 'password',
  number = 'number',
}

export interface InputProps {
  className?: string;
  decimalPlaces?: number;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  onChange: (value: string | number | null | undefined) => void;
  onPressEnter?: () => void;
  placeholder?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  supportingText?: string;
  testId?: string;
  type?: InputType;
  value?: string | number | null;
}
