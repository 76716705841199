/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Component
import {
  ParagraphAlign,
  ParagraphProps,
  ParagraphSize,
  ParagraphWeight,
} from './Paragraph.types';

// Theme
import { Theme } from '../../../types/theme';

const mapFontSizeBySize: Record<ParagraphSize, string> = {
  [ParagraphSize.xs]: '12px',
  [ParagraphSize.sm]: '14px',
  [ParagraphSize.md]: '16px',
  [ParagraphSize.lg]: '18px',
  [ParagraphSize.xl]: '20px',
};

export const mapFontWeightByWeight = (theme: Theme): Record<ParagraphWeight, string> => ({
  [ParagraphWeight.regular]: theme.font.regular,
  [ParagraphWeight.medium]: theme.font.medium,
  [ParagraphWeight.bold]: theme.font.bold,
});

export const mapLineHeightBySize: Record<ParagraphSize, string> = {
  [ParagraphSize.xs]: '20px',
  [ParagraphSize.sm]: '22px',
  [ParagraphSize.md]: '26px',
  [ParagraphSize.lg]: '28px',
  [ParagraphSize.xl]: '30px',
};

export const Element = styled.p<Pick<ParagraphProps, 'align' | 'size' | 'weight' | 'preventOverflow'>>`
  color: ${({ color }) => color};
  transition: color 0.3s;
  font-family: ${({ weight, theme }) => mapFontWeightByWeight(theme)[weight as ParagraphWeight]};
  font-size: ${({ size }) => mapFontSizeBySize[size as ParagraphSize]};
  letter-spacing: -0.02em;
  line-height: ${({ size }) => mapLineHeightBySize[size as ParagraphSize]};
  text-align: ${({ align }) => ParagraphAlign[align as ParagraphAlign]};
    ${({ preventOverflow }) => (
      preventOverflow
        ? `
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;`
        : 'visibility: inherit;'
    )};
`;
