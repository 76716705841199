// Core
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const ContentContainer = styled.div`
  padding: 40px;
  gap: 20px;
`;

export const TitleContainer = styled.div`
  padding: 20px 0 20px 0;
  display: flex;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 150px;
`;

export const Image = styled.img`
  height: 100%;
  width: max-content;
`;
