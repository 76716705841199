// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="wifi-offline-icon"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14.5344C10.5395 14.5344 11.0351 14.7233 11.425 15.0376L10 16.801L8.57505 15.0376C8.9781 14.7111 9.48131 14.5334 10 14.5344ZM3.05499 1.98694L16.4109 15.3436L15.3425 16.4119L11.4288 12.4982C10.9679 12.3453 10.4855 12.2675 10 12.2677C8.92109 12.2677 7.92981 12.6447 7.15159 13.2741L6.20262 12.0985C7.19545 11.2942 8.41857 10.8268 9.69477 10.7642L7.73336 8.80276C6.65456 9.10855 5.64903 9.63008 4.77765 10.3358L3.82716 9.16014C4.6415 8.50071 5.55623 7.976 6.53657 7.60597L4.81089 5.87954C3.9519 6.2886 3.14418 6.79758 2.40446 7.39593L1.45398 6.22105C2.14531 5.66194 2.89179 5.17007 3.68437 4.75377L1.98589 3.05604L3.05499 1.98694ZM11.8889 8.7053L10.1632 6.98037L10 6.97886C12.3384 6.97886 14.4857 7.79561 16.1729 9.16014L15.2224 10.3358C14.2489 9.5471 13.1091 8.98961 11.8889 8.7053ZM10 3.20111C13.2376 3.20111 16.2106 4.33217 18.5453 6.22105L17.5956 7.39593C15.447 5.65563 12.7649 4.70797 10 4.71221C9.33664 4.71221 8.68536 4.7651 8.05069 4.86861L6.77004 3.58644C7.80438 3.33484 8.88709 3.20111 10 3.20111Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
