export enum ConnectionStatusStatus {
  online = 'online',
  offline = 'offline',
}

export interface ConnectionStatusProps {
  className?: string;
  status: ConnectionStatusStatus;
  testId?: string;
}
