// Core
import React from 'react';

// Components
import { MainNavigation, NavigationContextType } from '../MainNavigation';

// Component
import { AdminPanelProps } from './AdminPanel.types';
import { Container, MainNavigationContainer } from './AdminPanel.style';

function AdminPanel({
  children,
  className,
  testId,
}: AdminPanelProps): JSX.Element {
  return (
    <Container
      data-testid={testId}
      className={className}
    >
      <MainNavigationContainer>
        <MainNavigation testId="main-navigation" context={NavigationContextType.admin} />
      </MainNavigationContainer>
      {children}
    </Container>
  );
}

export { AdminPanel };
