// Core
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  > div {
    border-radius: 4px;
  };

  .gm-bundled-control-on-bottom {
    display: none !important;
  };
`;
