// Core
import styled from 'styled-components';

// Types
import { Theme } from '../../types/theme';

// Components
import { ConnectionStatusProps } from './ConnectionStatus.types';

const mapSvgColorByStatus = (theme: Theme) => ({
  online: theme.color.connectionStatus.onlineIconColor,
  offline: theme.color.connectionStatus.offlineIconColor,
});

export const ConnectionStatusComponent = styled.div<Pick<ConnectionStatusProps, 'status'>>`
  display: flex;
  height: 14px;
  gap: 6px;
  align-items: center;
  
  > svg {
    color: ${({ status, theme }) => mapSvgColorByStatus(theme)[status]};
    width: 14px;
    height: 14px;
  };
`;
