export enum ToastType {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export interface ShowToastProps {
  autoClose?: number | false;
  className?: string;
  testId?: string;
  text: string;
  type: ToastType;
}
