export enum TagType {
  critical = 'critical',
  info = 'info',
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
}

export interface TagProps {
  block?: boolean;
  className?: string;
  testId?: string;
  text: string;
  type?: TagType;
}
