// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Media
import conveyorImage from '../../media/images/equipment/conveyor.png';

// Components
import { Paragraph, ParagraphSize } from '../../layout/elements/Paragraph';
import { Title, TitleSize, TitleWeight } from '../../layout/elements/Title';

// Component
import { EmptyBoxProps } from './EmptyBox.types';
import {
  Element,
  Image,
  TextContainer,
} from './EmptyBox.style';

// Types
import { Theme } from '../../types/theme';

function EmptyBox({
  className,
  description,
  imgSrc = conveyorImage,
  testId,
  title,
}: EmptyBoxProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  return (
    <Element
      data-testid={testId}
      className={className}
    >
      <Image src={imgSrc} alt={title} />
      <TextContainer>
        <Title
          size={TitleSize.xxs}
          weight={TitleWeight.bold}
          color={theme.color.emptyBox.titleColor}
        >
          {title}
        </Title>
        <Paragraph
          size={ParagraphSize.lg}
          color={theme.color.emptyBox.descriptionColor}
        >
          {description}
        </Paragraph>
      </TextContainer>
    </Element>
  );
}

export { EmptyBox };
