// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  vibrationSensorToTagType,
  vibrationSensorToText,
} from '../../../../plugins/crusher';
import { formatNumber, roundNumber } from '../../../../plugins/general';

// Components
import { Col } from '../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../layout/elements/ModuleHeader';
import { Tag } from '../../../../layout/elements/Tag';
import { Row } from '../../../../layout/elements/Row';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../layout/elements/Paragraph';

// Types
import { Theme } from '../../../../types/theme';

// View
import {
  VibrationContainer,
  VibrationItem,
  VibrationItemsContainer,
} from './VibrationModule.style';
import { CrusherVibrationModuleProps } from './VibrationModule.types';

function VibrationModule({
  className,
  onOpenDrawer,
  operationalStatus,
  style,
  testId,
  unitData,
}: CrusherVibrationModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const renderSensorItem = (
    sensorName: string,
    sensorValue: number | null,
    index: number,
  ): JSX.Element => (
    <VibrationItem key={sensorName}>
      <Row>
        <Col cols={11}>
          <Paragraph
            size={ParagraphSize.sm}
            weight={ParagraphWeight.medium}
            color={theme.color.crusherDashboard.vibrationModule.nameTextColor}
          >
            {`${t('view.crusher.vibration.sensor')} ${index + 1}`}
          </Paragraph>
        </Col>
        <Col cols={9}>
          <Paragraph
            size={ParagraphSize.sm}
            weight={ParagraphWeight.medium}
            color={theme.color.crusherDashboard.vibrationModule.valueTextColor}
          >
            {`${formatNumber(sensorValue)}%`}
          </Paragraph>
        </Col>
        <Col cols={4}>
          <Tag
            text={vibrationSensorToText(sensorValue, { highWarning: 55 })}
            type={vibrationSensorToTagType(sensorValue, { highWarning: 55 }, operationalStatus)}
          />
        </Col>
      </Row>
    </VibrationItem>
  );

  return (
    <VibrationContainer
      data-testid={testId}
      className={className}
      style={style}
    >
      <Row>
        <Col>
          <ModuleHeader
            onClick={onOpenDrawer}
            title={t('view.crusher.vibration.title')}
          />
        </Col>
      </Row>
      <VibrationItemsContainer>
        {renderSensorItem(
          'vibrationSensor1',
          roundNumber(unitData?.vibrationSensor1, 2),
          0,
        )}
        {renderSensorItem(
          'vibrationSensor2',
          roundNumber(unitData?.vibrationSensor2, 2),
          1,
        )}
        {renderSensorItem(
          'vibrationSensor3',
          roundNumber(unitData?.vibrationSensor3, 2),
          2,
        )}
      </VibrationItemsContainer>
    </VibrationContainer>
  );
}

export { VibrationModule };
