// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5834 12.8334H11.4167V11.6667C11.4167 11.2026 11.2323 10.7575 10.9041 10.4293C10.5759 10.1011 10.1308 9.91671 9.6667 9.91671H6.1667C5.70257 9.91671 5.25745 10.1011 4.92926 10.4293C4.60107 10.7575 4.4167 11.2026 4.4167 11.6667V12.8334H3.25003V11.6667C3.25003 10.8932 3.55732 10.1513 4.1043 9.60431C4.65128 9.05733 5.39315 8.75004 6.1667 8.75004H9.6667C10.4402 8.75004 11.1821 9.05733 11.7291 9.60431C12.2761 10.1513 12.5834 10.8932 12.5834 11.6667V12.8334ZM7.9167 7.58337C7.45707 7.58337 7.00195 7.49284 6.57731 7.31695C6.15267 7.14106 5.76683 6.88325 5.44182 6.55825C5.11682 6.23324 4.85901 5.84741 4.68312 5.42277C4.50723 4.99813 4.4167 4.543 4.4167 4.08337C4.4167 3.62375 4.50723 3.16862 4.68312 2.74398C4.85901 2.31934 5.11682 1.93351 5.44182 1.6085C5.76683 1.2835 6.15267 1.02569 6.57731 0.849796C7.00195 0.673904 7.45707 0.583374 7.9167 0.583374C8.84496 0.583374 9.73519 0.952123 10.3916 1.6085C11.0479 2.26488 11.4167 3.15512 11.4167 4.08337C11.4167 5.01163 11.0479 5.90187 10.3916 6.55825C9.73519 7.21462 8.84496 7.58337 7.9167 7.58337ZM7.9167 6.41671C8.53554 6.41671 9.12903 6.17087 9.56661 5.73329C10.0042 5.2957 10.25 4.70221 10.25 4.08337C10.25 3.46454 10.0042 2.87104 9.56661 2.43346C9.12903 1.99587 8.53554 1.75004 7.9167 1.75004C7.29786 1.75004 6.70437 1.99587 6.26678 2.43346C5.8292 2.87104 5.58336 3.46454 5.58336 4.08337C5.58336 4.70221 5.8292 5.2957 6.26678 5.73329C6.70437 6.17087 7.29786 6.41671 7.9167 6.41671Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
