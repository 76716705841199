// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58926 2.5199L18.0142 16.9449L16.9451 18.014L15.4234 16.4915C14.8989 16.6958 14.3408 16.8004 13.7779 16.7998H6.22235C5.15191 16.8005 4.11576 16.4223 3.29738 15.7323C2.47899 15.0423 1.93118 14.085 1.75093 13.0299C1.57068 11.9747 1.76963 10.8898 2.31255 9.96725C2.85547 9.04471 3.70733 8.34405 4.71729 7.98933C4.68496 7.33287 4.77541 6.67614 4.984 6.05286L2.52015 3.58825L3.58926 2.5199ZM6.22235 7.7332C6.22235 7.7944 6.22386 7.85636 6.22688 7.9168L6.27977 9.04106L5.21898 9.4143C4.54692 9.65198 3.98044 10.1194 3.61955 10.7342C3.25866 11.3489 3.12656 12.0714 3.24658 12.7741C3.3666 13.4768 3.73102 14.1144 4.2755 14.5745C4.81998 15.0346 5.5095 15.2876 6.22235 15.2887H13.7779C13.9184 15.2887 14.0567 15.2789 14.1919 15.2608L6.24502 7.31387C6.22991 7.45138 6.22235 7.59116 6.22235 7.7332ZM10.0001 2.44434C10.7162 2.44432 11.4249 2.58973 12.0831 2.87176C12.7414 3.15378 13.3355 3.56655 13.8295 4.08504C14.3235 4.60352 14.707 5.21692 14.9568 5.88805C15.2067 6.55918 15.3176 7.27405 15.2829 7.98933C15.9104 8.21037 16.4816 8.56675 16.956 9.03319C17.4303 9.49963 17.7963 10.0647 18.0279 10.6884C18.2595 11.3121 18.351 11.979 18.296 12.642C18.241 13.305 18.0409 13.9478 17.7097 14.5248L16.5817 13.3968C16.804 12.8468 16.8596 12.2435 16.7413 11.6622C16.6231 11.0808 16.3363 10.5472 15.9168 10.1277C15.4972 9.70827 14.9635 9.42161 14.3822 9.30348C13.8008 9.18534 13.1975 9.24097 12.6476 9.46341L11.5188 8.33386C12.1844 7.95231 12.9551 7.7332 13.7779 7.7332C13.7779 7.04146 13.5879 6.36301 13.2288 5.77182C12.8696 5.18063 12.355 4.6994 11.7411 4.3806C11.1272 4.0618 10.4376 3.91768 9.74741 3.96395C9.05722 4.01023 8.39299 4.24511 7.82714 4.643L6.7467 3.56256C7.67541 2.83612 8.82104 2.44236 10.0001 2.44434Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
