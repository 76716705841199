// Core
import React, {
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../plugins/request';

// Store
import {
  Customer,
  getUser,
  setCustomer,
  User,
} from '../../../store/session.store';

// Media
import mockedCustomerLogo from '../../../media/images/logoSuperior.png';

// Components
import { AdminPanel } from '../../../layout/AdminPanel';
import { CardCustomer } from '../../../components/CardCustomer';
import { EmptyBox } from '../../../components/EmptyBox';
import { Loader, LoaderSize } from '../../../layout/elements/Loader';
import { PageHeader } from '../../../layout/elements/PageHeader';

// Style
import {
  CardCustomerContainer,
  Container,
  Layout,
  LoadingContainer,
  PageHeaderContainer,
} from './CustomersIndex.style';

// Types
import { UserType } from '../../../types/global';

function CustomersIndex(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  /* ***********************************************************************************************
  **************************************** LOCAL STATES ********************************************
  *********************************************************************************************** */

  const initialState: {
    customers: Customer[],
    isLoading: boolean,
  } = {
    customers: [],
    isLoading: false,
  };

  const [state, setState] = useState(initialState);

  /* ***********************************************************************************************
  ***************************************** METHODS ************************************************
  *********************************************************************************************** */

  // Get user
  const user: User = getUser() as User;

  // Redirects if the user is not an admin
  if (user.type !== UserType.admin) {
    navigate('/plant-management');
  }

  /* ********** Request **********  */

  // Get the customers
  const getCustomers = async (): Promise<void> => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const customersResponse: Customer[] = await requestVantageApi.customer({
        method: RequestMethod.GET,
        path: '/customer',
      });

      setState((prevState) => ({
        ...prevState,
        customers: customersResponse,
        isLoading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  // Check if there are customers
  const hasCustomers: boolean = state.customers.length > 0;

  // Set the customerId in the store and navigate to the plant management page
  const handleCardClick = async (customer: Customer): Promise<void> => {
    setCustomer(customer);
    navigate('/plant-management');
  };

  /* ***********************************************************************************************
  ************************************* COMPONENT HANDLING *****************************************
  *********************************************************************************************** */

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <AdminPanel>
      <Layout>
        {state.isLoading ? (
          <LoadingContainer>
            <Loader
              size={LoaderSize.xl}
              text={`${t('view.customers.loading')}`}
            />
          </LoadingContainer>
        ) : (
          <Container>
            <PageHeaderContainer>
              <PageHeader
                title={`${t('common.customers')}`}
                description={`${t('view.customers.description')}`}
              />
            </PageHeaderContainer>
            {!hasCustomers ? (
              <EmptyBox
                title={`${t('view.customers.emptyBox.title')}`}
                description={`${t('view.customers.emptyBox.description')}`}
              />
            ) : (
              <CardCustomerContainer>
                {state.customers.map((customer) => (
                  <CardCustomer
                    idCustomer={customer.id}
                    imgSrc={mockedCustomerLogo}
                    key={customer.id}
                    name={customer.name}
                    options={[]}
                    onClick={() => handleCardClick(customer)}
                    testId={`customer-card-${customer.id}`}
                  />
                ))}
              </CardCustomerContainer>
            )}
          </Container>
        )}
      </Layout>
    </AdminPanel>
  );
}

export { CustomersIndex };
