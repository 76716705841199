// Core
import styled from 'styled-components';

export const OverallStatusContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  border-top: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
`;

export const MotorStatusContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  height: 100%;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
`;

export const MotorRpmContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MotorAmpsContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const SpeedometersContainer = styled.div`
  display: flex;
  flex: 1;
`;
