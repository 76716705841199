/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Libraries
import { Alert as AlertAntD, AlertProps } from 'antd';

// Types
import { Theme } from '../../../types/theme';

// Component
import { AlertType } from './Alert.types';

const mapBackgroundByType = (theme: Theme): Record<AlertType, string> => ({
  success: theme.color.alert.successBackground,
  error: theme.color.alert.errorBackground,
  warning: theme.color.alert.warningBackground,
});

const mapTextColorByType = (theme: Theme): Record<AlertType, string> => ({
  success: theme.color.alert.successText,
  error: theme.color.alert.errorText,
  warning: theme.color.alert.warningText,
});

const mapBorderColorByType = (theme: Theme): Record<AlertType, string> => ({
  success: theme.color.alert.successBorder,
  error: theme.color.alert.errorBorder,
  warning: theme.color.alert.warningBorder,
});

const mapPrimaryActionColorByType = (theme: Theme): Record<AlertType, string> => ({
  success: theme.color.alert.successPrimaryAction,
  error: theme.color.alert.errorPrimaryAction,
  warning: theme.color.alert.warningText,
});

const mapSecondaryActionColorByType = (theme: Theme): Record<AlertType, string> => ({
  success: theme.color.alert.successSecondaryAction,
  error: theme.color.alert.errorSecondaryAction,
  warning: theme.color.alert.warningText,
});

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px
`;

export const PrimaryActionButton = styled.div<Pick<AlertProps, 'type'>>`
  font-family: ${({ theme }) => theme.font.medium};
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme, type }) => mapPrimaryActionColorByType(theme)[type as AlertType]};
  line-height: 1.2;
  font-size: 14px;
  letter-spacing: -0.02em;
  cursor: pointer;
  
  svg {
    top: 2px;
    margin-left: 12px;
    display: flex;
    align-items: center;
  };
`;

export const SecondaryActionButton = styled.div<Pick<AlertProps, 'type'>>`
  background: none;
  border: none;
  color: ${({ theme, type }) => mapSecondaryActionColorByType(theme)[type as AlertType]};
  line-height: 1.2;
  font-size: 14px;
  letter-spacing: -0.02em;
  cursor: pointer;
`;

export const Element = styled(AlertAntD)<Pick<AlertProps, 'type' | 'description'> & {
  message: string
}>`
  padding: 14px 19px;
  color: ${({ type, theme }) => (
    type
      ? mapTextColorByType(theme)[type as AlertType]
      : 'inherit'
  )};
  line-height: 1.2;
  font-size: 14px;
  letter-spacing: -0.02em;
  background-color: ${({ theme, type }) => (
    type
      ? mapBackgroundByType(theme)[type as AlertType]
      : 'inherit'
  )};
  border: 1px solid ${({ theme, type }) => (
    type
      ? mapBorderColorByType(theme)[type as AlertType]
      : 'inherit'
  )};
  display: flex;
  align-items: center;
  
  > svg {
    font-size: 18px;
    align-self: ${({ description, message }) => (description && message ? 'flex-start' : 'center')};
  };
  
  .ant-alert-message {
    color: ${({ theme, type }) => (
      type
        ? mapTextColorByType(theme)[type as AlertType]
        : 'inherit'
    )};
    line-height: 1.2;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.bold};
  };
  
  .ant-alert-description {
    font-family: ${({ theme }) => theme.font.regular};
  };

  .ant-alert-content {
    padding-left: 12px;
  };
  
  .ant-alert-close-icon {
    display: flex;
    align-self: center;
    padding: 0 10px 0 34px;
    
    svg {
      font-size: 12px;
      color: ${({ theme, type }) => (
        type
          ? mapTextColorByType(theme)[type as AlertType]
          : 'inherit'
      )};
    };
  };
  
  .ant-alert-action {
    align-self: center;
  };
`;
