// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="ellipsis-icon"
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M4.24995 8.75C3.56244 8.75 2.99994 9.31251 2.99994 10C2.99994 10.6875 3.56244 11.25 4.24995 11.25C4.93746 11.25 5.49996 10.6875 5.49996 10C5.49996 9.31251 4.93746 8.75 4.24995 8.75ZM16.7501 8.75C16.0626 8.75 15.5001 9.31251 15.5001 10C15.5001 10.6875 16.0626 11.25 16.7501 11.25C17.4376 11.25 18.0001 10.6875 18.0001 10C18.0001 9.31251 17.4376 8.75 16.7501 8.75ZM10.5 8.75C9.8125 8.75 9.25 9.31251 9.25 10C9.25 10.6875 9.8125 11.25 10.5 11.25C11.1875 11.25 11.75 10.6875 11.75 10C11.75 9.31251 11.1875 8.75 10.5 8.75Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
