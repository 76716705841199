// Layout
import { CoordinatesType } from '../../layout/elements/Map';

export enum LiveStatus {
  on = 'on',
  off = 'off',
}

export enum ViewType {
  camera = 'camera',
  map = 'map',
}

export interface CameraFeedProps {
  centerCoordinates?: CoordinatesType;
  className?: string;
  live?: LiveStatus;
  pins?: CoordinatesType[];
  polygons?: CoordinatesType[] | CoordinatesType[][];
  src?: string;
  testId?: string;
  title: string;
}
