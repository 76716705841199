// Core
import React from 'react';

// Libraries
import { Menu as MenuAntD } from 'antd';

// Component
import { Element } from './NavigationTabs.style';
import { NavigationTabsProps } from './NavigationTabs.types';

function NavigationTabs({
  className,
  items,
  onClick,
  selected,
  testId,
}: NavigationTabsProps): JSX.Element {
  // Get the selected item
  const selectedKey: string[] = items.filter((
    item,
  ) => item.key === selected).map((item) => item.key);

  return (
    <Element
      data-testid={testId}
      className={className}
    >
      <MenuAntD
        mode="horizontal"
        items={items}
        selectedKeys={selectedKey}
        onClick={onClick}
        disabledOverflow
      />
    </Element>
  );
}

export { NavigationTabs };
