// Core
import styled from 'styled-components';

export const WindowDimensionsViewer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.app.windowDimensionsBgColor};
  color: ${({ theme }) => theme.color.app.windowDimensionsColor};
  padding: 6px 10px;
`;
