// Core
import styled from 'styled-components';

export const LeftMetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: 100%;
`;

export const RightMetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  flex: 1;
  height: 100%;
`;

export const LoadingCrusherDashboard = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LineWearCavityLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: 100%;
`;

export const CrusherMonitoringContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.crusherDashboardBackgroundColor};
  padding: 12px 12px 24px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: calc(100% - 64px);
`;

export const AlertContainer = styled.div`
  padding: 0 6px 0 2px;
`;

export const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
