// Core
import { ReactNode } from 'react';

export enum LinkToSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum LinkToWeight {
  regular = 'regular',
  medium = 'medium',
  bold = 'bold',
}

export enum LinkToType {
  link = 'link',
  route = 'route',
}

export enum LinkToTarget {
  blank = '_blank',
  self = '_self',
}

export enum LinkToColor {
  neutral = 'neutral',
  primary = 'primary',
}

export interface LinkToProps {
  children: ReactNode | ReactNode[];
  className?: string;
  color?: LinkToColor;
  newTab?: boolean;
  size?: LinkToSize;
  target: string;
  testId?: string;
  type?: LinkToType;
  weight?: LinkToWeight;
}
