// Core
import styled from 'styled-components';

export const LinerWearValueContainer = styled.div`
  margin-right: 4px;
`;

export const LinerWearUnitContainer = styled.div`
  width: max-content;
  line-height: normal;
  margin-bottom: 1px;
`;

export const LinerWearContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
`;

export const LinerWearContent = styled.div`
  display: flex;
  align-items: flex-end;
`;
