// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="wifi-icon"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.45471 6.2199C3.87179 4.26176 6.88927 3.19536 9.99999 3.19997C13.2375 3.19997 16.2106 4.33103 18.5453 6.2199L17.5963 7.39554C15.4477 5.65481 12.7653 4.70686 9.99999 4.71107C7.1221 4.71107 4.47994 5.71595 2.40368 7.39554L1.45471 6.2199ZM3.8279 9.159C5.57367 7.7446 7.75317 6.97435 9.99999 6.97772C12.3377 6.97772 14.4849 7.79447 16.1721 9.15824L15.2224 10.3339C13.7451 9.13734 11.901 8.48581 9.99999 8.48882C8.02196 8.48882 6.20486 9.18015 4.77762 10.3339L3.8279 9.15824V9.159ZM6.20184 12.0981C7.27611 11.2276 8.61732 10.7535 9.99999 10.7555C11.4386 10.7555 12.76 11.2579 13.7981 12.0973L12.8484 13.273C12.0427 12.6203 11.0369 12.265 9.99999 12.2666C8.92107 12.2666 7.92978 12.6436 7.15157 13.273L6.20184 12.0973V12.0981ZM8.57578 15.0372C8.97853 14.7106 9.48146 14.5326 9.99999 14.5332C10.5395 14.5332 11.0351 14.7221 11.4242 15.0364L9.99999 16.7999L8.57578 15.0364V15.0372Z"
        fill="currentColor"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default Icon;
