// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33317 14.6666C3.15636 14.6666 2.98679 14.5963 2.86177 14.4713C2.73674 14.3463 2.6665 14.1767 2.6665 13.9999V1.99992C2.6665 1.82311 2.73674 1.65354 2.86177 1.52851C2.98679 1.40349 3.15636 1.33325 3.33317 1.33325H12.6665C12.8433 1.33325 13.0129 1.40349 13.1379 1.52851C13.2629 1.65354 13.3332 1.82311 13.3332 1.99992V3.99992H11.9998V2.66659H3.99984V13.3333H11.9998V11.9999H13.3332V13.9999C13.3332 14.1767 13.2629 14.3463 13.1379 14.4713C13.0129 14.5963 12.8433 14.6666 12.6665 14.6666H3.33317ZM11.9998 10.6666V8.66659H7.33317V7.33325H11.9998V5.33325L15.3332 7.99992L11.9998 10.6666Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
