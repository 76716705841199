// Core
import React from 'react';

// Libraries
import { Col as ColAntD, ColProps as ColPropsAntD } from 'antd';

// Types
import { GridBreakpointConfig } from '../../../types/global';

// Component
import { ColProps } from './Col.types';

const convertBreakpointConfigToAntD = (
  config: GridBreakpointConfig | number,
): ColPropsAntD | number => {
  let convertedConfig: ColPropsAntD | number;
  if (typeof config === 'number') convertedConfig = config;
  else {
    convertedConfig = {
      span: undefined,
      offset: undefined,
    };
    if (config.cols) convertedConfig.span = config.cols;
    if (config.offset) convertedConfig.offset = config.offset;
  }
  return convertedConfig;
};

function Col({
  testId,
  className,
  children,
  style,
  cols = 24,
  offset = 0,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}: ColProps): JSX.Element {
  return (
    <ColAntD
      data-testid={testId}
      className={className}
      style={style}
      span={cols}
      offset={offset}
      xs={xs && convertBreakpointConfigToAntD(xs)}
      sm={sm && convertBreakpointConfigToAntD(sm)}
      md={md && convertBreakpointConfigToAntD(md)}
      lg={lg && convertBreakpointConfigToAntD(lg)}
      xl={xl && convertBreakpointConfigToAntD(xl)}
      xxl={xxl && convertBreakpointConfigToAntD(xxl)}
    >
      {children}
    </ColAntD>
  );
}

export { Col };
