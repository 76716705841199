export enum StatusIndicatorSize {
  sm = 'sm',
  lg = 'lg',
}

export enum StatusIndicatorStatus {
  alarm = 'alarm',
  warning = 'warning',
  good = 'good',
  neutral = 'neutral',
}

export interface StatusIndicatorProps {
  className?: string;
  size?: StatusIndicatorSize;
  status?: StatusIndicatorStatus;
  testId?: string;
  text: string;
}
