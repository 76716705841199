/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

// Types
import {
  LinkToColor,
  LinkToProps,
  LinkToSize,
  LinkToWeight,
} from './LinkTo.types';

// Theme
import { Theme } from '../../../types/theme';

export const mapFontSizeBySize: Record<LinkToSize, string> = {
  [LinkToSize.xs]: '12px',
  [LinkToSize.sm]: '14px',
  [LinkToSize.md]: '16px',
  [LinkToSize.lg]: '18px',
  [LinkToSize.xl]: '20px',
};

export const mapFontWeightByWeight = (theme: Theme): Record<LinkToWeight, string> => ({
  [LinkToWeight.regular]: theme.font.regular,
  [LinkToWeight.medium]: theme.font.medium,
  [LinkToWeight.bold]: theme.font.bold,
});

const linkStyle = (
  theme: Theme,
  weight?: LinkToWeight,
  size?: LinkToSize,
  color?: LinkToColor,
) => `
  color: ${color === LinkToColor.neutral ? theme.color.linkTo.neutral : theme.color.linkTo.primary};
  font-family: ${mapFontWeightByWeight(theme)[weight as LinkToWeight]};
  font-size: ${mapFontSizeBySize[size as LinkToSize]};
  text-decoration: none;

  &:hover {
    color: ${
      color === LinkToColor.neutral
        ? theme.color.linkTo.neutralHover
        : theme.color.linkTo.primaryHover
    };
  };
`;

export const Link = styled.a<Pick<LinkToProps, 'size' | 'weight' | 'color'>>`
  ${({
    theme,
    size,
    weight,
    color,
  }) => linkStyle(
    theme,
    weight,
    size,
    color,
  )};
`;

export const Route = styled(RouterLink)<Pick<LinkToProps, 'size' | 'weight' | 'color'>>`
  ${({
    theme,
    size,
    weight,
    color,
  }) => linkStyle(
    theme,
    weight,
    size,
    color,
  )};
`;
