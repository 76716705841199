/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Libraries
import { Button as AntButton } from 'antd';
import { ButtonType as AntDButtonType } from 'antd/lib/button';
import { BaseButtonProps as AntDBaseButtonProps } from 'antd/es/button/button';
import _ from 'lodash';

// Types
import { Theme } from '../../../types/theme';
import {
  ButtonColor,
  ButtonProps,
  ButtonSize,
} from './Button.types';

type ColorMap = Partial<Record<AntDButtonType, Partial<Record<ButtonColor, string>>>>;

/*
  Verifies the button type and color and returns the [background-color]
  @param theme should receive the theme object
  @param type should receive a AntDButtonType [primary | link | default]
  @param color should receive a ButtonColor [primary | neutral | danger]
*/
const getBackgroundColor = (
  theme: Theme,
  type: AntDButtonType,
  color: ButtonColor,
  disabled: boolean,
): string => {
  const mapBackground: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.backgroundColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.backgroundColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.backgroundColor,
    },
  };

  const mapDisabledBackground: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.disabledBackgroundColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.disabledBackgroundColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.disabledBackgroundColor,
    },
  };

  const colorValue: string | undefined = disabled
    ? mapDisabledBackground[type]?.[color]
    : mapBackground[type]?.[color];

  return colorValue || 'inherit';
};

/*
  Verifies the button type and color and returns the [text-color]
  @param theme should receive the theme object
  @param type should receive a AntDButtonType [primary | link | default]
  @param color should receive a ButtonColor [primary | neutral | danger]
*/
const getTextColor = (
  theme: Theme,
  type: AntDButtonType,
  color: ButtonColor,
  disabled: boolean,
): string => {
  const mapText: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.textColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.textColor,
    },
    link: {
      [ButtonColor.primary]: theme.color.button.linkType.primaryColor.textColor,
      [ButtonColor.neutral]: theme.color.button.linkType.neutralColor.textColor,
      [ButtonColor.danger]: theme.color.button.linkType.dangerColor.textColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.textColor,
    },
  };

  const mapDisabledText: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.disabledTextColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.disabledTextColor,
    },
    link: {
      [ButtonColor.primary]: theme.color.button.linkType.primaryColor.disabledTextColor,
      [ButtonColor.neutral]: theme.color.button.linkType.neutralColor.disabledTextColor,
      [ButtonColor.danger]: theme.color.button.linkType.dangerColor.disabledTextColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.disabledTextColor,
    },
  };

  const colorValue: string | undefined = disabled
    ? mapDisabledText[type]?.[color]
    : mapText[type]?.[color];
  return colorValue || 'inherit';
};

/*
  Verifies the button type and color and returns the [border-color]
  @param theme should receive the theme object
  @param type should receive a AntDButtonType [primary | link | default]
  @param color should receive a ButtonColor [primary | neutral | danger]
*/
const getBorderColor = (
  theme: Theme,
  type: AntDButtonType,
  color: ButtonColor,
  disabled: boolean,
): string => {
  const mapBorderColor: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.borderColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.borderColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.borderColor,
    },
  };

  const mapDisabledBorderColor: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.disabledBorderColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.disabledBorderColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.disabledBorderColor,
    },
  };

  const borderColor: string | undefined = disabled
    ? mapDisabledBorderColor[type]?.[color]
    : mapBorderColor[type]?.[color];
  return borderColor || 'inherit';
};

/*
  Verifies the button type and color and returns the [hover background-color]
  @param theme should receive the theme object
  @param type should receive a AntDButtonType [primary | link | default]
  @param color should receive a ButtonColor [primary | neutral | danger]
*/
const getHoverBackgroundColor = (
  theme: Theme,
  type: AntDButtonType,
  color: ButtonColor,
): string => {
  const mapHoverBackground: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.hoverBackgroundColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.hoverBackgroundColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.hoverBackgroundColor,
    },
  };

  const colorValue: string | undefined = mapHoverBackground[type]?.[color];
  return colorValue || 'inherit';
};

/*
  Verifies the button type and color and returns the [hover text-color]
  @param theme should receive the theme object
  @param type should receive a AntDButtonType [primary | link | default]
  @param color should receive a ButtonColor [primary | neutral | danger]
*/
const mapHoverTextColor = (
  theme: Theme,
  type: AntDButtonType,
  color: ButtonColor,
): string => {
  const mapHoverText: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.hoverTextColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.hoverTextColor,
    },
    link: {
      [ButtonColor.primary]: theme.color.button.linkType.primaryColor.hoverTextColor,
      [ButtonColor.neutral]: theme.color.button.linkType.neutralColor.hoverTextColor,
      [ButtonColor.danger]: theme.color.button.linkType.dangerColor.hoverTextColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.hoverTextColor,
    },
  };

  const colorValue: string | undefined = mapHoverText[type]?.[color];
  return colorValue || 'inherit';
};

/*
  Verifies the button type and color and returns the [hover border-color]
  @param theme should receive the theme object
  @param type should receive a AntDButtonType [primary | link | default]
  @param color should receive a ButtonColor [primary | neutral | danger]
*/
const mapHoverBorderColor = (
  theme: Theme,
  type: AntDButtonType,
  color: ButtonColor,
): string => {
  const mapHoverBorder: ColorMap = {
    primary: {
      [ButtonColor.primary]: theme.color.button.primaryType.primaryColor.hoverBorderColor,
      [ButtonColor.danger]: theme.color.button.primaryType.dangerColor.hoverBorderColor,
    },
    default: {
      [ButtonColor.primary]: theme.color.button.secondaryType.primaryColor.hoverBorderColor,
    },
  };

  const borderColor: string | undefined = mapHoverBorder[type]?.[color];
  return borderColor || 'inherit';
};

// Map ButtonSize to size
const sizeMap: Record<ButtonSize, string> = {
  [ButtonSize.xs]: '40px',
  [ButtonSize.sm]: '44px',
  [ButtonSize.md]: '46px',
  [ButtonSize.lg]: '48px',
  [ButtonSize.xl]: '52px',
};

/**
 * Determines the width of the button based on its properties.
 *
 * @param {boolean} square - Indicates if the button should be square.
 * @param {boolean} block - Indicates if the button should be a block element.
 * @param {ButtonSize} size - The size of the button.
 * @returns {string} - The calculated width of the button.
 */
const getWidth = (square: boolean, block: boolean, size: ButtonSize): string => {
  let width: string = '100%';
  if (square) {
    width = sizeMap[size];
  } else if (!block && !square) {
    width = 'max-content';
  }
  return width;
};

export const Element = styled(AntButton)<
  Pick<ButtonProps, 'color' | 'disabled' | 'block'>
  & {
    type: AntDButtonType,
    loading: AntDBaseButtonProps['loading'],
    btnsize: ButtonSize,
    square: string,
    internalloading: number | null,
  }
>`
  :not(.ant-btn-link) {
    /* ******************* BACKGROUND COLOR ******************* */
    background-color: ${({
      theme,
      type,
      color,
      disabled,
    }) => getBackgroundColor(theme, type, color as ButtonColor, disabled as boolean)};

    /* ******************* BOX SHADOW ******************* */
    box-shadow: ${({ theme }) => `0px 11px 25px -6px ${theme.color.button.buttonBoxShadowColor}`};

    /* ******************* BORDER COLOR ******************* */
    border-color: ${({
      theme,
      type,
      color,
      disabled,
    }) => getBorderColor(theme, type, color as ButtonColor, disabled as boolean)} !important;
  };

  /* ******************* FONT SIZE ******************* */
  font-size: ${({ btnsize }) => (btnsize === ButtonSize.xs ? '14px' : '16px')};
  
  /* ******************* HEIGHT ******************* */
  height: ${({ btnsize }) => sizeMap[btnsize]};
  
  /* ******************* WIDTH ******************* */
  width: ${({ square, block, btnsize }) => getWidth(square === 'true', block as boolean, btnsize)};
  
  font-family: ${({ theme }) => theme.font.bold} !important;
  /* ******************* TEXT COLOR ******************* */
  color: ${({
    theme,
    type,
    color,
    disabled,
  }) => getTextColor(theme, type, color as ButtonColor, disabled as boolean)} !important;
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  padding: ${({ square }) => (square === 'true' ? '0' : '0 20px')};
  gap: 10px;
  
  > span {
    position: relative;
  };

  /* ******************* HOVER ******************* */
  :hover:not(:disabled) {
    color: ${({ theme, type, color }) => mapHoverTextColor(theme, type, color as ButtonColor)} !important;
    :not(.ant-btn-link) {
      background-color: ${({
        theme,
        type,
        color,
      }) => getHoverBackgroundColor(theme, type, color as ButtonColor)} !important;
      border-color: ${({
        theme,
        type,
        color,
      }) => mapHoverBorderColor(theme, type, color as ButtonColor)} !important;
    };

    cursor: ${({ internalloading }) => (!_.isNil(internalloading) ? 'wait' : 'pointer')};
  };

  /* ******************* DISABLED ******************* */
  
  :disabled {
    cursor: not-allowed;
  };
  
  /* ******************* LOADING ******************* */
  .ant-btn-loading-icon {
    display: none;
  }
  opacity: 1 !important;
`;

export const LoadingProgress = styled.div<Pick<ButtonProps, 'loading'> & { internalLoading: number | null }>`
  width: ${({ internalLoading }) => (!_.isNil(internalLoading) ? `calc(${internalLoading}% + 2px)` : '0%')};
  cursor: ${({ loading }) => (_.isNil(loading) ? 'pointer' : 'wait')};
  height: calc(100% + 2px);
  background-color: ${({ theme }) => theme.color.button.loadBackgroundColor};
  transition: ${({ loading }) => (loading === true ? 'width 35ms' : 'width 500ms')};
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 3px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 24px;
  max-height: 24px;
`;
