// Core
import styled from 'styled-components';

// Libraries
import { Modal as AntDModal } from 'antd';

// Component
import { ModalPanelProps } from './ModalPanel.types';

// Layout
import { Col } from '../Col';
import { Row } from '../Row';

export const Element = styled(Row)`
  flex: 1;
`;

export const Header = styled(Col)<Pick<ModalPanelProps, 'description'>>`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const Container = styled(AntDModal)`
  .modal-header-description {
    color: ${({ theme }) => theme.color.modalPanel.headerDescriptionTextColor};
  };
  
  .ant-modal-body {
    height: 75vh;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
  };
  
  .ant-modal-content {
    border-radius: 4px;
    padding: 2em 2em 16px 2em;
  };
`;
