// Core
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.crusherDashboard.borderColor};
`;

export const StatusContainer = styled.div`
  display: flex;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
`;

export const IndicatorsContainer = styled.div`
  flex-direction: column;
  display: flex;
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  border-radius: 4px;
  width: 100%;
`;
