// Core
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Layout = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 44px;
`;

export const PageHeaderContainer = styled.div`
  margin-bottom: 24px;
`;

export const CardCustomerContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
