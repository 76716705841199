// Core
import styled from 'styled-components';

// Libraries
import { Row as RowAntD } from 'antd';

export const Element = styled(RowAntD)`
  &.ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;
