/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Types
import { ThemedProps } from '../../types/theme';

// Plugins
import { hexToRgba } from '../../plugins/general';

// Media
import backgroundCameraFeed from '../../media/images/backgroundCameraFeed.png';

// Component
import { ViewType } from './CameraFeed.types';

export const CameraOnOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ theme }) => `linear-gradient(
    ${hexToRgba(theme.color.cameraFeed.backgroundGradientColor, 0.6)} 0%, transparent 50%,
    ${hexToRgba(theme.color.cameraFeed.backgroundGradientColor, 0.6)} 100%)`
  };
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 1;
  border-radius: 4px;
`;

export const CameraOffOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ theme }) => `linear-gradient(
    ${hexToRgba(theme.color.cameraFeed.backgroundGradientColor, 0.7)} 0%,
    ${hexToRgba(theme.color.cameraFeed.backgroundGradientColor, 0.7)} 100%),
    url(${backgroundCameraFeed})`
  };
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
`;

export const LiveContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 16px;
  justify-content: space-between;
  z-index: 2;
`;

export const LiveIndicator = styled.div`
  background: ${({ theme }) => theme.color.cameraFeed.liveIndicatorColor};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;
`;

export const SwitchContainer = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 3px;
  border-radius: 3px;
  background: ${({ theme }) => theme.color.cameraFeed.buttonBackgroundColor};
  z-index: 2;
`;

export const CamButton = styled.div<{ currentView: ViewType } & ThemedProps>`
  width: 25px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: center;
  background: ${({ currentView, theme }) => (currentView === ViewType.camera
    ? theme.color.cameraFeed.switchActiveBackgroundColor
    : theme.color.cameraFeed.buttonBackgroundColor
  )};

  > svg {
    color: ${({ currentView, theme }) => (currentView === ViewType.camera
      ? theme.color.cameraFeed.iconActiveColor
      : theme.color.cameraFeed.iconDisabledColor
    )};
  };

  /* *************** HOVER *************** */
  &:hover {
    cursor: pointer;

    > svg {
      color: ${({ theme }) => theme.color.cameraFeed.iconActiveColor};
    };
  };
`;

export const MapButton = styled.div<{ currentView: ViewType } & ThemedProps>`
  width: 25px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ currentView, theme }) => (currentView === ViewType.map
    ? theme.color.cameraFeed.switchActiveBackgroundColor
    : theme.color.cameraFeed.buttonBackgroundColor
  )};

  > svg {
    color: ${({ currentView, theme }) => (currentView === ViewType.map
      ? theme.color.cameraFeed.iconActiveColor
      : theme.color.cameraFeed.iconDisabledColor
    )};
  };

  /* *************** HOVER *************** */
  &:hover {
    cursor: pointer;

    > svg {
      color: ${({ theme }) => theme.color.cameraFeed.actionsIconHoverColor};
    };
  };
`;

export const FullScreenButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: ${({ theme }) => theme.color.cameraFeed.buttonBackgroundColor};
  
  > svg {
    color: ${({ theme }) => theme.color.cameraFeed.fullScreenIconColor};
  };
  
  /* *************** HOVER *************** */   
  &:hover {
    cursor: pointer;
      
    > svg {
      color: ${({ theme }) => theme.color.cameraFeed.iconActiveColor};
    };
  };
`;

export const ContainerTitle = styled.div`
  position: absolute;
  bottom: 18px;
  right: 18px;
  z-index: 2;
`;

export const CloudOff = styled.div`
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.cameraFeed.buttonOfflineBgColor};
  backdrop-filter: blur(2px);
  padding: 12px;

  > svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.color.cameraFeed.iconActiveColor};
  };
`;

export const OfflineMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.color.cameraFeed.textColor};
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 448px;
`;

export const CameraContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.cameraFeed.cameraFeedBackgroundColor};
  border-radius: 4px;
`;

export const CrusherSimulationImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  position: absolute;
  height: 100%;
  object-fit: contain;
`;

export const MapViewContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const CameraFeedContainer = styled.div`
  position: relative;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.cameraFeed.cameraFeedBackgroundColor};
  align-items: center;
  justify-content: center;
  
  > video {
    height: 100%;
    max-width: 100%;
    border-radius: 4px;
  };
`;
