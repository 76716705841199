// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M3.83333 2V12.6667H14.5V14H2.5V2H3.83333ZM14.0287 4.19533L14.9713 5.138L11.1667 8.94267L9.16667 6.94333L6.30467 9.80467L5.362 8.862L9.16667 5.05733L11.1667 7.05667L14.0287 4.19533Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
