// Core
import React from 'react';

export enum TooltipPlacement {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export enum TooltipMode {
  default = 'default',
  light = 'light',
}

export interface TooltipProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  mode?: TooltipMode;
  placement?: TooltipPlacement;
  testId?: string;
  text: string;
}
