// Core
import React from 'react';

// Middlewares
import Auth from './middlewares/Auth';

// Views
// import EquipmentModel from './views/AdminPanel/EquipmentModel/EquipmentModel';
// import EquipmentModelTable from './views/AdminPanel/EquipmentModel/EquipmentModelTable';
import { AdminActions } from './views/AdminPanel/AdminActions/AdminActions';
import { CrusherDashboard } from './views/Equipment/Crusher';
import { CustomersIndex } from './views/AdminPanel/Customers/CustomersIndex';
import { Login } from './views/Login';
import { PlantNavigation } from './views/PlantNavigation/PlantNavigation';

const routes = [
  {
    path: '/',
    element: <Auth Component={CustomersIndex} Fallback={Login} />,
  },
  {
    path: 'plant-management',
    element: <Auth Component={PlantNavigation} />,
  },
  {
    path: 'admin-actions',
    element: <Auth Component={AdminActions} />,
  },
  {
    path: '/crusher/:id',
    element: <Auth Component={CrusherDashboard} />,
  },
  // {
  //   path: 'equipment',
  //   children: [
  //     {
  //       path: 'model',
  //       children: [
  //         {
  //           index: true,
  //           element: <Auth Component={EquipmentModel} />,
  //         },
  //         {
  //           path: ':equipmentType', // @todo review the component name
  //           element: <Auth Component={EquipmentModelTable} />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
