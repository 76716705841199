export enum BreadcrumbType {
  page = 'page',
  plant = 'plant',
}

export interface BreadcrumbItem {
  target?: string;
  text: string;
}

export interface BreadcrumbProps {
  className?: string;
  items: BreadcrumbItem[];
  testId?: string;
  type?: BreadcrumbType;
}
