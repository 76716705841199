// Core
import React, { useEffect, useState } from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Components
import { Paragraph, ParagraphSize } from '../Paragraph';

// Component
import { CheckboxProps, CheckboxSize } from './Checkbox.types';
import { Element } from './Checkbox.style';

// Types
import { Theme } from '../../../types/theme';

function Checkbox({
  checked = false,
  className,
  disabled = false,
  indeterminate = false,
  onChange,
  size = CheckboxSize.md,
  supportingText,
  testId,
  text,
}: CheckboxProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  /* ***********************************************************************************************
  ****************************************** STATE *************************************************
  *********************************************************************************************** */

  // State for tracking hover status of the supporting text
  const [isSupportingTextHovered, setIsSupportingTextHovered] = useState<boolean>(false);

  /* ***********************************************************************************************
  ***************************************** METHODS ************************************************
  *********************************************************************************************** */

  // Get [supportingText] color based on state
  const getSupportingTextColor = (): string => {
    let color: string;

    if (disabled) {
      color = theme.color.checkbox.disabledSupportingTextColor;
    } else if (isSupportingTextHovered) {
      color = theme.color.checkbox.supportingTextHoverColor;
    } else {
      color = theme.color.checkbox.supportingTextColor;
    }

    return color;
  };

  /* ***********************************************************************************************
  *************************************** COMPONENT HANDLING ***************************************
  *********************************************************************************************** */

  useEffect((): void => {
    if (checked && indeterminate) {
      throw new Error('Checkbox cannot be both checked and indeterminate.');
    }
  }, [checked, indeterminate]);

  return (
    <div data-testid={testId}>
      <Element
        className={className}
        checked={checked && !indeterminate}
        disabled={disabled}
        indeterminate={indeterminate && !checked}
        onChange={onChange}
        size={size}
        onMouseEnter={() => setIsSupportingTextHovered(true)}
        onMouseLeave={() => setIsSupportingTextHovered(false)}
      >
        {text}
        {supportingText && (
          <Paragraph
            color={getSupportingTextColor()}
            size={ParagraphSize.sm}
          >
            {supportingText}
          </Paragraph>
        )}
      </Element>
    </div>
  );
}

export { Checkbox };
