// Core
import React from 'react';

// Component
import { Container } from './PageHeader.style';
import { PageHeaderProps } from './PageHeader.types';

// Layout
import { Paragraph, ParagraphSize } from '../Paragraph';
import { Title, TitleSize, TitleWeight } from '../Title';

function PageHeader({
  className,
  description,
  testId,
  title,
}: PageHeaderProps): JSX.Element {
  return (
    <Container
      className={className}
      data-testid={testId}
    >
      <Title size={TitleSize.xs} weight={TitleWeight.bold}>{title}</Title>
      {description && <Paragraph size={ParagraphSize.lg}>{description}</Paragraph>}
    </Container>
  );
}

export { PageHeader };
