/* eslint-disable @typescript-eslint/indent */

// Core
import styled, { css } from 'styled-components';
import { Theme } from '../../../../../types/theme';

// Reusable styles
const borderColor = (
  { theme }: { theme: Theme },
) => theme.color.crusherDashboard.motorControlDrawer.borderColor;

const baseContainerStyles = css`
  border-radius: 4px;
  border: 1px solid ${borderColor};
  margin: 16px 0 24px 0;
`;

const paddingContainer = css`
  padding: 14px 20px;
`;

const flexColumnGap = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const fullHeight = css`
  height: 100%;
`;

export const MonitoringContainer = styled.div`
  ${baseContainerStyles};
`;

export const ControlModeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid ${borderColor};
`;

export const MonitoringStatusContainer = styled.div`
  ${flexColumnGap};
  padding: 16px 24px;
  border-right: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};

  &:last-child {
    border-bottom: none;
  }
`;

export const MonitoringStatus = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ThermometerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${fullHeight};
`;

export const LubeTankHeaterStatusContainer = styled.div`
  ${flexColumnGap};
  padding: 24px;
  border-top: 1px solid ${borderColor};
`;

export const ControllerModuleContainer = styled.div`
  ${baseContainerStyles};
`;

export const MotorControlContainer = styled.div`
  ${paddingContainer};
  border-right: 1px solid ${borderColor};
  ${flexColumnGap};
`;

export const LubeTankHeaterContainer = styled.div`
  ${paddingContainer};
  ${flexColumnGap};
  ${fullHeight};
`;

export const LubeUnitContainer = styled.div`
  ${paddingContainer};
  ${flexColumnGap};
  ${fullHeight};
  border-right: 1px solid ${borderColor};
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ExecuteCommandContainer = styled.div`
  margin-top: auto;
`;

export const Controllers = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  margin-top: 8px;
  border: 1px solid ${borderColor};
  border-radius: 4px;
`;

export const StopStartButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const DischargeContainer = styled.div`
  ${paddingContainer};
  ${flexColumnGap};
  ${fullHeight};
`;

export const CrusherMotorContainer = styled.div`
  ${paddingContainer};
  ${flexColumnGap};
  border-right: 1px solid ${borderColor};
`;

export const FeederContainer = styled.div`
  ${paddingContainer};
  ${flexColumnGap};
  ${fullHeight};
`;
