// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Component
import { Element } from './Paragraph.style';
import {
  ParagraphAlign,
  ParagraphColor,
  ParagraphProps,
  ParagraphSize,
  ParagraphWeight,
} from './Paragraph.types';

// Types
import { Theme } from '../../../types/theme';

function Paragraph({
  align = ParagraphAlign.left,
  children,
  className,
  color = ParagraphColor.default,
  size = ParagraphSize.md,
  preventOverflow = false,
  testId,
  weight = ParagraphWeight.regular,
}: ParagraphProps) {
  const theme: Theme = useTheme();

  return (
    <Element
      align={align}
      className={className}
      color={color === ParagraphColor.default ? theme.color.paragraph.default : color}
      data-testid={testId}
      size={size}
      weight={weight}
      preventOverflow={preventOverflow}
    >
      {children}
    </Element>
  );
}

export { Paragraph };
