// Core
import React from 'react';

// Components
import { Paragraph, ParagraphWeight } from '../Paragraph';
import { CaretRight } from '../../icons';

// Component
import {
  Container,
  Element,
  IconContainer,
} from './ModuleHeader.style';
import { ModuleHeaderProps } from './ModuleHeader.types';

function ModuleHeader({
  className,
  onClick,
  testId,
  title,
}: ModuleHeaderProps): JSX.Element {
  return (
    <Container
      data-testid={testId}
      className={className}
    >
      <Element
        tabIndex={0}
        onClick={onClick}
        role="button"
      >
        <Paragraph weight={ParagraphWeight.bold}>{title}</Paragraph>
        <IconContainer>
          <CaretRight />
        </IconContainer>
      </Element>
    </Container>
  );
}

export { ModuleHeader };
