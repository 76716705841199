// Core
import styled from 'styled-components';

export const Element = styled.div`

  /* ************* MENU ************** */
  .ant-menu {
    background-color: transparent;
    max-width: max-content;
    display: flex;
    border-bottom: none;
    box-sizing: border-box;
  };

  /* ************* MENU ITEM ************** */
  .ant-menu-item {
    line-height: 22px;
    padding: 0 4px; 
    margin: 0 8px; 

    :first-child {
      margin-left: 0;
    };

    :last-child {
      margin-right: 0;
    };
  };

  /* ************* TITLE ************** */
  .ant-menu-title-content {
    font-family: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.color.navigationTabs.textColor};
    font-size: 14px; 
    font-style: normal;
  };

  /* ************* ITEM SELECTED ************** */
  .ant-menu-item-selected .ant-menu-title-content {
    color: ${({ theme }) => theme.color.navigationTabs.selectedItemTextColor};
  };

  /* ************* ITEM HOVER ************** */
  .ant-menu-item:hover > .ant-menu-title-content {
    color: ${({ theme }) => theme.color.navigationTabs.selectedItemTextColor};
  };
  
  .ant-menu-horizontal > .ant-menu-item:hover::after {
    border-bottom-width: 0;
    border-bottom-color: ${({ theme }) => theme.color.navigationTabs.borderBottomColor};
  };

  .ant-menu-horizontal > .ant-menu-item::after {
    inset-inline: 0;
  };

  .ant-menu-horizontal > .ant-menu-submenu-selected::after {
    border-bottom-width: 1px; 
    inset-inline: 2px; 
  };

  /* ************* OVERFLOW ITEM ************** */
  .ant-menu-overflow-item {
    padding: 0 4px 0 4px; 
  };

  .ant-menu-item-title {
    white-space: nowrap;
  };

  /* ************* PADDING BOTTOM ITEM ************** */
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item {
    padding-bottom: 6px; 
  };

  /* ************* BORDER BOTTOM ITEM SELECTED ************** */
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom: 1px solid ${({ theme }) => theme.color.navigationTabs.borderBottomColor} !important;
  };

  /* ************* BORDER BOTTOM ITEM HOVER ************** */
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
    border-bottom: 1px solid ${({ theme }) => theme.color.navigationTabs.borderBottomColor};
  };

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    border-bottom: 1px solid transparent;
  };
`;
