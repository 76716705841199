// Core
import React from 'react';

// Component
import {
  Options,
  OptionsDropdownProps,
  OptionsDropdownPlacement,
} from './OptionsDropdown.types';
import {
  Container,
  Content,
  Divider,
  Icon,
  Item,
} from './OptionsDropdown.style';

// Layout
import { Ellipsis } from '../../icons';

function OptionsDropdown({
  children = <Ellipsis />,
  className,
  options,
  placement = OptionsDropdownPlacement.bottom,
  secondaryOptions,
  testId,
}: OptionsDropdownProps) {
  const renderOptions = (optionsList: Options[]) => (
    optionsList.map((option) => (
      <Item
        color={option.color}
        disabled={option.disabled}
        key={option.text}
        onClick={!option.disabled ? option.onClick : undefined}
      >
        {option.icon && <Icon>{option.icon}</Icon>}
        {option.text}
      </Item>
    ))
  );

  return (
    <Container
      className={className}
      data-testid={testId}
    >
      {children}
      <Content placement={placement}>
        {renderOptions(options)}
        {secondaryOptions && (
          <>
            <Divider />
            {renderOptions(secondaryOptions)}
          </>
        )}
      </Content>
    </Container>
  );
}

export { OptionsDropdown };
