// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2706 11.078L15.1259 13.9327L14.1826 14.876L11.3279 12.0207C10.2657 12.8722 8.94459 13.3353 7.58325 13.3333C4.27125 13.3333 1.58325 10.6453 1.58325 7.33334C1.58325 4.02134 4.27125 1.33334 7.58325 1.33334C10.8953 1.33334 13.5833 4.02134 13.5833 7.33334C13.5852 8.69468 13.1221 10.0158 12.2706 11.078ZM10.9333 10.5833C11.7793 9.71327 12.2518 8.54696 12.2499 7.33334C12.2499 4.75468 10.1613 2.66668 7.58325 2.66668C5.00459 2.66668 2.91659 4.75468 2.91659 7.33334C2.91659 9.91134 5.00459 12 7.58325 12C8.79687 12.0019 9.96318 11.5294 10.8333 10.6833L10.9333 10.5833Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
