import React from 'react';

export enum AlertType {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export interface AlertAction {
  onClick: React.MouseEventHandler<HTMLElement>;
  text: string;
}

export interface AlertProps {
  className?: string;
  closable?: boolean;
  description?: string;
  icon: React.ReactNode;
  primaryAction?: AlertAction;
  secondaryAction?: AlertAction;
  testId?: string;
  title?: string;
  type: AlertType;
}
