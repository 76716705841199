// Core
import styled from 'styled-components';

// Libraries
import { Drawer as DrawerAntD } from 'antd';

// Layout
import { Col } from '../Col';
import { Row } from '../Row';

// Plugins
import { hexToRgba } from '../../../plugins/general';

// Component
import { DrawerProps } from './Drawer.types';

export const Overlay = styled.div<Pick<DrawerProps, 'open'>>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: ${({ open }) => (open ? 'blur(7px)' : 'inherit')};
  z-index: 2;
  pointer-events: none;
`;

export const Header = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleContainer = styled.div`
  justify-content: space-between;
  gap: 16px;
  display: flex;
`;

export const Element = styled(DrawerAntD)`
  .ant-drawer-header {
    border-bottom: unset;
    padding: 28px;
    color: ${({ theme }) => theme.color.drawer.colorIcon};
  };

  .ant-drawer-wrapper-body {
    position: relative;
  };

  .ant-drawer-body {
    padding: 0 28px 28px 28px;

    ::-webkit-scrollbar {
      width: 0.438em;
    };

    ::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 3.375em;
    };

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.drawer.scrollBackgroundColor};
      border-radius: 3.375em;
    };
  };

  .ant-drawer-footer {
    display: flex;
    position: relative;
    justify-content: flex-end;
    padding: 0.75em 1.5em 1em 1.5em;
    border-top: unset;
  };
`;

export const PreviewContainer = styled(Row)`
  margin: 0.75em 0 1.5em;
`;

export const PreviewImage = styled.img`
  width: 7.5em;
  height: 7.5em;
  object-fit: contain;
  background: ${({ theme }) => theme.color.drawer.imageBackgroundColor};
  border: 0.063em solid ${({ theme }) => theme.color.drawer.imageBorderColor};
  border-radius: 0.25em;
  box-sizing: border-box;
`;

export const ActionsPreviewContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  gap: 0.25em;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const GradientEffect = styled.div<{ isHidden: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0.78em;
  height: 8.25em;
  width: 100%;
  border-radius: 4px;
  opacity: ${({ isHidden }): number => (isHidden ? 0 : 1)};
  background: linear-gradient(
    180deg,
    ${({ theme }) => hexToRgba(theme.color.drawer.colorGradient, 0)},
    ${({ theme }) => theme.color.drawer.colorGradient} 100%
  );
  transition: opacity 0.3s ease;
`;
