// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="alert-line-icon"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7217 2.5L18.66 16.25C18.7332 16.3767 18.7717 16.5204 18.7717 16.6667C18.7717 16.8129 18.7332 16.9566 18.66 17.0833C18.5869 17.21 18.4817 17.3152 18.355 17.3884C18.2283 17.4615 18.0846 17.5 17.9384 17.5H2.06171C1.91543 17.5 1.77173 17.4615 1.64505 17.3884C1.51837 17.3152 1.41317 17.21 1.34003 17.0833C1.2669 16.9566 1.22839 16.8129 1.22839 16.6667C1.22839 16.5204 1.2669 16.3767 1.34004 16.25L9.27837 2.5C9.35151 2.37333 9.45671 2.26814 9.58339 2.19501C9.71007 2.12188 9.85376 2.08337 10 2.08337C10.1463 2.08337 10.29 2.12188 10.4167 2.19501C10.5434 2.26814 10.6486 2.37333 10.7217 2.5ZM3.50504 15.8333H16.495L10 4.58334L3.50504 15.8333ZM9.1667 13.3333H10.8334V15H9.1667V13.3333ZM9.1667 7.5H10.8334V11.6667H9.1667V7.5Z"
        stroke="currentColor"
        strokeWidth={0.1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
