// Core
import React from 'react';

// Component
import { Link, Route } from './LinkTo.style';
import {
  LinkToColor,
  LinkToProps,
  LinkToSize,
  LinkToTarget,
  LinkToType,
  LinkToWeight,
} from './LinkTo.types';

function LinkTo({
  children,
  className,
  color = LinkToColor.neutral,
  newTab = false,
  size = LinkToSize.md,
  target,
  testId,
  type = LinkToType.route,
  weight = LinkToWeight.regular,
}: LinkToProps): JSX.Element {
  return type === LinkToType.route ? (
    <Route
      className={className}
      color={color}
      data-testid={testId}
      size={size}
      weight={weight}
      target={newTab ? LinkToTarget.blank : LinkToTarget.self}
      to={target}
    >
      {children}
    </Route>
  ) : (
    <Link
      className={className}
      color={color}
      size={size}
      data-testid={testId}
      href={target}
      target={newTab ? LinkToTarget.blank : LinkToTarget.self}
      weight={weight}
    >
      {children}
    </Link>
  );
}

export { LinkTo };
