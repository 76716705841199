// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Layout
import { Tag, TagType } from '../../layout/elements/Tag';

// Component
import {
  EquipmentStatusTagProps,
  TagStatus,
} from './EquipmentStatusTag.types';

function EquipmentStatusTag({
  className,
  status,
  testId,
}: EquipmentStatusTagProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const convertTagType = (equipmentStatus: EquipmentStatusTagProps['status']): TagType => {
    const mapStatus: Record<string, TagType> = {
      running: TagType.success,
      stopped: TagType.neutral,
    };
    return mapStatus[equipmentStatus];
  };

  return (
    <Tag
      testId={testId}
      className={className}
      type={convertTagType(status)}
      text={
        t(status === TagStatus.running
          ? 'component.equipmentStatusTag.running'
          : 'component.equipmentStatusTag.stopped')
      }
    />
  );
}

export { EquipmentStatusTag };
